import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { CampaignRequestFormRouteParams, TabData } from '../../../../../../types';
import { CampaignSettingsItemTabs as CampaignSettingsItemTabsInj } from '../../components';

export const getAdditionalCampaignRequestFormTabsRoutesInj = (): TabData[] => [];

export const CampaignSettingsRequestFormTabs = observer((): JSX.Element => {
    const [getAdditionalCampaignRequestFormTabsRoutes] = di(
        [getAdditionalCampaignRequestFormTabsRoutesInj],
        CampaignSettingsRequestFormTabs,
    );
    const [CampaignSettingsItemTabs] = di([CampaignSettingsItemTabsInj], CampaignSettingsRequestFormTabs);

    const params = useParams<CampaignRequestFormRouteParams>();

    const standardTabsData: TabData[] = [
        {
            title: <FormattedMessage id="campaign.settingsRequestFormTabs.information" />,
            path: generatePath(clientRoute.campaignSettingsRequestFormInfo, params),
        },
        {
            title: <FormattedMessage id="campaign.settingsRequestFormTabs.sections" />,
            path: generatePath(clientRoute.campaignSettingsRequestFormSectionSettings, params),
        },
    ];

    const tabsData = [...standardTabsData, ...getAdditionalCampaignRequestFormTabsRoutes()];

    return <CampaignSettingsItemTabs tabsData={tabsData} />;
});
