import { ClientBreadcrumbsWithCustomLinks, SyslogPage } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useCampaignRequestPageContext } from '../../../hooks';
import { getBreadcrumbsCampaignRequest as getBreadcrumbsCampaignRequestInj } from '../../../stores';
import { SyslogEntity } from '../../../types';

export const CampaignRequestSyslogPage = observer((): JSX.Element => {
    const [getBreadcrumbsCampaignRequest] = di([getBreadcrumbsCampaignRequestInj], CampaignRequestSyslogPage);

    const { model } = useCampaignRequestPageContext();
    const { id, number, campaign } = model;
    const intl = useIntl();
    const breadcrumbsLinksData = getBreadcrumbsCampaignRequest(intl, id, number, campaign, true);
    const breadcrumbs = <ClientBreadcrumbsWithCustomLinks customLinks={breadcrumbsLinksData} />;

    return <SyslogPage breadcrumbs={breadcrumbs} entityType={SyslogEntity.request} />;
});
