import { BreadcrumbsLinksType, ClientBreadcrumbsSettings } from '@platform/front-core';
import { IdTitle } from '@platform/front-utils';
import { IntlShape } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../clientRoute';
import { CampaignSettingsParams } from '../types';

export type ICSRouteParams = CampaignSettingsParams & {
    campaignId?: string;
};

export type ICSRouteTitles = {
    campaign?: string;
    regForm?: string;
    sectionSetting?: string;
};

export const getBreadcrumbsNames = (
    intl: IntlShape,
    pageTitles: ICSRouteTitles,
): ClientBreadcrumbsSettings['names'] => {
    const requestFormTitle = pageTitles.regForm || '';
    const sectionTitle = pageTitles.sectionSetting || '';
    return {
        mainPage: intl.formatMessage({ id: 'breadcrumbs.mainPage' }),
        campaigns: intl.formatMessage({ id: 'breadcrumbs.campaigns' }),
        campaign: pageTitles.campaign || '',
        requests: intl.formatMessage({ id: 'breadcrumbs.requests' }),
        campaignSettings: intl.formatMessage({ id: 'breadcrumbs.campaignSettings' }),
        campaignCreate: intl.formatMessage({ id: 'breadcrumbs.campaignCreate' }),
        campaignEdit: intl.formatMessage({ id: 'breadcrumbs.campaignEdit' }),
        users: intl.formatMessage({ id: 'breadcrumbs.users' }),
        campaignDescription: intl.formatMessage({ id: 'breadcrumbs.campaignDescription' }),
        campaignRequestForms: intl.formatMessage({ id: 'breadcrumbs.campaignRequestForms' }),
        campaignRequestFormEdit: intl.formatMessage({ id: 'breadcrumbs.campaignRequestFormEdit' }),
        campaignRequestFormCreate: intl.formatMessage({
            id: 'breadcrumbs.campaignRequestFormCreate',
        }),
        campaignRequestFormInformation: intl.formatMessage(
            { id: 'breadcrumbs.campaignRequestFormInformation' },
            {
                requestFormTitle,
            },
        ),
        campaignRequestFormSections: intl.formatMessage(
            { id: 'breadcrumbs.campaignRequestFormSections' },
            {
                requestFormTitle,
            },
        ),
        campaignRequestFormSectionInformation: intl.formatMessage(
            { id: 'breadcrumbs.campaignRequestFormSectionInformation' },
            {
                sectionTitle,
            },
        ),
        campaignRequestFormSectionPF: intl.formatMessage(
            { id: 'breadcrumbs.campaignRequestFormSectionPF' },
            {
                sectionTitle,
            },
        ),
    };
};

export const getBreadcrumbsRoutes = (params: ICSRouteParams): ClientBreadcrumbsSettings['routes'] => {
    const { id, rfId, sectionSettingId, campaignId } = params;
    return {
        mainPage: () => clientRoute.root,
        campaigns: () => clientRoute.campaigns,
        requests: () => clientRoute.requests,
        campaign: () => (id ? generatePath(clientRoute.campaign, { id }) : ''),
        campaignSettings: () => (id ? generatePath(clientRoute.campaignSettings, { id }) : ''),
        campaignCreate: () => (id ? generatePath(clientRoute.createCampaign, { id }) : ''),
        campaignEdit: () => (id ? generatePath(clientRoute.campaignSettingsEdit, { id }) : ''),
        campaignDescription: () => (id ? generatePath(clientRoute.campaignSettingsDescription, { id }) : ''),
        campaignRequestForms: () => (id ? generatePath(clientRoute.campaignSettingsRequestForms, { id }) : ''),
        campaignRequestFormCreate: () =>
            id && rfId
                ? generatePath(clientRoute.campaignSettingsRequestFormCreate, {
                      id,
                      rfId,
                  })
                : '',
        campaignRequestFormEdit: () =>
            id && rfId
                ? generatePath(clientRoute.campaignSettingsRequestFormEdit, {
                      id,
                      rfId,
                  })
                : '',
        campaignRequestFormInformation: () =>
            id && rfId
                ? generatePath(clientRoute.campaignSettingsRequestFormInfo, {
                      id,
                      rfId,
                  })
                : '',
        campaignRequestFormSections: () =>
            id && rfId
                ? generatePath(clientRoute.campaignSettingsRequestFormSectionSettings, {
                      id,
                      rfId,
                  })
                : '',
        campaignRequestFormSectionInformation: () =>
            id && rfId && sectionSettingId
                ? generatePath(clientRoute.campaignSettingsRequestFormSectionSettingInfo, {
                      id,
                      rfId,
                      sectionSettingId,
                  })
                : '',
        campaignRequestFormSectionPF: () =>
            id && rfId && sectionSettingId
                ? generatePath(clientRoute.campaignSettingsRequestFormSectionSettingPF, {
                      id,
                      rfId,
                      sectionSettingId,
                  })
                : '',
        campaignRequestSyslog: () => (id ? generatePath(clientRoute.requestSyslog, { id }) : ''),
    };
};

export const getBreadcrumbsCampaignRequest = (
    intl: IntlShape,
    requestId: string,
    requestN: string,
    campaign: IdTitle,
    isSyslog?: boolean,
): BreadcrumbsLinksType[] => {
    const links = [
        {
            to: clientRoute.campaigns,
            routeTitle: intl.formatMessage({ id: 'breadcrumbs.campaigns' }),
        },
        {
            to: campaign.id ? generatePath(clientRoute.campaign, { id: campaign.id }) : '',
            routeTitle: campaign.title,
        },
        {
            to: requestId ? generatePath(clientRoute.request, { id: requestId }) : '',
            routeTitle: intl.formatMessage({ id: 'campaignRequest.requestN' }, { number: requestN }),
        },
    ];
    if (isSyslog) {
        links.push({
            to: clientRoute.requestSyslog,
            routeTitle: intl.formatMessage({ id: 'breadcrumbs.syslog' }),
        });
    }
    return links;
};
