import { Grid, Typography } from '@mui/material';
import { ClientBreadcrumbs, ObjectHeaderLayout, ObjectHeaderLayoutProps } from '@platform/front-core';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import {
    useCampaignPageContext,
    useCampaignSettingsPageContext,
    useSetBreadcrumbsProps,
    useStore,
} from '../../../../hooks';
import { campaignSxStyles } from '../../campaignSxStyles';
import { CampaignSettingsDeleteCampaign as CampaignSettingsDeleteCampaignInj } from '../CampaignSettingsDeleteCampaign';
import { CampaignSettingsPublishCampaign as CampaignSettingsPublishCampaignInj } from '../CampaignSettingsPublishCampaign';
import { CampaignSettingsPageHeaderRequestFormBreadcrumbs as CampaignSettingsPageHeaderRequestFormBreadcrumbsInj } from './CampaignSettingsPageHeaderRequestFormBreadcrumbs';

export const CampaignSettingsPageHeader = observer((): JSX.Element => {
    const [CampaignSettingsDeleteCampaign] = di([CampaignSettingsDeleteCampaignInj], CampaignSettingsPageHeader);
    const [CampaignSettingsPublishCampaign] = di([CampaignSettingsPublishCampaignInj], CampaignSettingsPageHeader);
    const [CampaignSettingsPageHeaderRequestFormBreadcrumbs] = di(
        [CampaignSettingsPageHeaderRequestFormBreadcrumbsInj],
        CampaignSettingsPageHeader,
    );

    const { breadcrumbsStore } = useStore().coreRootStore;
    const { breadCrumbsSettings } = breadcrumbsStore;

    const { model } = useCampaignPageContext();
    const { title } = model;

    const { isCampaignCreatePage } = useCampaignSettingsPageContext();

    const params = useParams<RouteParamsDefault>();
    const { id } = params;
    useSetBreadcrumbsProps(params, { campaign: title });

    const isCampaignSettingsDescriptionPage = !!useRouteMatch(clientRoute.campaignSettingsDescription);

    const headerObjectLayoutProps: ObjectHeaderLayoutProps = {
        breadcrumbs: (
            <Switch>
                <Route path={clientRoute.campaignSettingsRequestForm}>
                    <CampaignSettingsPageHeaderRequestFormBreadcrumbs />
                </Route>
                <Route>
                    <ClientBreadcrumbs breadcrumbsSettings={breadCrumbsSettings} />
                </Route>
            </Switch>
        ),
        titleBlockAndMenu: !isCampaignCreatePage
            ? {
                  titleBlock: (
                      <Grid item sx={isCampaignSettingsDescriptionPage ? campaignSxStyles.titleWrapper : {}}>
                          <Typography sx={campaignSxStyles.title} variant="subtitle2">
                              {title}
                          </Typography>
                      </Grid>
                  ),
              }
            : undefined,
        rightBlocks: !isCampaignCreatePage
            ? {
                  topBlock: (
                      <Grid item container spacing={2} justifyContent="flex-end">
                          <Grid item>
                              <CampaignSettingsPublishCampaign id={id} title={title} />
                          </Grid>
                          <Grid item>
                              <CampaignSettingsDeleteCampaign id={id} title={title} />
                          </Grid>
                      </Grid>
                  ),
              }
            : undefined,
    };

    return <ObjectHeaderLayout {...headerObjectLayoutProps} />;
});
