import { Button, ButtonProps } from '@mui/material';
import { VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useState } from 'react';
import { di } from 'react-magnetic-di';
import { Route, useHistory, useLocation } from 'react-router-dom';
import {
    CampaignSettingsAccessPermissionContext,
    CampaignSettingsAccessPermissionContextType,
} from '../../../../../../contexts';
import { useStore } from '../../../../../../hooks';
import { AccessPermissionModel } from '../../../../../../models';
import { LoadPermissions, SelectedIndex, UpdatePermissions } from '../../../../../../types';
import { lastRouteRegexp } from '../../section-settings';
import { CampaignSettingsAccessPermissionDialog as CampaignSettingsAccessPermissionDialogInj } from './CampaignSettingsAccessPermissionDialog';

export type CampaignSettingsAccessPermissionButtonProps = {
    accessPermissionDialogTitle: string;
    editPermissionDialogTitle: string;
    addPermissionDialogTitle: string;
    emptyAccessPermissionList: string;
    checkboxLabel: string;
    loadPermissions: LoadPermissions;
    updatePermissions: UpdatePermissions;
    buttonTitle: string;
    buttonColor?: ButtonProps['color'];
    buttonVariant?: ButtonProps['variant'];
    accessPermissionRoutePath: string;
    editPermissionRoutePath: string;
    accessPermissionRoutePathTemplate: string;
    editPermissionRoutePathTemplate: string;
};

/**
 * Компонент, который содержит в себе кнопку и три попапа,
 * первый из которых выезжает по нажатии на эту кнопку.
 * Первый из попапов - это попап со списком доступов.
 * Два других выезжают при взаимодействии с его элементами управления.
 * @prop accessPermissionDialogTitle - Заголовок главного (первого) попапа со списком доступов.
 * @prop editPermissionDialogTitle - Заголовок попапа с редактированием доступов.
 * @prop addPermissionDialogTitle - Заголовок попапа с добавлением доступов.
 * @prop emptyAccessPermissionList - Заголовок, отображающийся если доступы отсутствуют.
 * @prop checkboxLabel - Лейбл чекбокса, отображающегося в попапе с редактированием доступов и в
 * попапе с добавлением доступов.
 * @prop loadPermissions - Функция, загружающая доступы.
 * @prop updatePermissions: - Функция, обновляющая доступы.
 * @prop buttonTitle - Текст внутри кнопки.
 * @prop buttonColor? - Цвет кнопки.
 * @prop buttonVariant? - Вариант кнопки;
 * @prop accessPermissionRoutePath - Путь к попапу со списком доступов. Он должен совпадать с урл
 * страницы на которой находится данный компонент, и добавлять в конец свой сегмент.
 * @prop editPermissionRoutePath - Путь к попапам редактирования и добавления доступов. Он должен совпадать
 * с урл страницы на которой находится данный компонент, с путем к попапу со списком доступов и добавлять в
 * конец свой сегмент, например "/edit".
 * @prop accessPermissionRoutePathTemplate - ??? Базовый путь к попапу со списком доступов.
 * @prop editPermissionRoutePathTemplate - ??? Базовый путь к попапам редактирования и добавления доступов.
 */
export const CampaignSettingsAccessPermissionButton = observer(
    (props: CampaignSettingsAccessPermissionButtonProps): JSX.Element => {
        const [CampaignSettingsAccessPermissionDialog] = di(
            [CampaignSettingsAccessPermissionDialogInj],
            CampaignSettingsAccessPermissionButton,
        );

        const { pathname } = useLocation();
        const history = useHistory();

        const {
            accessPermissionDialogTitle,
            editPermissionDialogTitle,
            addPermissionDialogTitle,
            emptyAccessPermissionList,
            checkboxLabel,
            loadPermissions,
            updatePermissions,
            buttonTitle,
            buttonColor = 'secondary',
            buttonVariant = 'contained',
            accessPermissionRoutePathTemplate,
            editPermissionRoutePathTemplate,
            accessPermissionRoutePath,
            editPermissionRoutePath,
        } = props;

        const { selectStore } = useStore();

        const [selectedPermissionIndex, setSelectedPermissionIndex] = useState<SelectedIndex>(null);
        const [selectedDeleteIndex, setSelectedDeleteIndex] = useState<number>(0);

        const model = useMemo(() => new AccessPermissionModel(selectStore, loadPermissions, updatePermissions), []);

        const openEditPermissionDialog = (): void => {
            history.push(editPermissionRoutePath);
        };

        const openPermissionsListDialog = (): void => {
            history.push(accessPermissionRoutePath);
        };

        const goBack = (): void => {
            history.push(pathname.replace(lastRouteRegexp, ''));
        };

        const editPermission = useCallback(
            (permissionIndex: SelectedIndex): VoidFunction =>
                (): void => {
                    openEditPermissionDialog();
                    setSelectedPermissionIndex(permissionIndex);
                },
            [openEditPermissionDialog],
        );

        const handleOpenAddPermission = useCallback(editPermission(null), [editPermission]);

        const onDeleteConfirm = useCallback((): Promise<void> => {
            return model.deletePermission(selectedDeleteIndex);
        }, [model, selectedDeleteIndex]);

        const onDelete = useCallback((deleteIndex: number): VoidFunction => {
            return (): void => {
                setSelectedDeleteIndex(deleteIndex);
            };
        }, []);

        const editOrAddPermissionDialogTitle =
            selectedPermissionIndex !== null ? editPermissionDialogTitle : addPermissionDialogTitle;

        const contextValues: CampaignSettingsAccessPermissionContextType = {
            editOrAddPermissionDialogTitle,
            checkboxLabel,
            model,
            selectedPermissionIndex,
            loadPermissions,
            updatePermissions,
            editPermission,
            onDeleteConfirm,
            onDelete,
            editPermissionRoutePath: editPermissionRoutePathTemplate,
            goBack,
        };
        return (
            <CampaignSettingsAccessPermissionContext.Provider value={contextValues}>
                <Button color={buttonColor} variant={buttonVariant} onClick={openPermissionsListDialog}>
                    {buttonTitle}
                </Button>

                <Route path={accessPermissionRoutePathTemplate}>
                    <CampaignSettingsAccessPermissionDialog
                        handleOpenAddPermissionDialog={handleOpenAddPermission}
                        dialogTitle={accessPermissionDialogTitle}
                        emptyListTitle={emptyAccessPermissionList}
                    />
                </Route>
            </CampaignSettingsAccessPermissionContext.Provider>
        );
    },
);
