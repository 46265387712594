import { useQuery } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useRef } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { CampaignSettingsSectionSettingContext } from '../../../../../../contexts';
import { useSetBreadcrumbsProps, useStore } from '../../../../../../hooks';
import { RequestFormSectionSettingModel } from '../../../../../../models';
import { CampaignRequestFormSectionEditParams } from './CampaignSettingsSectionSettingRoutes';

export type CampaignSettingsSectionSettingProviderProps = {
    children: JSX.Element;
};

export const CampaignSettingsSectionSettingProvider = observer((props: CampaignSettingsSectionSettingProviderProps) => {
    const { children } = props;

    const isCreatePage = !!useRouteMatch(clientRoute.campaignSettingsRequestFormSectionSettingsCreate);
    const isEditPage = !!useRouteMatch([
        clientRoute.campaignSettingsRequestFormSectionSettingsEdit,
        clientRoute.campaignSettingsRequestFormSectionSettingEdit,
    ]);

    const query = useQuery();
    const params = useParams<CampaignRequestFormSectionEditParams>();
    const { rfId, sectionSettingId: psectionSettingId, tabSettingId } = params;
    const sectionSettingId = psectionSettingId || query.get('sectionSettingId') || tabSettingId || '';

    const rootStore = useStore();
    const sectionModelRef = useRef<RequestFormSectionSettingModel>({} as RequestFormSectionSettingModel);
    const sectionModel: RequestFormSectionSettingModel = useMemo<RequestFormSectionSettingModel>(() => {
        const currentId = sectionModelRef?.current?.id;
        if (isEditPage && currentId === sectionSettingId) {
            return sectionModelRef.current;
        } else {
            const newModel = new RequestFormSectionSettingModel(rfId, sectionSettingId, rootStore, isCreatePage);
            sectionModelRef.current = newModel;
            return newModel;
        }
    }, [rfId, sectionSettingId, isCreatePage, isEditPage]);

    const { sectionSettingDTO } = sectionModel;
    const { title } = sectionSettingDTO;
    useSetBreadcrumbsProps({ sectionSettingId }, { sectionSetting: title });

    return (
        <CampaignSettingsSectionSettingContext.Provider
            value={{ rfId, sectionSettingId, isCreatePage, isEditPage, sectionSettingModel: sectionModel }}
        >
            {children}
        </CampaignSettingsSectionSettingContext.Provider>
    );
});
