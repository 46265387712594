import { AuthorizationCheck, OldSpacingThemeProvider } from '@platform/front-core';
import { IdTitle } from '@platform/front-utils';
import { TTable, TTableRowActions } from '@platform/ttable';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CellProps } from 'react-table';
import { entities, permissions } from '../../../authSchemeConfig';
import { useStore } from '../../../hooks';
import { CampaignRequestRow, RegistryCode } from '../../../types';

export type CampaignRequestListProps = {
    setRowActions: TTableRowActions<CampaignRequestRow>;
    title: string;
    customRegistryCode?: string;
    customTableHeight?: string;
};

// Пока реестр не обрабатывает массив с отв. лицами, такой временный обходной маневр.
const cellFormatters = {
    'customData.executors': function extractExecutorNameLink(p: CellProps<CampaignRequestRow>): JSX.Element {
        return (
            <React.Fragment>
                {p.row.original.customData.executors
                    .map((executor: IdTitle) => {
                        return executor.title;
                    })
                    .join(', ')}
            </React.Fragment>
        );
    },
};

export const CampaignRequestList = observer((props: CampaignRequestListProps): JSX.Element => {
    const { setRowActions, title, customRegistryCode, customTableHeight } = props;

    const { coreRootStore } = useStore();
    const { catalogStore, userStore, intlStore, api } = coreRootStore;

    return (
        <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.Administration}>
            {(allowed): JSX.Element => (
                <OldSpacingThemeProvider>
                    <TTable<CampaignRequestRow>
                        tableTitle={title}
                        client={api.client}
                        registryCode={customRegistryCode ? customRegistryCode : RegistryCode.requests}
                        fetchCatalog={catalogStore.getCatalog}
                        fetchSelectDataByUrl={catalogStore.getSelectDataByUrl}
                        lang={intlStore.locale}
                        fetchUserRoleList={userStore.userRoleList}
                        isAdmin={allowed}
                        rowActions={setRowActions}
                        cellFormatters={cellFormatters}
                        maxTableHeight={customTableHeight}
                    />
                </OldSpacingThemeProvider>
            )}
        </AuthorizationCheck>
    );
});
