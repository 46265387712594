import { SxProps, TabsProps, Theme } from '@mui/material';

export const campaignRequestInfoPanelWidth = 340;
export const sxRightSideCampaignRequest: SxProps<Theme> = {
    minWidth: campaignRequestInfoPanelWidth,
    maxWidth: campaignRequestInfoPanelWidth,
};

export const sxHeight100: SxProps<Theme> = {
    height: '100%',
};

export const maxWidth100: SxProps<Theme> = {
    maxWidth: '100% !important',
};

export const sxWidth10: SxProps<Theme> = { width: '10%' };
export const sxWidth15: SxProps<Theme> = { width: '15%' };
export const sxWidth20: SxProps<Theme> = { width: '20%' };
export const sxWidth25: SxProps<Theme> = { width: '25%' };
export const sxWidth30: SxProps<Theme> = { width: '30%' };
export const sxWidth35: SxProps<Theme> = { width: '35%' };
export const sxWidth40: SxProps<Theme> = { width: '40%' };
export const sxWidth45: SxProps<Theme> = { width: '45%' };
