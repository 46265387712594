import { Features } from '../../types';

const features: Features = {
    loginWithoutRegister: true,
    headerLogo: false,
    customLogo: {
        isEnabled: true,
        src: require('./misis-logo.svg'),
        // locales: {
        //     ru: {
        //         src: require('./misis-logo.svg'),
        //         alt: 'НИТУ «МИСиС»',
        //     },
        // },
        style: { width: '100%', maxWidth: '125px', height: '70px' },
        withText: false,
        mdLogo: false,
    },
    customBackground: false,
    allUsersRedirectPath: false,
    search: false,
    helpIcon: true,
    notificationIcon: true,
    phoneNumber: true,
    esiaLogin: false,
    showCreateRequestButton: true,
    showRequestsCount: true,
    loginDialogAlert: false,
    yaMetrika: true,
    modularNavigation: true,
    footerLogo: false,
    samlAuth: false,
    showCampaignPeriod: true,
    isWithRegistrationPage: true,
};

export default features;
