import { createTheme, Theme, ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren } from 'react';

export type OldSpacingThemeProviderProps = PropsWithChildren<{}>;

const createThemeCreator = (theme: Theme): Theme =>
    createTheme({
        ...theme,
        spacing: 4,
    });

export const OldSpacingThemeProvider = observer((props: OldSpacingThemeProviderProps): JSX.Element => {
    const { children } = props;

    return <ThemeProvider theme={createThemeCreator}>{children}</ThemeProvider>;
});
