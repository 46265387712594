import { SidebarItem } from '@platform/formiojs-react';
import { ElementPositionUtils, scrollToElementById } from '@platform/front-utils';
import { action, makeObservable, observable, toJS } from 'mobx';
import React, { RefObject } from 'react';
import { BootstrapPanelUtils, dropFocus, getSelectedSidebarItemsIds } from '../utils';

export const FormSidebarModelProps = {
    formWrapperRef: observable,
    sidebarWrapperRef: observable,
    sidebarItems: observable,
    expandedItems: observable,
    selectedItems: observable,
    itemAnchorPrefix: observable,
    handleScroll: action.bound,
    onNodeSelect: action.bound,
    findIntoViewElement: action.bound,
    handleSelectItems: action.bound,
    expandItem: action.bound,
    decreaseItem: action.bound,
    setSidebarItems: action.bound,
    setExpandedItems: action.bound,
    setSelectedItems: action.bound,
};

export class FormSidebarModel {
    formWrapperRef: RefObject<HTMLDivElement>;
    sidebarWrapperRef: RefObject<HTMLDivElement>;
    sidebarItems: SidebarItem[] = [];
    expandedItems: string[] = [];
    selectedItems: string[] = [];
    itemAnchorPrefix = 'sidebarItem-';

    constructor(formWrapperRef: RefObject<HTMLDivElement>, sidebarWrapperRef: RefObject<HTMLDivElement>) {
        makeObservable(this, FormSidebarModelProps);
        this.formWrapperRef = formWrapperRef;
        this.sidebarWrapperRef = sidebarWrapperRef;
    }

    handleScroll(): void {
        const isChildInView = this.findIntoViewElement(this.sidebarItems);
        !isChildInView && this.findIntoViewElement(this.sidebarItems, false);
        const lastSelectedItemId = this.selectedItems.length - 1;
        const sidebarWrapperElement = this.sidebarWrapperRef.current;
        sidebarWrapperElement &&
            scrollToElementById(this.itemAnchorPrefix + this.selectedItems[lastSelectedItemId], sidebarWrapperElement);
    }

    onNodeSelect(event: React.SyntheticEvent, nodeIds: string[]): void {
        const isClickEvent = event.type === 'click';
        const tagName = (event.target as HTMLElement).tagName;
        const isClickOnButton = tagName === 'BUTTON' || tagName === 'svg' || tagName === 'path';

        if (!isClickOnButton) {
            const firstNodeId = nodeIds[0];
            const currentSelectedItemId =
                isClickEvent || nodeIds.length !== 1
                    ? firstNodeId
                    : toJS(this.selectedItems).find((itemId) => itemId !== firstNodeId);

            if (currentSelectedItemId) {
                const wrapperElement = this.formWrapperRef.current;

                BootstrapPanelUtils.openPanelById(currentSelectedItemId);
                wrapperElement && scrollToElementById(currentSelectedItemId, wrapperElement);
                isClickEvent && dropFocus();
            }
        }
    }

    findIntoViewElement(sidebarItems: SidebarItem[], isFindByChild = true): boolean {
        const wrapperElement = this.formWrapperRef.current;

        return sidebarItems.some((item) => {
            const { id, subItems } = item;

            if (subItems && isFindByChild) {
                return this.findIntoViewElement(subItems);
            }

            const element: HTMLElement | undefined | null = wrapperElement?.querySelector('#' + id);

            const isElementInView =
                element && ElementPositionUtils.isPartOfElementInViewInParentElement(element, wrapperElement);

            if (isElementInView) {
                this.handleSelectItems(id);
                return isElementInView;
            }
        });
    }

    handleSelectItems(nodeId: string): void {
        const newSelectedItemsIds = getSelectedSidebarItemsIds(toJS(this.sidebarItems), nodeId);
        this.setSelectedItems(newSelectedItemsIds);
    }

    expandItem(nodeId: string): void {
        const newtExpandedItemsIds: string[] = [...this.expandedItems, nodeId];
        this.setExpandedItems(newtExpandedItemsIds);
    }

    decreaseItem(nodeId: string): void {
        const newExpandedItemsIds = [...this.expandedItems];
        const itemIndex = newExpandedItemsIds.indexOf(nodeId);
        newExpandedItemsIds.splice(itemIndex, 1);
        this.setExpandedItems(newExpandedItemsIds);
    }

    setSidebarItems(sidebarItems: SidebarItem[]): void {
        this.sidebarItems = sidebarItems;
    }

    setExpandedItems(expandedItems: string[]): void {
        this.expandedItems = expandedItems;
    }

    setSelectedItems(selectedItems: string[]): void {
        this.selectedItems = selectedItems;
        this.setExpandedItems(selectedItems);
    }
}
