import { clientRoute } from '../../clientRoute';
import { Features } from '../../types';

const features: Features = {
    loginWithoutRegister: true,
    headerLogo: false,
    customLogo: {
        isEnabled: true,
        src: require('./cniioiz-logo.png'),
        // locales: {
        //     ru: {
        //         src: require('./cniioiz-logo.png'),
        //         alt: 'ЦНИИОИЗ',
        //     },
        // },
        style: { width: '400px', height: '70px' },
        withText: false,
        mdLogo: false,
    },
    customBackground: {
        isEnabled: true,
        src: require('./cniioiz-background.png'),
        style: {
            backgroundPositionY: '-100px',
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
        },
    },
    helpIcon: true,
    notificationIcon: true,
    changeLanguage: true,
    phoneNumber: false,
    esiaLogin: false,
    showCreateRequestButton: true,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: true,
    footerLogo: false,
    samlAuth: false,
    showCampaignPeriod: true,
    allUsersRedirectPath: {
        isEnabled: true,
        path: clientRoute.campaigns,
    },
    showRequestsCount: true,
    isWithRegistrationPage: true,
};

export default features;
