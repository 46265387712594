import {
    AccessPermissionRequests,
    AccessPermissionSelects,
    DefaultNewPermissionDTO,
    PermissionSettingsDTO,
} from '@platform/front-core';
import { action, makeObservable, observable } from 'mobx';
import { CampaignsStore } from '../stores';

export const campaignSettingsRequestAccessPermissionModelObservables = {
    requestFormId: observable,
    campaignsStore: observable,
    loadPermissions: action.bound,
    loadSelects: action.bound,
    updatePermissions: action.bound,
};

export class CampaignSettingsRequestAccessPermissionModel implements AccessPermissionRequests {
    protected requestFormId: string;
    protected campaignsStore: CampaignsStore;

    constructor(requestFormId: string, campaignsStore: CampaignsStore) {
        this.requestFormId = requestFormId;
        this.campaignsStore = campaignsStore;

        makeObservable(this, campaignSettingsRequestAccessPermissionModelObservables);
    }

    loadPermissions(): Promise<PermissionSettingsDTO> {
        return this.campaignsStore.requestPermissions(this.requestFormId);
    }

    loadSelects(): Promise<AccessPermissionSelects> {
        return this.campaignsStore.requestPermissionsSelectData(this.requestFormId);
    }

    updatePermissions(permissions: DefaultNewPermissionDTO[]): Promise<void> {
        return this.campaignsStore.updateRequestPermissions(this.requestFormId, permissions);
    }
}
