import Validator from '@platform/formio/components/Validator';
import { defaultFormsLocale } from '@platform/front-utils';
export function validateComponentIfVisible(component, data, withoutSignatureValidate) {
    var isComponentVisible = component.checkConditions();
    return !isComponentVisible ? [] : validateComponent(component, data, withoutSignatureValidate);
}
var signatureValidator = 'signatureRequired';
function validateComponent(component, data, withoutSignatureValidate) {
    // Улучшила тем, что result привела к такому же виду, как в валидации через submit.
    // За счет этого ошибки можно выводить в правом меню навигации.
    var result = [];
    var currentComponentCheck;
    if (withoutSignatureValidate) {
        var validators = component.validators;
        var validatorsWithoutSignature = validators.map(function (validator) { return validator !== signatureValidator; });
        component.validators = validatorsWithoutSignature;
        currentComponentCheck = Validator.check(component, data);
        component.validators = validators;
    }
    else {
        currentComponentCheck = Validator.check(component, data);
    }
    if (currentComponentCheck) {
        result.push({
            component: component.component,
            message: currentComponentCheck,
        });
    }
    if (typeof component.getComponents === 'function') {
        return component
            .getComponents()
            .reduce(function (arr, comp) { return arr.concat(validateComponentIfVisible(comp, data, withoutSignatureValidate)); }, result);
    }
    else {
        return result;
    }
}
export var validateEditPage = function (formApi, formioSidebarStore, formName) {
    if (formApi) {
        return formApi
            .submit(true)
            .then(function () {
            formioSidebarStore && formName && formioSidebarStore.updateSidebarErrors(formName);
            return Promise.resolve();
        })
            .catch(function (errors) {
            formioSidebarStore && formName && formioSidebarStore.updateSidebarErrors(formName, errors);
            return Promise.reject(errors);
        });
    }
    else {
        return Promise.reject(['formApi is undefined']);
    }
};
export var validateReadonlyPage = function (props) {
    var setFormErrors = props.setFormErrors, setShowValidation = props.setShowValidation, onSuccess = props.onSuccess, formApi = props.formApi, formioSidebarStore = props.formioSidebarStore, formName = props.formName, withoutSignatureValidate = props.withoutSignatureValidate;
    // Для валидации заявки в текстовом виде пришлось добавить отдельный метод,
    // т.к. валидация через submit использует отрендеренные formio компоненты
    if (formApi) {
        var formData = formApi.getSubmission();
        if (!formData[defaultFormsLocale]) {
            return Promise.reject('Отсутствует submission. Надо заполнить форму');
        }
        var errors = validateComponentIfVisible(formApi.form(), formData[defaultFormsLocale].data, withoutSignatureValidate);
        if (!errors.length) {
            formioSidebarStore && formName && formioSidebarStore.updateSidebarErrors(formName);
            return onSuccess ? onSuccess() : Promise.resolve();
        }
        else {
            setFormErrors(errors);
            setShowValidation && setShowValidation(true);
            formioSidebarStore && formName && formioSidebarStore.updateSidebarErrors(formName, errors);
            return Promise.reject(errors);
        }
    }
    else {
        return Promise.reject(['formApi is undefined']);
    }
};
