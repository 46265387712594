import { Grid } from '@mui/material';
import { AuthorizationCheck, ObjectLayout, ObjectLayoutProps } from '@platform/front-core';
import { RouteParamsDefault } from '@platform/front-utils';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { permissionsConfig } from '../../../authSchemeConfig';
import { sxHeight100 } from '../../../constants';
import { CampaignCardPageContext } from '../../../contexts';
import { useCreateRequestDialog } from '../../../hooks';
import { CreateRequestDialog as CreateRequestDialogInj } from '../create-request-dialog';
import { CampaignCardBody as CampaignCardBodyInj } from './campaign-card-body';
import { CampaignCardTabs as CampaignCardTabsInj } from './';
import { CampaignCardHeader as CampaignCardHeaderInj } from './CampaignCardHeader';

/**
 * Обертка с разметкой страницы категории.
 * @returns JSX.Element
 */

export const CampaignCardPage = (): JSX.Element => {
    const [CreateRequestDialog] = di([CreateRequestDialogInj], CampaignCardPage);
    const [CampaignCardHeader] = di([CampaignCardHeaderInj], CampaignCardPage);
    const [CampaignCardTabs] = di([CampaignCardTabsInj], CampaignCardPage);
    const [CampaignCardBody] = di([CampaignCardBodyInj], CampaignCardPage);

    const { isCreateRequestDialogOpen, openCreateRequestDialog, closeCreateRequestDialog } = useCreateRequestDialog();
    const { id } = useParams<RouteParamsDefault>();
    const viewCampaignQuery = permissionsConfig.viewCampaign(id);

    const objectLayoutProps: ObjectLayoutProps = {
        header: <CampaignCardHeader />,
        tabs: {
            element: <CampaignCardTabs />,
        },
        contentLayout: {
            content: <CampaignCardBody />,
        },
    };

    return (
        <CampaignCardPageContext.Provider
            value={{
                isCreateRequestDialogOpen,
                openCreateRequestDialog,
                closeCreateRequestDialog,
            }}
        >
            <AuthorizationCheck {...viewCampaignQuery} isWithRedirect>
                <ObjectLayout {...objectLayoutProps} />
                <CreateRequestDialog onCancel={closeCreateRequestDialog} open={isCreateRequestDialogOpen} />
            </AuthorizationCheck>
        </CampaignCardPageContext.Provider>
    );
};
