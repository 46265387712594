import { Button, Grid } from '@mui/material';
import { DraggableList, EmptyList } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../../clientRoute';
import { CampaignSettingsTemplateListContext } from '../../../../../../../contexts';
import { useCampaignSettingsListContext, useStore } from '../../../../../../../hooks';
import {
    CampaignRequestFormSectionSettingsRouteParams,
    NewPermissionDTO,
    PermissionSettingsDTO,
    PermissionsType,
    TemplateListDTO,
} from '../../../../../../../types';
import { CampaignSettingsBodyHeader as CampaignSettingsBodyHeaderInj } from '../../../CampaignSettingsBodyHeader';
import { CampaignSettingsAccessPermissionButton as CampaignSettingsAccessPermissionButtonInj } from '../../../components';
import { renderSectionSettingTemplatesBody as renderSectionTemplatesBodyInj } from './renderSectionSettingTemplatesBody';

export const CampaignSettingsSectionSettingPfList = observer((): JSX.Element => {
    const [renderSectionTemplatesBody] = di([renderSectionTemplatesBodyInj], CampaignSettingsSectionSettingPfList);
    const [CampaignSettingsBodyHeader] = di([CampaignSettingsBodyHeaderInj], CampaignSettingsSectionSettingPfList);
    const [CampaignSettingsAccessPermissionButton] = di(
        [CampaignSettingsAccessPermissionButtonInj],
        CampaignSettingsSectionSettingPfList,
    );

    const { campaignsStore } = useStore();
    const params = useParams<CampaignRequestFormSectionSettingsRouteParams>();
    const { sectionSettingId } = params;
    const history = useHistory();
    const intl = useIntl();

    const { shouldLoadListItems, setShouldntLoadListItems, setShouldLoadListItems } = useCampaignSettingsListContext();

    const createPfTemplate = (): void => {
        history.push(generatePath(clientRoute.campaignSettingsRequestFormSectionSettingPFCreate, params));
    };

    const loadPfTemplates = useCallback(
        (): Promise<TemplateListDTO[]> => campaignsStore.loadSectionTemplates(sectionSettingId),
        [sectionSettingId],
    );
    const loadTemplatesPermissions = (): Promise<PermissionSettingsDTO> =>
        campaignsStore.loadAccessPermissions(sectionSettingId, PermissionsType.templatePermissions);
    const updateTemplatePermissions = (permissions: NewPermissionDTO[]): Promise<void> =>
        campaignsStore.saveAccessPermissions(sectionSettingId, permissions, PermissionsType.templatePermissions);

    const renderButtons = (): JSX.Element => {
        const intl = useIntl();
        const actionButtons = [
            <Grid item>
                <Button color="secondary" variant="outlined" onClick={createPfTemplate}>
                    <FormattedMessage id="campaignSettings.addTemplate" />
                </Button>
            </Grid>,
            <Grid item>
                <CampaignSettingsAccessPermissionButton
                    accessPermissionDialogTitle={intl.formatMessage({ id: 'campaignSettings.accessPfPermissions' })}
                    addPermissionDialogTitle={intl.formatMessage({ id: 'campaignSettings.addingPfPermissions' })}
                    editPermissionDialogTitle={intl.formatMessage({ id: 'campaignSettings.editingPfPermissions' })}
                    emptyAccessPermissionList={intl.formatMessage({
                        id: 'campaignSettings.emptyAccessPermissionListTemplates',
                    })}
                    checkboxLabel={intl.formatMessage({ id: 'campaignSettings.editPermissions.generate' })}
                    buttonTitle={intl.formatMessage({ id: 'campaignSettings.editPermissions.editPermission' })}
                    buttonVariant="outlined"
                    updatePermissions={updateTemplatePermissions}
                    loadPermissions={loadTemplatesPermissions}
                    accessPermissionRoutePathTemplate={
                        clientRoute.campaignSettingsRequestFormSectionSettingPFAccessPermissions
                    }
                    editPermissionRoutePathTemplate={
                        clientRoute.campaignSettingsRequestFormSectionSettingPFEditPermission
                    }
                    accessPermissionRoutePath={generatePath(
                        clientRoute.campaignSettingsRequestFormSectionSettingPFAccessPermissions,
                        params,
                    )}
                    editPermissionRoutePath={generatePath(
                        clientRoute.campaignSettingsRequestFormSectionSettingPFEditPermission,
                        params,
                    )}
                />
            </Grid>,
        ];
        return <React.Fragment>{actionButtons}</React.Fragment>;
    };

    const createButtonProps = {
        title: intl.formatMessage({ id: 'campaignSettings.addTemplate' }),
        createItem: createPfTemplate,
    };

    const renderDraggableListEmpty = (): JSX.Element => (
        <EmptyList
            noItemsTitle={intl.formatMessage({ id: 'campaignSettings.noTemplates' })}
            createButtonProps={createButtonProps}
        />
    );

    return (
        <CampaignSettingsTemplateListContext.Provider value={{ reloadList: setShouldLoadListItems }}>
            <Grid item container flexDirection="column" flexGrow={1} spacing={1} wrap="nowrap">
                <Grid item>
                    <CampaignSettingsBodyHeader
                        titleId="campaignSettings.templatesTitle"
                        renderButtons={renderButtons}
                    />
                </Grid>

                <Grid container item flexGrow={1}>
                    <DraggableList
                        loadListItems={loadPfTemplates}
                        changeItemPosition={campaignsStore.changeTemplatePosition}
                        renderDraggableListBody={renderSectionTemplatesBody}
                        renderDraggableListEmpty={renderDraggableListEmpty}
                        setShouldntLoadListItems={setShouldntLoadListItems}
                        shouldLoadListItems={shouldLoadListItems}
                    />
                </Grid>
            </Grid>
        </CampaignSettingsTemplateListContext.Provider>
    );
});
