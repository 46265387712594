import { TabsActions } from '@mui/material';
import { ObjectLayout, ObjectLayoutProps } from '@platform/front-core';
import { grey, SxStyle } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { di } from 'react-magnetic-di';
import { Route, useParams } from 'react-router-dom';
import { clientRoute } from '../../../clientRoute';
import { sxRightSideCampaignRequest } from '../../../constants';
import { useCampaignRequestPageContext } from '../../../hooks';
import { CampaignRequestRouteParams } from '../../../types';
import {
    CampaignRequestContent as CampaignRequestContentInj,
    CampaignRequestFormSidebar as CampaignRequestFormSidebarInj,
    CampaignRequestInfo as CampaignRequestInfoInj,
    CampaignRequestInfoTitle as CampaignRequestInfoTitleInj,
} from './campaign-request-body';
import { CampaignRequestHeader as CampaignRequestHeaderInj } from './campaign-request-header';
import { CampaignRequestSections as CampaignRequestSectionsInj } from './campaign-request-sections';

const campaignRequestInfoSx: SxStyle = {
    ...sxRightSideCampaignRequest,
    height: '100%',
    paddingX: 2,
    paddingTop: 2,
    borderLeft: `1px solid ${grey[300]}`,
    right: 0,
};

const campaignRequestInfoTitleWrapper: SxStyle = {
    mb: 1,
};

export const CampaignRequestPage = observer((): JSX.Element => {
    const [CampaignRequestHeader] = di([CampaignRequestHeaderInj], CampaignRequestPage);
    const [CampaignRequestContent] = di([CampaignRequestContentInj], CampaignRequestPage);
    const [CampaignRequestSections] = di([CampaignRequestSectionsInj], CampaignRequestPage);
    const [CampaignRequestInfoTitle] = di([CampaignRequestInfoTitleInj], CampaignRequestPage);
    const [CampaignRequestFormSidebar] = di([CampaignRequestFormSidebarInj], CampaignRequestPage);
    const [CampaignRequestInfo] = di([CampaignRequestInfoInj], CampaignRequestPage);

    const tabsActions = useRef<TabsActions>(null);
    const formWrapperRef = useRef<HTMLDivElement>(null);
    const { sectionId } = useParams<CampaignRequestRouteParams>();
    const { model, isRequestEdit, isSyslog } = useCampaignRequestPageContext();
    const { currentSection, formName, isLoading, loadSection, redirectToFirstOrCurrentSection } = model;

    useEffect(() => {
        // эффект должен срабатывать, когда пользователь уходит со страницы журнала
        if (!isSyslog && !sectionId && !isLoading) {
            redirectToFirstOrCurrentSection(isRequestEdit);
        }
    }, [isSyslog, redirectToFirstOrCurrentSection]); // остальные флаги умышленно не добавлены в dependencies, во избежание лишних вызовов

    useEffect(() => {
        sectionId && loadSection(sectionId);
    }, [sectionId, loadSection]);

    const objectLayoutProps: ObjectLayoutProps = {
        header: <CampaignRequestHeader />,
        contentLayout: {
            content: (
                <Route path={clientRoute.requestSection}>
                    <CampaignRequestContent formWrapperRef={formWrapperRef} />
                </Route>
            ),
            navigationSidebar: currentSection && currentSection.formDTO && (
                <CampaignRequestFormSidebar
                    section={currentSection}
                    formWrapperRef={formWrapperRef}
                    formName={formName}
                />
            ),
        },
        infoSidebar: {
            isWithToggle: true,
            elements: {
                contentElement: <CampaignRequestInfo />,
                wrapperSx: campaignRequestInfoSx,
                titleWrapperSx: campaignRequestInfoTitleWrapper,
                titleElement: <CampaignRequestInfoTitle />,
            },
        },
        tabs: {
            element: <CampaignRequestSections tabsActions={tabsActions} />,
        },
    };

    return <ObjectLayout {...objectLayoutProps} />;
});
