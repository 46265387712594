import { Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import { highlight, languages } from 'prismjs';
import 'prismjs/themes/prism.css';
import React from 'react';
import Editor from 'react-simple-code-editor';

export type JSONEditorFieldProps = {
    value: string;
    onChange: (value: string) => void;
    name: string;
    labelValue: string;
};

const consoleEditorClass = 'language-javascript';

const useStyles = makeStyles(() => {
    const theme = useTheme();
    return {
        jsonEditor: {
            flex: 1,
            borderRadius: '4px',
            padding: `${theme.spacing(1.25)} ${theme.spacing(2)} !important`,
            border: `1px solid ${grey[500]}`,
            '& textarea': {
                padding: `${theme.spacing(1.25)} ${theme.spacing(2)} !important`,
            },
            '& *': {
                fontFamily: '"Fira code", "Fira Mono", monospace !important',
                textShadow: 'none !important',
            },
        },
        jsonEditorWrapper: {
            marginTop: theme.spacing(2),
            position: 'relative',
        },
        jsonEditorWrapperLabel: {
            position: 'absolute',
            top: '-' + theme.spacing(1),
            left: '12px',
            backgroundColor: theme.palette.common.white,
            zIndex: 999,
            padding: '0px 5px',
            color: grey[500],
        },
    };
});

/**
 * Компонент, который содержит поле с редактором кода в формате JSON.
 * @prop value - Значение поля
 * @prop onChange - Функция, срабатывающая при изменении значения поля
 * @prop name - Имя для html-аттрибута поля
 * @prop labelValue - Значение лейбла над полем
 */
export const CampaignSettingsCommentsSettingDialogFormJSONEditorField = observer(
    (props: JSONEditorFieldProps): JSX.Element => {
        const { value, onChange, name, labelValue } = props;
        const classes = useStyles();

        const highlightCode = (text: string): string => {
            return highlight(text, languages.javascript, 'javascript');
        };

        return (
            <Grid item container flex={1} className={classes.jsonEditorWrapper}>
                <Typography variant="caption" className={classes.jsonEditorWrapperLabel}>
                    {labelValue}
                </Typography>
                <Editor
                    name={name}
                    highlight={highlightCode}
                    onValueChange={onChange}
                    value={value}
                    textareaClassName={consoleEditorClass}
                    preClassName={consoleEditorClass}
                    className={classes.jsonEditor}
                />
            </Grid>
        );
    },
);
