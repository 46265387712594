import { LocalesTree } from '@platform/front-utils';

const campaign: LocalesTree = {
    titleCampaignListPage: 'Campaigns',
    newCampaign: 'New campaign',
    titleCreateCampaignPage: 'Create campaign',
    emptyList: 'There are currently no created campaigns',
    emptyActiveList: 'There are currently no active campaigns',
    createCampaign: 'Create campaign',
    editCampaign: 'Edit campaign',
    files: 'Files',
    campaignPeriod: 'Active',
    campaignPeriodIfNotStarted: 'Awaiting launch',
    createRequest: 'Create request',
    commonInfo: 'Common information',
    description: 'Description',
    requests: 'Requests',

    filters: {
        title: 'Title',
        allStates: 'All states',
        startDate: 'Start date',
        endDate: 'End date',
    },

    lifecycle: 'Lifecycle',
    settingsRequestFormTabs: {
        information: 'Information',
        sections: 'Sections',
        pf: 'Print form templates',
    },

    campaignManagementDelete: 'Are you sure you want to delete the campaign «{campaignName}»?',
    deleteCampaign: 'Delete campaign',

    campaignRequestFormExecutorResponsibleExecutor: 'Responsible executor',

    collectionCompleted: 'Collection completed',
    createRequestError: 'Creation of requests by campaign is not available',

    userRequests: {
        title: 'Your requests',
        endMessage: 'No more requests',
        isEmpty: "You don't have any requests created",
        createRequest: 'Create a new request',
        fields: {
            created: 'Created',
            sent: 'Sent',
            sentAlt: 'Not sent',
            regForm: 'Request form',
            number: 'Request number',
        },
    },

    requestForm: {
        createRequest: 'Go to request creation',
    },
};

export default campaign;
