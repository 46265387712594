import { Box, Grid, Typography } from '@mui/material';
import { grey, makeSxStyles, Tag } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { CampaignCardUserRequestCardColumn as CampaignCardUserRequestCardColumnInj } from './CampaignCardUserRequestCardColumn';
import { CampaignCardUserRequestCardColumnItemProps } from './CampaignCardUserRequestCardColumnItem';

export type RequestCardDTO = {
    id: string;
    title?: string;
    number: string;
    regFormTitle: string;
    created: string;
    sent?: string;
    state: string;
};

export type CampaignCardRequestCardProps = {
    dto: RequestCardDTO;
};

export const campaignCardUserRequestCardSxStyles = makeSxStyles({
    root: {
        display: 'grid',
        gridTemplateRows: 'repeat(2, auto)',
        rowGap: (theme) => theme.spacing(1),
        border: `1px solid ${grey[200]}`,
        borderRadius: '8px',
        padding: (theme) => `${theme.spacing(1.875)} ${theme.spacing(1.875)}`,
        cursor: 'pointer',

        '&:hover': {
            borderColor: grey[400],
        },

        '&:focus': {
            borderColor: grey[600],
        },
    },
    label: {
        color: grey[600],
    },
    tagItem: {
        display: 'inline-flex',
    },
});

export const CampaignCardUserRequestCard = observer((props: CampaignCardRequestCardProps): JSX.Element => {
    const [CampaignCardUserRequestCardColumn] = di([CampaignCardUserRequestCardColumnInj], CampaignCardUserRequestCard);
    const { dto } = props;
    const { title, number, regFormTitle, sent, state, created, id } = dto;

    const intl = useIntl();

    const createdItem: CampaignCardUserRequestCardColumnItemProps = {
        label: intl.formatMessage({ id: 'campaign.userRequests.fields.created' }),
        value: created ? intl.formatDate(created) : '',
    };

    const sentItem: CampaignCardUserRequestCardColumnItemProps = {
        label: intl.formatMessage({ id: 'campaign.userRequests.fields.sent' }),
        value: sent ? intl.formatDate(sent) : '',
        altValue: intl.formatMessage({ id: 'campaign.userRequests.fields.sentAlt' }),
    };

    const regFormItem: CampaignCardUserRequestCardColumnItemProps = {
        label: intl.formatMessage({ id: 'campaign.userRequests.fields.regForm' }),
        value: regFormTitle,
    };

    const numberItem: CampaignCardUserRequestCardColumnItemProps = {
        label: intl.formatMessage({ id: 'campaign.userRequests.fields.number' }),
        value: number,
    };

    const isWithNumber = !title;

    const columnsConfig: CampaignCardUserRequestCardColumnItemProps[][] = isWithNumber
        ? [[createdItem, sentItem], [regFormItem]]
        : [
              [numberItem, createdItem],
              [regFormItem, sentItem],
          ];

    const columns: JSX.Element[] = columnsConfig.map(
        (column, index): JSX.Element => (
            <Grid item key={index}>
                <CampaignCardUserRequestCardColumn items={column} />
            </Grid>
        ),
    );

    const history = useHistory();

    const goToRequestPage = (): void => {
        history.push(generatePath(clientRoute.request, { id }));
    };

    return (
        <Box sx={campaignCardUserRequestCardSxStyles.root} onClick={goToRequestPage}>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography variant="button">{isWithNumber ? number : title}</Typography>
                </Grid>
                <Grid item sx={campaignCardUserRequestCardSxStyles.tagItem}>
                    <Tag>{state}</Tag>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={5} flexWrap="nowrap">
                {columns}
            </Grid>
        </Box>
    );
});
