import { Button } from '@mui/material';
import { ConfirmationDialog } from '@platform/front-core';
import { PromiseVoidFunction, useFlag } from '@platform/front-utils';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export type CampaignRequestAddActualAppointmentButtonProps = {
    handleConfirm: PromiseVoidFunction;
    requestNumber: string;
};

export const CampaignRequestAddActualAppointmentButton = (
    props: CampaignRequestAddActualAppointmentButtonProps,
): JSX.Element => {
    const { handleConfirm, requestNumber } = props;
    const [open, openConfirmation, closeConfirmation] = useFlag();
    const intl = useIntl();

    return (
        <React.Fragment>
            <Button onClick={openConfirmation} color="primary" variant="contained">
                <FormattedMessage id="campaignRequest.actions.requestToWork" />
            </Button>
            <ConfirmationDialog
                open={open}
                onCancel={closeConfirmation}
                id="addActualAppointment"
                keepMounted
                onConfirm={handleConfirm}
                title={intl.formatMessage({ id: 'campaignRequest.dialog.executorsTitle' })}
                message={intl.formatMessage(
                    { id: 'campaignRequest.dialog.executorsMessage' },
                    { number: requestNumber },
                )}
            />
        </React.Fragment>
    );
};
