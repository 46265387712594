import { SignatureSettings, SignatureStatus } from '@platform/crypto-ui';
import { FileDTO, PersonDTO } from '@platform/front-core';

export enum PrintFormStatusCode {
    completed = 'COMPLETED',
    canceled = 'CANCELED',
    attemptFailed = 'ATTEMPT_FAILED',
    failed = 'FAILED',
    new = 'NEW',
}

export type DelayedTaskStatus = {
    taskStatus: PrintFormStatusCode;
    title: string;
};

export type PrintFormDTO = {
    templateId: string;
    filename: string;
    docCategory?: string;
    signatureSettings: SignatureSettings;
    signatureStatus: SignatureStatus;
    author?: PersonDTO;
    created?: string; //Date
    pf?: FileDTO;
    delayedTask?: DelayedTaskStatus;
    required: string;
};
