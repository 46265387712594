import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton } from '@mui/material';
import { IdTitle, useFlag, VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useCampaignRequestPageContext } from '../../../../hooks';
import { CampaignRequestRouteParams } from '../../../../types';
import {
    CampaignRequestEditExecutorDialog as CampaignRequestEditExecutorDialogInj,
    CampaignRequestEditExecutorFormType,
} from '../../components';

export type CampaignRequestUpdateAppointmentProps = {
    isIconButton?: boolean;
    handleSuccessSubmit: VoidFunction;
};

export const CampaignRequestUpdateAppointmentButton = observer(
    (props: CampaignRequestUpdateAppointmentProps): JSX.Element => {
        const [CampaignRequestEditExecutorDialog] = di(
            [CampaignRequestEditExecutorDialogInj],
            CampaignRequestUpdateAppointmentButton,
        );

        const { isIconButton = false, handleSuccessSubmit } = props;

        const { id } = useParams<CampaignRequestRouteParams>();

        const [open, openDialog, closeDialog] = useFlag();

        const { model } = useCampaignRequestPageContext();
        const { editExecutor } = model;

        const handleSubmitNewExecutor = (values: CampaignRequestEditExecutorFormType): Promise<void> => {
            const { executor } = values;
            return editExecutor((executor as IdTitle).id)
                .then(handleSuccessSubmit)
                .finally(closeDialog);
        };

        return (
            <React.Fragment>
                {isIconButton ? (
                    <IconButton size="large" onClick={openDialog}>
                        <EditIcon />
                    </IconButton>
                ) : (
                    <Button variant="contained" color="secondary" onClick={openDialog}>
                        <FormattedMessage id="campaignRequest.actions.appointExecutor" />
                    </Button>
                )}
                <CampaignRequestEditExecutorDialog
                    onCancel={closeDialog}
                    onSubmit={handleSubmitNewExecutor}
                    open={open}
                    requestId={id}
                />
            </React.Fragment>
        );
    },
);
