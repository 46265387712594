var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { action, makeObservable, observable } from 'mobx';
import { checkCondition } from '@platform/formio/utils/utils';
export var FormioSidebarStoreProps = {
    items: observable,
    initSidebarItems: action.bound,
    updateItemsVisibility: action.bound,
    updateSidebarErrors: action.bound,
    findErrorInComponentsRecursive: action.bound,
    setItems: action.bound,
};
var FormioSidebarStore = /** @class */ (function () {
    function FormioSidebarStore() {
        this.items = {};
        makeObservable(this, FormioSidebarStoreProps);
    }
    FormioSidebarStore.prototype.initSidebarItems = function (objectId, form) {
        var formioForm;
        if (typeof form === 'function') {
            formioForm = form();
        }
        else {
            formioForm = form;
        }
        var formData = formioForm.data;
        var sidebarItems = [];
        formioForm.component.components.forEach(function (parent) {
            if (parent.title) {
                var parentId_1 = parent.id;
                var sidebarItem = {
                    id: parentId_1,
                    title: parent.title,
                    component: parent,
                    isShown: checkCondition(parent, formData, formData),
                    hasError: false,
                    subItems: [],
                    errorsCount: 0,
                    components: [],
                };
                if (Array.isArray(parent.components)) {
                    var subItems_1 = [];
                    var components_1 = [];
                    parent.components.forEach(function (child) {
                        if (child.title) {
                            subItems_1.push({
                                id: child.id,
                                title: child.title,
                                component: child,
                                isShown: checkCondition(child, formData, formData),
                                hasError: false,
                                parentId: parentId_1,
                            });
                        }
                        else {
                            components_1.push(child);
                        }
                    });
                    sidebarItem.subItems = subItems_1;
                    sidebarItem.components = components_1;
                }
                sidebarItems.push(sidebarItem);
            }
        });
        var items = __assign({}, this.items);
        items[objectId] = sidebarItems;
        this.setItems(items);
    };
    FormioSidebarStore.prototype.updateItemsVisibility = function (objectId, form) {
        var result = this.items[objectId].map(function (item) {
            var subItems = item.subItems;
            return __assign(__assign({}, item), { isShown: checkCondition(item.component, form, form), subItems: subItems &&
                    subItems.map(function (subItem) {
                        return __assign(__assign({}, subItem), { isShown: checkCondition(subItem.component, form, form) });
                    }) });
        });
        var items = __assign({}, this.items);
        items[objectId] = __spreadArray([], result, true);
        this.setItems(items);
        return result;
    };
    FormioSidebarStore.prototype.updateSidebarErrors = function (objectId, errors) {
        var _this = this;
        if (errors === void 0) { errors = []; }
        var itemsCopy = JSON.parse(JSON.stringify(this.items[objectId]));
        var errorsCopy = JSON.parse(JSON.stringify(errors));
        itemsCopy.forEach(function (item) {
            item.hasError = false;
            item.errorsCount = 0;
            var itemComponents = item.components;
            var subItems = item.subItems;
            if (subItems && subItems.length > 0) {
                subItems.forEach(function (subItem) {
                    subItem.hasError = false;
                    subItem.errorsCount = 0;
                    var subItemComponents = subItem.component.components;
                    if (subItemComponents && subItemComponents.length > 0) {
                        _this.findErrorInComponentsRecursive(subItemComponents, errorsCopy, subItem, item);
                    }
                });
            }
            if (itemComponents && itemComponents.length > 0) {
                _this.findErrorInComponentsRecursive(itemComponents, errorsCopy, {}, item);
            }
        });
        var items = __assign({}, this.items);
        items[objectId] = itemsCopy;
        this.setItems(items);
        return itemsCopy;
    };
    FormioSidebarStore.prototype.findErrorInComponentsRecursive = function (components, errors, subItem, item) {
        if (components === void 0) { components = []; }
        var componentsLength = components.length;
        var _loop_1 = function (i) {
            var component = components[i];
            var componentId = component.id;
            if (componentId !== undefined) {
                var errorIndex = errors.findIndex(function (errorComp) { return errorComp.component.id === componentId; });
                if (errorIndex !== -1) {
                    item.hasError = true;
                    item.errorsCount++;
                    subItem.hasError = true;
                    subItem.errorsCount++;
                    // удаляем только найденный элемент,
                    // т.к компонент panel может содержать обособленные компоненты, не обернутые в panel
                    errors.splice(errorIndex, 1);
                }
            }
            // Сам компонент в списке ошибок не найден, ищем дальше в его дочерних компонентах
            if (component.components && component.components.length) {
                this_1.findErrorInComponentsRecursive(component.components, errors, subItem, item);
            }
            else if (component.columns && component.columns.length) {
                // особый компонент, дочерние элементы которого называются columns
                this_1.findErrorInComponentsRecursive(component.columns, errors, subItem, item);
            }
            else if (component.rows && component.rows.length) {
                // компонент таблица, дочерние элементы которого называются rows
                for (var j = 0; j < component.rows.length; j++) {
                    this_1.findErrorInComponentsRecursive(component.rows[j], errors, subItem, item);
                }
            }
        };
        var this_1 = this;
        for (var i = 0; i < componentsLength; i++) {
            _loop_1(i);
        }
    };
    FormioSidebarStore.prototype.setItems = function (items) {
        this.items = items;
    };
    return FormioSidebarStore;
}());
export { FormioSidebarStore };
