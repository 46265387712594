import { Box, Button, Grid } from '@mui/material';
import { List } from '@platform/front-core';
import { FullScreenDialog, FullScreenDialogSize } from '@platform/front-ui';
import { VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Route } from 'react-router-dom';
import { useCampaignSettingsAccessPermissionContext, useStore } from '../../../../../../hooks';
import { CampaignSettingsEditPermissionDialog as CampaignSettingsEditPermissionDialogInj } from './CampaignSettingsEditPermissionDialog';
import { CampaignSettingsPermissionList as CampaignSettingsPermissionListInj } from './CampaignSettingsPermissionList';

export type CampaignSettingsAccessPermissionDialogProps = {
    handleOpenAddPermissionDialog: VoidFunction;

    dialogTitle: string;
    emptyListTitle: string;
};

export const CampaignSettingsAccessPermissionDialog = observer(
    (props: CampaignSettingsAccessPermissionDialogProps): JSX.Element => {
        const [CampaignSettingsPermissionList] = di(
            [CampaignSettingsPermissionListInj],
            CampaignSettingsAccessPermissionDialog,
        );

        const [CampaignSettingsEditPermissionDialog] = di(
            [CampaignSettingsEditPermissionDialogInj],
            CampaignSettingsAccessPermissionDialog,
        );

        const { dialogTitle, emptyListTitle, handleOpenAddPermissionDialog } = props;

        const { coreRootStore } = useStore();
        const { headerHeightStore } = coreRootStore;
        const { visibleHeaderHeight } = headerHeightStore;
        const { locale, formatMessage } = useIntl();
        const { model, editPermissionRoutePath, goBack } = useCampaignSettingsAccessPermissionContext();
        const { permissionList, isLoading, load } = model;

        const accessButtonTitle = formatMessage({ id: 'campaignSettings.editPermissions.addAccess' });

        useEffect(() => {
            load();
        }, [locale]);

        const createButtonProps = {
            title: accessButtonTitle,
            createItem: handleOpenAddPermissionDialog,
        };

        const emptyListProps = {
            noItemsTitle: emptyListTitle,
            createButtonProps: createButtonProps,
        };

        return (
            <FullScreenDialog
                size={FullScreenDialogSize.medium}
                open={true}
                headerHeight={visibleHeaderHeight}
                title={dialogTitle}
                onCancel={goBack}
            >
                <Grid container flexDirection="column" spacing={1} flexGrow={1}>
                    <Grid container item justifyContent="flex-end">
                        <Grid item>
                            <Box pr={1}>
                                <Button color="secondary" variant="outlined" onClick={handleOpenAddPermissionDialog}>
                                    {accessButtonTitle}
                                </Button>
                                <Route path={editPermissionRoutePath}>
                                    <CampaignSettingsEditPermissionDialog />
                                </Route>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item container flexGrow={1}>
                        <List isLoading={isLoading} {...(!permissionList.length && { emptyListProps })}>
                            <Grid item width="100%" paddingX={2} alignSelf="start">
                                <CampaignSettingsPermissionList />
                            </Grid>
                        </List>
                    </Grid>
                </Grid>
            </FullScreenDialog>
        );
    },
);
