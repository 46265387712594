import { CampaignSettingsLocales } from '../../../../types';

const campaignSettings: CampaignSettingsLocales = {
    confirmTransitionTitle: '{title}',
    publishCampaignMessage: 'Статус категории «{title}» будет изменён на «{toState}»',
    campaignSettings: 'Настроить категорию',
    pfTemplates: 'Шаблоны ПФ',
    noTemplates: 'Шаблоны отсутствуют',

    campaignDescription: 'Описание категории',
    campaignDescriptionEdit: 'Редактирование категории',
    campaignDescriptionCreate: 'Создание категории',

    comments: 'Комментарии',
    setUpAccessToComments: 'Настроить доступы к комментариям',

    campaignRequestForms: 'Формы заявок',
    addCampaignRequestFormButton: 'Добавить форму заявки',
    addingCampaignRequestForm: 'Добавление формы заявки',
    editCampaignRequestForm: 'Редактирование формы заявки',
    noRequestForms: 'Формы отсутствуют',
    requestFormInfo: 'Основная информация',

    campaignManagement: 'Управление категорией',

    addTemplate: 'Добавить шаблон',
    editTemplate: 'Редактировать шаблон',
    accessPfPermissions: 'Настройка доступов к шаблонам ПФ',
    addingPfPermissions: 'Добавление доступа к шаблонам ПФ',
    editingPfPermissions: 'Редактирование доступа к шаблонам ПФ',
    emptyAccessPermissionListTemplates: 'Доступы к шаблонам отсутствуют',

    accessSectionSetting: 'Настройка доступов к разделу',
    addingSectionPermission: 'Добавление доступа к разделу',
    editingSectionPermission: 'Редактирование доступа к разделу',
    setSectionAccess: 'Настроить доступы к разделу',
    emptyAccessPermissionListSection: 'Доступы к разделу отсутствуют',

    commentsSetting: {
        accessSectionSetting: 'Настроить доступы к комментариям',
        addingSectionPermission: 'Добавление доступа к комментариям',
        editingSectionPermission: 'Редактирование доступа к комментариям',
        setSectionAccess: 'Настроить доступы к комментариям',
        emptyAccessPermissionListSection: 'Доступы к комментариям отсутствуют',
    },

    requestAccessSetting: {
        accessSetting: 'Настроить доступы для реестра заявок',
        emptyPermissionList: 'Доступы к заявкам отсутствуют',
    },

    deletion: {
        delete: {
            CampaignRequestForm: 'Удалить форму заявки',
            SectionSetting: 'Удалить раздел',
            PF: 'Удалить шаблон',
        },
        confirmDelete: {
            CampaignRequestForm: 'Вы действительно хотите удалить форму заявки «{title}»?',
            SectionSetting: 'Вы действительно хотите удалить раздел «{title}»?',
            PF: 'Вы действительно хотите удалить шаблон «{title}»?',
        },
    },

    sectionForm: {
        title: {
            label: 'Название раздела',
            placeholder: 'Введите название раздела',
        },
        identifier: {
            label: 'Идентификатор раздела',
            placeholder: 'Введите идентификатор раздела',
        },
        fileSize: {
            label: 'Размер пакета загружаемых документов',
            placeholder: 'Введите значение в МБ',
        },
        form: {
            label: 'Код используемой формы',
            placeholder: 'Выберите значение',
        },
        executor: {
            label: 'Ответственный исполнитель',
            placeholder: 'Выберите пользователя',
        },
        parentSectionSetting: {
            label: 'Родительский раздел',
            placeholder: 'Выберите родительский раздел',
        },
        openOnCreation: {
            label: 'Открывать при создании заявки',
        },
        commentingAllowed: {
            label: 'Разрешить создание комментариев',
        },
        deletable: {
            label: 'Разрешить удалять комментарии',
        },
        editable: {
            label: 'Разрешить редактировать комментарии',
        },
        showComments: {
            label: 'Отображать комментарии',
        },
        maxCommentLength: {
            label: 'Максимальная длина комментария',
            placeholder: 'Введите максимальное возможное количество символов',
        },
        textEditorSettings: {
            label: 'Настройки форматирования текста',
        },
        threadsAllowed: {
            label: 'Разрешить треды (ответы)',
        },
    },
    sectionFileSize: '{fileSize} МБ',

    editPermissions: {
        list: {
            role: 'Роль',
            status: 'Статус ЖЦ',
            allStatuses: 'Все статусы',
        },
        group: {
            role: 'Роль',
            condition: 'Условие',
        },
        edit: 'Редактирование',
        generate: 'Генерация',
        view: 'Просмотр',
        addAccess: 'Добавить доступ',
        access: 'Доступ',
        accessSetting: 'Настройка доступа',
        editPermission: 'Настроить доступ',
        lifecycleStage: 'Этап жизненного цикла',
        deletePermission: 'Удалить доступ',
        deletePermissionConfirmationMessage: 'Вы действительно хотите удалить доступ для роли «{role}»?',
        canComment: 'Использование функций комментирования',
    },

    templateForm: {
        title: {
            label: 'Название шаблона',
            placeholder: 'Введите название',
        },
        description: {
            label: 'Описание',
            placeholder: 'Введите описание шаблона',
        },
        docCategory: {
            label: 'Категория документа',
            placeholder: 'Выберите значение',
        },
        fileFormat: {
            label: 'Формат',
            placeholder: '',
        },
        required: {
            label: 'Является обязательным для генерации',
            placeholder: '',
        },
        file: {
            label: 'Файл шаблона',
            placeholder: '',
        },
        ['signatureSettings.signatureEnabled']: {
            label: 'Включить использование электронной подписи',
            placeholder: '',
        },
        ['signatureSettings.signatureRequired']: {
            label: 'Обязательно наличие подписи',
            placeholder: '',
        },
        ['signatureSettings.signatureBrowserPlugin']: {
            label: 'Плагин браузера',
            placeholder: '',
        },
        ['signatureSettings.signatureUpload']: {
            label: 'Загрузка подписи или файла с прикреплённой подписью',
            placeholder: '',
        },
    },
    signatureVariants: 'Способы подписания',
    templateFormErrors: {
        notUniqueTemplateName: 'Название шаблона должно быть уникальным',
        executorsNotAllowed:
            'Данный способ назначения недоступен, так как выбран способ назначения ответственных по факту',
    },

    sectionsTitle: 'Разделы заявки',
    noSections: 'Разделы отсутствуют',
    addSectionButton: 'Создать раздел заявки',
    createSection: 'Создать раздел',
    creatingSection: 'Создание раздела',
    editingSection: 'Редактирование раздела',
    requestFormSettings: {
        title: 'Название раздела',
        formTitle: 'Форма',
    },

    fileFormat: 'Формат',

    templatesTitle: 'Шаблоны печатных форм в разделе',
    creatingTemplate: 'Добавление шаблона',
    editingTemplate: 'Редактирование шаблона',
};

export default campaignSettings;
