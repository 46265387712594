import { Grid, SxProps, Theme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { Route } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { CampaignSettingsDescription as CampaignSettingsDescriptionInj } from './description';
import {
    CampaignSettingsRequestFormRoutes as CampaignSettingsRequestFormRoutesInj,
    CampaignSettingsRequestForms as CampaignSettingsRequestFormsInj,
} from './request-forms';

const wrapperSx: SxProps<Theme> = {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
};

export const CampaignSettingsBody = observer((): JSX.Element => {
    const [CampaignSettingsDescription] = di([CampaignSettingsDescriptionInj], CampaignSettingsBody);
    const [CampaignSettingsRequestForms] = di([CampaignSettingsRequestFormsInj], CampaignSettingsBody);
    const [CampaignSettingsRequestFormRoutes] = di([CampaignSettingsRequestFormRoutesInj], CampaignSettingsBody);

    return (
        <Grid item flexGrow={1} sx={wrapperSx}>
            <Route
                path={[
                    clientRoute.campaignSettingsDescription,
                    clientRoute.createCampaign,
                    clientRoute.campaignSettingsEdit,
                ]}
                exact
            >
                <CampaignSettingsDescription />
            </Route>
            <Route path={clientRoute.campaignSettingsRequestForms} exact>
                <CampaignSettingsRequestForms />
            </Route>
            <Route
                path={[
                    clientRoute.campaignSettingsRequestForm,
                    clientRoute.campaignSettingsRequestFormInfo,
                    clientRoute.campaignSettingsRequestFormCreate,
                    clientRoute.campaignSettingsRequestFormEdit,
                    clientRoute.campaignSettingsRequestFormSectionSettings,
                    clientRoute.campaignSettingsRequestFormSectionSettingsCreate,
                    clientRoute.campaignSettingsRequestFormSectionSettingsEdit,
                    clientRoute.campaignSettingsRequestFormSectionSettingEdit,
                    clientRoute.campaignSettingsRequestFormSectionsOld,
                    clientRoute.campaignSettingsRequestFormSectionOld,
                    clientRoute.campaignSettingsRequestFormSectionEditOld,
                    clientRoute.campaignSettingsRequestFormSectionCreateOld,
                ]}
            >
                <CampaignSettingsRequestFormRoutes />
            </Route>
        </Grid>
    );
});
