import { CoreRootStore, PersonStore as PersonStoreInj, RedirectByRoleType, RolePriority } from '@platform/front-core';
import { makeObservable, override } from 'mobx';
import { di } from 'react-magnetic-di';
import { clientRoute } from '../clientRoute';

export const loginRedirectByRole: RedirectByRoleType = {
    User: clientRoute.campaigns,
    Employee: clientRoute.requests,
    SeniorEmployee: clientRoute.requests,
    Moderator: clientRoute.requests,
};

export const rolePriorities: RolePriority = {
    User: 0,
    Employee: 1,
    SeniorEmployee: 2,
    Moderator: 3,
};

export const PersonStoreICSProps = {
    getMainRoute: override,
    getRolePriorities: override,
    getLoginRedirectByRole: override,
};

export class PersonStore extends PersonStoreInj {
    constructor(rootStore: CoreRootStore) {
        super(rootStore);
        makeObservable(this, PersonStoreICSProps);
    }

    getMainRoute(): string {
        return clientRoute.campaigns;
    }

    getRolePriorities(): RolePriority {
        return rolePriorities;
    }

    getLoginRedirectByRole(): RedirectByRoleType {
        return loginRedirectByRole;
    }
}

export const getPersonStore = (): any => {
    const [_PersonStore] = di([PersonStore], getPersonStore);
    return _PersonStore;
};
