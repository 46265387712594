import { makeAutoObservable } from 'mobx';
var FormioFormApi = /** @class */ (function () {
    function FormioFormApi(form, readOnly) {
        if (readOnly === void 0) { readOnly = false; }
        makeAutoObservable(this);
        this.form = form;
        this.readOnly = readOnly;
    }
    FormioFormApi.prototype.setSubmission = function (s) {
        return this.form.setSubmission(s);
    };
    FormioFormApi.prototype.getSubmission = function () {
        return Object.assign({}, this.form.submission);
    };
    FormioFormApi.prototype.getExpertiseInfo = function () {
        var expertiseForm = this.form.components.find(function (_) { return _.component.type === 'expertiseForm'; });
        var getExpertiseInfo = expertiseForm === null || expertiseForm === void 0 ? void 0 : expertiseForm.getExpertiseInfo;
        getExpertiseInfo = getExpertiseInfo === null || getExpertiseInfo === void 0 ? void 0 : getExpertiseInfo.bind(expertiseForm);
        if (typeof getExpertiseInfo === 'function') {
            return getExpertiseInfo();
        }
        else {
            return undefined;
        }
    };
    FormioFormApi.prototype.validate = function () {
        this.form.setPristine(false);
        return this.form.checkData(this.getSubmission().data, {}, null);
    };
    FormioFormApi.prototype.submit = function (validation) {
        var options = validation ? { state: 'submitted' } : { state: 'draft' };
        return this.form.submit(false, options);
    };
    FormioFormApi.prototype.destroy = function () {
        this.form.destroy();
    };
    return FormioFormApi;
}());
export { FormioFormApi };
