import {
    CommentContextInfoDTO,
    CommentsAppConfig,
    CommentsAuthCheckingFunctions,
    CommentsAuthStatuses,
} from '@platform/comments';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { permissionsConfig } from '../../authSchemeConfig';
import { useCampaignRequestPageContext } from '../contexts';
import { useStore } from '../useStore';

export type UseCommentsAppConfigProps = {
    entityId: string;
    entityType: string;
};

type CreateConfigFunctionArgs = { isCreatingAuthorized: boolean; isAdmin: boolean };

/**
 * Хук, возвращающий необходимые данные (конфигурацию) для работы приложения комментариев.
 * @prop entityId - Id для распознавания какой-либо конкретной сущности модуля.
 * Например, если требуется авторизация на просмотр какой-либо конкретной заявки или категории,
 * соответсвенно вам потребуется передать её идентификатор.
 * @prop entityType - Код сущности из схемы авторизации на беке.
 */
export const useCommentsAppConfig = (props: UseCommentsAppConfigProps): CommentsAppConfig | null => {
    const { entityId, entityType } = props;
    const { commentsStore, coreRootStore } = useStore();
    const { authorizationStore, personStore } = coreRootStore;
    const { user } = personStore;
    const { id: currentUserId } = user;
    const { locale } = useIntl();
    const { model: requestModel } = useCampaignRequestPageContext();
    const { state: requestState } = requestModel;

    const [commentsAppConfig, setCommentsAppConfig] = useState<CommentsAppConfig | null>(null);

    const checkDeletingAuth = (commentId: string): Promise<boolean> => {
        return authorizationStore.check(permissionsConfig.deleteComment(commentId));
    };

    const createConfig = (args: CreateConfigFunctionArgs): void => {
        const { isAdmin, isCreatingAuthorized } = args;
        commentsStore.getCommentContextInfo({ entityId, entityType }).then((contextInfo: CommentContextInfoDTO) => {
            setCommentsAppConfig(() => {
                const statuses: CommentsAuthStatuses = { isCreatingAuthorized };
                const statusCheckers: CommentsAuthCheckingFunctions = { checkDeletingAuth };
                const config: CommentsAppConfig = {
                    contextInfo,
                    entityId,
                    entityType,
                    locale,
                    commentsSettings: contextInfo.attribute,
                    commentsStore,
                    userData: {
                        id: currentUserId,
                        isAdmin,
                    },
                    auth: {
                        statuses,
                        statusCheckers,
                    },
                };
                return config;
            });
        });
    };

    const checkIsCreatingAuthorized = (isAdmin: boolean): Promise<CreateConfigFunctionArgs> => {
        const defaultSettings = { isAdmin, isCreatingAuthorized: false };
        return isAdmin
            ? Promise.resolve({ ...defaultSettings, isCreatingAuthorized: true })
            : authorizationStore
                  .check(permissionsConfig.createComment(entityId))
                  .then((isCreatingAuthorized) => ({ ...defaultSettings, isCreatingAuthorized }))
                  .catch(() => defaultSettings);
    };

    const checkIsAdmin = (): Promise<boolean> => {
        return authorizationStore.check(permissionsConfig.administation());
    };

    const dropConfig = (): void => {
        setCommentsAppConfig(null);
    };

    /**
     * При смене локали, просто подменяем поле, потому что
     * перезагрузка приложения не нужна.
     */
    useEffect(() => {
        setCommentsAppConfig((config) => config && { ...config, locale });
    }, [locale]);

    /**
     * При смене раздела или статуса заявки,
     * выполняем всю проверку и генерируем конфиг.
     * */
    useEffect(() => {
        if (!entityId || !requestState) {
            return;
        }
        dropConfig();
        checkIsAdmin().then(checkIsCreatingAuthorized).then(createConfig);
    }, [entityId, requestState]);

    return commentsAppConfig;
};
