import { useYup } from '@platform/front-core';
import { FullScreenDialog, FullScreenDialogSize } from '@platform/front-ui';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useCampaignSettingsAccessPermissionContext, useStore } from '../../../../../../hooks';
import { AccessPermissionInitialValues } from '../../../../../../types';
import { CampaignSettingsEditPermissionForm as CampaignSettingsEditPermissionFormInj } from './CampaignSettingsEditPermissionForm';

const emptyInitialValues: AccessPermissionInitialValues = {
    roleCondition: null,
    whenObjectStateIsOneOf: [],
    allowEdit: false,
};

export const CampaignSettingsEditPermissionDialog = observer((): JSX.Element => {
    const [CampaignSettingsEditPermissionForm] = di(
        [CampaignSettingsEditPermissionFormInj],
        CampaignSettingsEditPermissionDialog,
    );
    const { editOrAddPermissionDialogTitle, model, selectedPermissionIndex, goBack } =
        useCampaignSettingsAccessPermissionContext();

    const { coreRootStore } = useStore();
    const { visibleHeaderHeight } = coreRootStore.headerHeightStore;

    const { Yup } = useYup();

    const schema = Yup.object().shape({
        roleCondition: Yup.object()
            .shape({
                title: Yup.string().required(),
            })
            .nullable()
            .required(),
        whenObjectStateIsOneOf: Yup.array(),
        allowEdit: Yup.boolean(),
    });

    const handleSubmit = (values: AccessPermissionInitialValues): Promise<void> => {
        return model.submitPermissions(values, selectedPermissionIndex, goBack);
    };

    const initialValues: AccessPermissionInitialValues = useMemo(() => {
        if (selectedPermissionIndex !== null) {
            return model.permissionList[selectedPermissionIndex];
        }
        return emptyInitialValues;
    }, [selectedPermissionIndex, model.permissionList]);

    return (
        <FullScreenDialog
            size={FullScreenDialogSize.medium}
            open={true}
            headerHeight={visibleHeaderHeight}
            title={editOrAddPermissionDialogTitle}
            onCancel={goBack}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                <CampaignSettingsEditPermissionForm />
            </Formik>
        </FullScreenDialog>
    );
});
