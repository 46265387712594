import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { Route, Switch } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { useCampaignSettingsSectionSettingContext } from '../../../../../../hooks';
import { CampaignSettingsSectionSettingHeader as CampaignSettingsSectionHeaderInj } from './CampaignSettingsSectionSettingHeader';
import { CampaignSettingsSectionSettingInfo as CampaignSettingsSectionInfoInj } from './CampaignSettingsSectionSettingInfo';
import { CampaignSettingsSectionSettingTabs as CampaignSettingsSectionTabsInj } from './CampaignSettingsSectionSettingTabs';
import {
    CampaignSettingsSectionSettingPfEdit as CampaignSettingsSectionPfEditInj,
    CampaignSettingsSectionSettingPfList as CampaignSettingSectionPfListInj,
} from './pf';

export const CampaignSettingsSectionSetting = observer((): JSX.Element => {
    const [CampaignSettingsSectionHeader] = di([CampaignSettingsSectionHeaderInj], CampaignSettingsSectionSetting);
    const [CampaignSettingsSectionTabs] = di([CampaignSettingsSectionTabsInj], CampaignSettingsSectionSetting);
    const [CampaignSettingSectionPfList] = di([CampaignSettingSectionPfListInj], CampaignSettingsSectionSetting);
    const [CampaignSettingsSectionInfo] = di([CampaignSettingsSectionInfoInj], CampaignSettingsSectionSetting);
    const [CampaignSettingsSectionPfEdit] = di([CampaignSettingsSectionPfEditInj], CampaignSettingsSectionSetting);

    const { sectionSettingId, sectionSettingModel } = useCampaignSettingsSectionSettingContext();
    const { sectionSettingDTO } = sectionSettingModel;
    const { title } = sectionSettingDTO;

    const headerItem = { id: sectionSettingId, title };

    return (
        <React.Fragment>
            <CampaignSettingsSectionHeader item={headerItem} />
            <CampaignSettingsSectionTabs />
            <Switch>
                <Route
                    path={[
                        clientRoute.campaignSettingsRequestFormSectionSettingEdit,
                        clientRoute.campaignSettingsRequestFormSectionSettingInfo,
                    ]}
                >
                    <CampaignSettingsSectionInfo />
                </Route>
                <Route path={clientRoute.campaignSettingsRequestFormSectionSettingPF}>
                    <CampaignSettingSectionPfList />
                    <Route
                        path={[
                            clientRoute.campaignSettingsRequestFormSectionSettingPFEdit,
                            clientRoute.campaignSettingsRequestFormSectionSettingPFCreate,
                        ]}
                    >
                        <CampaignSettingsSectionPfEdit />
                    </Route>
                </Route>
            </Switch>
        </React.Fragment>
    );
});
