import { clientRoute } from '../../clientRoute';
import { Features } from '../../types';

const features: Features = {
    loginWithoutRegister: false,
    headerLogo: false,
    customLogo: {
        isEnabled: true,
        src: require('./vtb-logo.svg'),
        // locales: {
        //     ru: {
        //         src: require('./vtb-logo.svg'),
        //         alt: 'ВТБ',
        //     },
        // },
        style: { width: '100%', maxWidth: '125px', height: '70px' },
        withText: false,
        mdLogo: false,
    },
    customBackground: {
        isEnabled: true,
        src: require('./vtb-background.jpg'),
    },
    helpIcon: false,
    notificationIcon: true,
    phoneNumber: false,
    esiaLogin: false,
    showCreateRequestButton: true,
    search: false,
    showRequestsCount: false,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: true,
    footerLogo: false,
    samlAuth: false,
    showCampaignPeriod: true,
    allUsersRedirectPath: {
        isEnabled: true,
        path: clientRoute.campaigns,
    },
    isWithRegistrationPage: true,
};

export default features;
