var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoaderBox } from '../styled/LoaderBox';
import { FormioForm } from './FormioForm';
import { observer } from 'mobx-react-lite';
export var FormRaw = observer(function (props) {
    var formApi = props.formApi, onFormReady = props.onFormReady, className = props.className, setFormIsChanged = props.setFormIsChanged, locale = props.locale, otherProps = __rest(props, ["formApi", "onFormReady", "className", "setFormIsChanged", "locale"]);
    var formModel = formApi.formModel, multiLang = formApi.multiLang, lang = formApi.lang, readOnly = formApi.readOnly;
    useEffect(function () {
        if (readOnly) {
            formApi.setLang(locale);
        }
    }, [locale]);
    var _a = useState(), submission = _a[0], setSubmission = _a[1];
    useEffect(function () {
        setSubmission(multiLang ? formModel.getLangSubmission(lang) : formModel.getSubmission());
    }, [lang, formModel.submission]);
    var onFormReadyCallback = function (form) {
        formApi.setFormioFormApi(form);
        form.form.formReady.then(function () {
            onFormReady && onFormReady(formApi);
        });
    };
    return (React.createElement(React.Fragment, null, formModel.loaded && submission ? (React.createElement(FormioForm, __assign({ form: formModel.form, submission: submission, i18n: formModel.i18n, locale: lang, onFormReady: onFormReadyCallback, readOnly: readOnly, className: className, setFormIsChanged: setFormIsChanged }, otherProps))) : (React.createElement(LoaderBox, null,
        React.createElement(CircularProgress, null)))));
});
