var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultFormsLocale } from '@platform/front-utils';
import cn from 'classnames';
import merge from 'deepmerge';
import i18next from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { FormioFormApi } from '../api/FormioFormApi';
import '../sass/forms.scss';
import { FormWithTheme } from './FormWithTheme';
import defaultI18n from './i18n.json';
var defaultOptions = { language: defaultFormsLocale, disableAlerts: true, noAlerts: true };
export var FormioForm = observer(function (props) {
    var form = props.form, onFormReady = props.onFormReady, readOnly = props.readOnly, onFormChange = props.onFormChange, onFieldFocus = props.onFieldFocus, onFieldBlur = props.onFieldBlur, setFormIsChanged = props.setFormIsChanged, locale = props.locale, formI18n = props.i18n, submission = props.submission, className = props.className;
    var formRef = useRef(null);
    var _a = useState(), instance = _a[0], setInstance = _a[1];
    var _b = useState(), i18nextInstance = _b[0], setI18nextInstance = _b[1];
    useEffect(function () {
        var i18n = merge(defaultI18n, { resources: formI18n || {} });
        setI18nextInstance(i18next.createInstance(i18n, function (err) {
            if (err) {
                console.error("can't create i18next instance", err);
            }
        }));
        var options = __assign(__assign({}, defaultOptions), { i18n: i18n, readOnly: readOnly, viewAsHtml: readOnly, language: locale, i18nReady: true, i18next: i18nextInstance });
        changeFormLanguage(locale);
        new window.Formio.Form(formRef.current, form, options).render().then(function (instance) {
            setInstance(instance);
            if (submission) {
                instance.setSubmission(submission);
            }
            if (form && form.components) {
                var formApi = new FormioFormApi(instance, readOnly);
                onFormReady && onFormReady(formApi);
            }
            onFieldFocus && instance.on('focus', onFieldFocus);
            onFieldBlur && instance.on('blur', onFieldBlur);
            if (setFormIsChanged || onFormChange) {
                instance.on('change', function (event) {
                    var _a, _b, _c;
                    if (setFormIsChanged) {
                        if (((_b = (_a = event.changed) === null || _a === void 0 ? void 0 : _a.flags) === null || _b === void 0 ? void 0 : _b.modified) || ((_c = event.changed) === null || _c === void 0 ? void 0 : _c.component.type) === 'datetime') {
                            setFormIsChanged(true);
                        }
                    }
                    onFormChange && onFormChange(event);
                });
            }
        });
    }, [form, submission, readOnly]);
    useEffect(function () { return onDestroy; }, []);
    var onDestroy = function () {
        if (instance) {
            instance.destroy();
        }
        setFormIsChanged && setFormIsChanged(false);
    };
    var changeFormLanguage = function (lang) {
        i18nextInstance === null || i18nextInstance === void 0 ? void 0 : i18nextInstance.changeLanguage(lang, function (err) {
            if (err) {
                console.error("can't change form language", err);
            }
            setInstance(function (instance) {
                return instance && __assign(__assign({}, instance), { language: lang });
            });
        });
    };
    var formClasses = cn(className, {
        'read-only': readOnly,
    });
    return (React.createElement(FormWithTheme, null,
        React.createElement("div", { ref: formRef, className: formClasses })));
});
