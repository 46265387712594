import { Box, useTheme } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useStore } from '../../../../../../hooks';
import { CampaignRequestFormListMember } from '../../../../../../types';
import {
    RequestFormCardInfo as RequestFormCardInfoInj,
    RequestFormCardTitleWithButton as RequestFormCardTitleWithButtonInj,
} from '../../../../components';

export type CampaignCardDescriptionRequestFormProps = {
    requestForm: CampaignRequestFormListMember;
    isLast: boolean;
};

export const CampaignCardDescriptionRequestForm = (props: CampaignCardDescriptionRequestFormProps): JSX.Element => {
    const [RequestFormCardInfo] = di([RequestFormCardInfoInj], CampaignCardDescriptionRequestForm);
    const [RequestFormCardTitleWithButton] = di(
        [RequestFormCardTitleWithButtonInj],
        CampaignCardDescriptionRequestForm,
    );

    const { requestForm, isLast } = props;
    const { title } = requestForm;

    const { campaignsStore } = useStore();
    const theme = useTheme();
    const intl = useIntl();

    const onClickButton = (): void => {
        createRequest(requestForm.id);
    };

    const createRequest = (formId: string): void => {
        campaignsStore.createRequest(formId);
    };

    const containerCardProps = {
        pb: 2.25,
        pt: 2,
        borderBottom: isLast ? 'none' : `1px solid ${theme.palette.grey[200]}`,
    };
    return (
        <Box pb={2}>
            <RequestFormCardTitleWithButton
                title={title}
                buttonTitle={intl.formatMessage({ id: 'campaign.requestForm.createRequest' })}
                onClickButton={onClickButton}
            />
            <Box {...containerCardProps}>
                <RequestFormCardInfo
                    requestForm={requestForm}
                    onDownloadFile={campaignsStore.downloadRequestFormFile}
                />
            </Box>
        </Box>
    );
};
