import { FullScreenDialog } from '@platform/front-ui';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useHistory, useLocation } from 'react-router-dom';
import {
    useCampaignSettingsListContext,
    useCampaignSettingsSectionSettingContext,
    useStore,
} from '../../../../../../../hooks';
import { RequestFormSectionSettingFormValues, SectionSettingDTO } from '../../../../../../../types';
import { CampaignSettingsSectionSettingDialogForm as CampaignSettingsSectionDialogFormInj } from './components';

export const lastRouteRegexp = /(\/[^\/]*)$/g;

/** Попап с формой создания раздела */
export const CampaignSettingsSectionSettingDialog = observer((): JSX.Element => {
    const [CampaignSettingsSectionDialogForm] = di(
        [CampaignSettingsSectionDialogFormInj],
        CampaignSettingsSectionSettingDialog,
    );
    const { rfId, sectionSettingId, isCreatePage, sectionSettingModel } = useCampaignSettingsSectionSettingContext();
    const { setShouldLoadListItems } = useCampaignSettingsListContext();

    const rootStore = useStore();
    const { coreRootStore, campaignsStore } = rootStore;
    const { visibleHeaderHeight } = coreRootStore.headerHeightStore;

    const {
        isSectionSettingDTOLoaded,
        setInitialSectionSettingFormValues,
        sectionSettingFormInitialValues,
        getSectionSettingFormValidationSchema,
    } = sectionSettingModel;

    const sectionFormValidationSchema = getSectionSettingFormValidationSchema();

    useEffect(() => {
        if (isSectionSettingDTOLoaded) {
            setInitialSectionSettingFormValues();
        }
    }, [isSectionSettingDTOLoaded]);

    const history = useHistory();
    const location = useLocation();

    const onCancel = (): void => {
        const { pathname } = location;
        const newPath = pathname.replace(lastRouteRegexp, '');
        history.push(newPath);
    };

    const onSubmit = (values: RequestFormSectionSettingFormValues): void => {
        const { form, executor, fileSize, parentSectionSetting, commentAttributes, showComments, ...restValues } =
            values;
        const { textEditorSettings, maxCommentLength } = commentAttributes;

        const updatedTextEditorSettings: string[] | undefined =
            textEditorSettings?.length && showComments ? textEditorSettings : undefined;

        const updatedMaxCommentLength: number | undefined =
            !showComments || Number.isNaN(maxCommentLength) || (maxCommentLength as number) < 1
                ? undefined
                : Number(maxCommentLength);

        const dto: SectionSettingDTO = Object.assign(restValues, {
            showComments,
            formCode: form?.code || '',
            formTitle: form?.title || '',
            fileSize: Number(fileSize),
            executorId: executor?.id,
            parentSectionSettingId: parentSectionSetting?.id,
            commentAttributes: {
                ...commentAttributes,
                textEditorSettings: updatedTextEditorSettings,
                maxCommentLength: updatedMaxCommentLength,
            },
        });
        const thenHandler = (): void => {
            setShouldLoadListItems();
            onCancel();
        };
        isCreatePage
            ? campaignsStore.createSectionSetting(rfId, dto).then(thenHandler)
            : campaignsStore.updateSectionSetting(sectionSettingId, dto).then(thenHandler);
    };

    const intl = useIntl();
    const dialogTitle = intl.formatMessage({
        id: isCreatePage ? 'campaignSettings.creatingSection' : 'campaignSettings.editingSection',
    });

    return (
        <FullScreenDialog open={true} headerHeight={visibleHeaderHeight} title={dialogTitle} onCancel={onCancel}>
            <Formik
                initialValues={sectionSettingFormInitialValues}
                validationSchema={sectionFormValidationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                <CampaignSettingsSectionDialogForm sectionSettingModel={sectionSettingModel} onCancel={onCancel} />
            </Formik>
        </FullScreenDialog>
    );
});
