var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
import { sassVars } from '../../constants/sassVars';
export default function () {
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        font-size: ", ";\n        padding: ", " ", ";\n    "], ["\n        font-size: ", ";\n        padding: ", " ", ";\n    "])), sassVars.btnFontSizeXs, sassVars.btnPaddingVerticalXs, sassVars.btnPaddingHorizontalXs).join('');
}
var templateObject_1;
