import { FormControlLabel, FormLabel, Grid, Radio, SxProps, Theme } from '@mui/material';
import {
    AutocompleteField,
    DropzoneAreaField,
    FieldWithServerError,
    FullScreenForm,
    FullScreenFormProps,
    inputWithPlaceholderProps,
} from '@platform/front-core';
import { RequiredLabel } from '@platform/front-ui';
import { VoidFunction } from '@platform/front-utils';
import { Field, useFormikContext } from 'formik';
import { CheckboxWithLabel, RadioGroup } from 'formik-mui';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../../../../../../../hooks';
import { CampaignPfTemplateModel } from '../../../../../../../../models';
import { TemplateFormFields, TemplateFormValues } from '../../../../../../../../types';
import { DigitalSignaturePfFormFields } from './DigitalSignaturePfFormFields';
import { campaignSettingsSectionSxStyles } from './campaignSettingsSectionSxStyles';

export type CampaignSettingsSectionSettingPfEditFormProps = {
    model: CampaignPfTemplateModel;
    onCancel: VoidFunction;
    submitBtnAdditionalProps?: FullScreenFormProps['submitBtnAdditionalProps'];
};

const formLabelSx: SxProps<Theme> = {
    marginBottom: '4px',
};

const radioSx: SxProps<Theme> = { marginRight: '4px' };

export const CampaignSettingsSectionSettingPfEditForm = observer(
    (props: CampaignSettingsSectionSettingPfEditFormProps): JSX.Element => {
        const { model, onCancel, submitBtnAdditionalProps } = props;

        const { downloadTemplateFile } = useStore().campaignsStore;
        const { getFieldLabel, getFieldPlaceholder, docCategories, fileFormats } = model;

        const titleLabel = <RequiredLabel text={getFieldLabel(TemplateFormFields.title)} />;
        const requiredLabel = { label: getFieldLabel(TemplateFormFields.required) };

        const { initialValues } = useFormikContext<TemplateFormValues>();
        const initialFiles = toJS(initialValues[TemplateFormFields.file]);

        const fileFormatComponents: JSX.Element[] = fileFormats.map((fileFormat): JSX.Element => {
            const { id, title } = fileFormat;
            const label = title.toLocaleUpperCase();
            const control = <Radio sx={radioSx} />;
            return <FormControlLabel key={id} value={id} label={label} control={control} />;
        });

        return (
            <FullScreenForm onCancel={onCancel} submitBtnAdditionalProps={submitBtnAdditionalProps}>
                <Grid container flexDirection="column" spacing={2}>
                    <Grid item>
                        <FieldWithServerError
                            name={TemplateFormFields.title}
                            label={titleLabel}
                            placeholder={getFieldPlaceholder(TemplateFormFields.title)}
                            {...inputWithPlaceholderProps}
                        />
                    </Grid>
                    <Grid item>
                        <FieldWithServerError
                            name={TemplateFormFields.description}
                            label={getFieldLabel(TemplateFormFields.description)}
                            placeholder={getFieldPlaceholder(TemplateFormFields.description)}
                            {...inputWithPlaceholderProps}
                        />
                    </Grid>
                    <Grid item>
                        <AutocompleteField
                            fieldName={TemplateFormFields.docCategory}
                            options={docCategories}
                            label={getFieldLabel(TemplateFormFields.docCategory)}
                            placeholder={getFieldPlaceholder(TemplateFormFields.docCategory)}
                        />
                    </Grid>
                    <Grid item>
                        <FormLabel sx={campaignSettingsSectionSxStyles.formLabel}>
                            {getFieldLabel(TemplateFormFields.fileFormat)}
                        </FormLabel>
                        <Field component={RadioGroup} name={TemplateFormFields.fileFormat} row spacing={1}>
                            {fileFormatComponents}
                        </Field>
                    </Grid>
                    <Grid container item direction="column">
                        <Grid item>
                            <FieldWithServerError
                                name={TemplateFormFields.required}
                                component={CheckboxWithLabel}
                                type="checkbox"
                                Label={requiredLabel}
                                isWithoutHelperTextProp
                            />
                        </Grid>
                        <DigitalSignaturePfFormFields model={model} />
                    </Grid>
                    <Grid item>
                        <FormLabel sx={formLabelSx}>
                            <RequiredLabel text={getFieldLabel(TemplateFormFields.file)} />
                        </FormLabel>
                        <DropzoneAreaField
                            fieldName={TemplateFormFields.file}
                            acceptedFiles={model.acceptedFiles}
                            initialFiles={initialFiles}
                            downloadServerFile={downloadTemplateFile}
                        />
                    </Grid>
                </Grid>
            </FullScreenForm>
        );
    },
);
