import React, { useState } from 'react';
import { Grid, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { CampaignRequestInfoCommon as CampaignRequestInfoCommonInj } from './campaign-request-info-common';
import { CampaignRequestTabs } from '../../../../../types';
import { CampaignRequestInfoService as CampaignRequestInfoServiceInj } from './campgain-request-info-service';

export const StyledTabPanelInj = styled(TabPanel)`
    margin-left: -24px;
`;

export const CampaignRequestInfo = (): JSX.Element => {
    const [CampaignRequestInfoCommon] = di([CampaignRequestInfoCommonInj], CampaignRequestInfo);
    const [CampaignRequestInfoService] = di([CampaignRequestInfoServiceInj], CampaignRequestInfo);
    const [StyledTabPanel] = di([StyledTabPanelInj], CampaignRequestInfo);

    const { formatMessage } = useIntl();
    const [selectedTab, setSelectedTab] = useState<CampaignRequestTabs>(CampaignRequestTabs.common);

    const handleTabChange = (event: React.SyntheticEvent, newTab: CampaignRequestTabs): void => {
        setSelectedTab(newTab);
    };

    const commonLabel = formatMessage({ id: 'campaignRequest.descriptionPanel.commonTabLabel' });
    const serviceLabel = formatMessage({ id: 'campaignRequest.descriptionPanel.serviceTabLabel' });

    return (
        <Grid item>
            <TabContext value={selectedTab}>
                <TabList onChange={handleTabChange}>
                    <Tab label={commonLabel} value={CampaignRequestTabs.common} />
                    <Tab label={serviceLabel} value={CampaignRequestTabs.service} />
                </TabList>
                <Grid item>
                    <StyledTabPanel value={CampaignRequestTabs.common}>
                        <CampaignRequestInfoCommon />
                    </StyledTabPanel>
                    <StyledTabPanel value={CampaignRequestTabs.service}>
                        <CampaignRequestInfoService />
                    </StyledTabPanel>
                </Grid>
            </TabContext>
        </Grid>
    );
};
