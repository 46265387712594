import { TreeView } from '@mui/lab';
import { SidebarItem } from '@platform/formiojs-react';
import { SkeletonTreeList } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, RefObject, useEffect, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { FormSidebarModel, RequestFormSectionModel } from '../../../../../models';
import { CampaignRequestFormSidebarItems as CampaignRequestFormSidebarItemsInj } from './CampaignRequestFormSidebarItems';

export type CampaignRequestFormSidebarListProps = {
    section: RequestFormSectionModel;
    startSidebarItems: SidebarItem[];
    formWrapperRef: RefObject<HTMLDivElement>;
    sidebarWrapperRef: RefObject<HTMLDivElement>;
};

const treeSx: CSSProperties = {
    width: '100%',
};

export const CampaignRequestFormSidebarList = observer((props: CampaignRequestFormSidebarListProps): JSX.Element => {
    const [CampaignRequestFormSidebarItems] = di([CampaignRequestFormSidebarItemsInj], CampaignRequestFormSidebarList);

    const { formWrapperRef, sidebarWrapperRef, section, startSidebarItems } = props;
    const { isFormLoading } = section;
    const model = useMemo(
        () => new FormSidebarModel(formWrapperRef, sidebarWrapperRef),
        [formWrapperRef.current, sidebarWrapperRef.current],
    );
    const {
        sidebarItems,
        expandedItems,
        selectedItems,
        itemAnchorPrefix,
        setSidebarItems,
        handleScroll,
        expandItem,
        decreaseItem,
        onNodeSelect,
    } = model;

    useEffect(() => {
        if (!isFormLoading) {
            setSidebarItems(startSidebarItems);

            setTimeout(handleScroll, 0);
            formWrapperRef.current?.addEventListener('scroll', handleScroll);

            return () => formWrapperRef.current?.removeEventListener('scroll', handleScroll);
        }
    }, [startSidebarItems, isFormLoading]);

    return (
        <TreeView
            sx={treeSx}
            selected={selectedItems}
            expanded={expandedItems}
            onNodeSelect={onNodeSelect}
            multiSelect={true}
        >
            {isFormLoading ? (
                <SkeletonTreeList rowsCount={5} />
            ) : (
                <CampaignRequestFormSidebarItems
                    itemAnchorPrefix={itemAnchorPrefix}
                    sidebarItems={sidebarItems}
                    expandItem={expandItem}
                    decreaseItem={decreaseItem}
                    isFirstStep={true}
                />
            )}
        </TreeView>
    );
});
