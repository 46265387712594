import {
    AccessPermissionButton,
    AccessPermissionRequests,
    AccessPermissionRoutes,
    AccessPermissionAdditionalFormFields,
} from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../../clientRoute';
import { useStore } from '../../../../../../../hooks';
import { CampaignSettingsRequestAccessPermissionModel } from '../../../../../../../models';
import { CampaignRequestFormRouteParams } from '../../../../../../../types';

const emptyAdditionalFormFields: AccessPermissionAdditionalFormFields = {
    renderFields: () => <React.Fragment />,
    getValidationSchema: () => ({}),
    initialValues: {},
};

export const CampaignSettingsAccessRequestPermissionButton = observer((): JSX.Element => {
    const { campaignsStore } = useStore();
    const { formatMessage } = useIntl();
    const params = useParams<CampaignRequestFormRouteParams>();
    const { rfId } = params;

    const accessPermissionRequests: AccessPermissionRequests = useMemo(
        () => new CampaignSettingsRequestAccessPermissionModel(rfId, campaignsStore),
        [campaignsStore, rfId],
    );

    const title = formatMessage({
        id: 'campaignSettings.requestAccessSetting.accessSetting',
    });

    const settingsButton = { title };

    const emptyListTitle = formatMessage({
        id: 'campaignSettings.requestAccessSetting.emptyPermissionList',
    });

    const routes: AccessPermissionRoutes = {
        closePath: generatePath(clientRoute.campaignSettingsRequestFormInfo, params),
        listPath: generatePath(clientRoute.campaignSettingsRequestFormRequestPermissions, params),
        addPath: generatePath(clientRoute.campaignSettingsRequestFormAddRequestPermission, params),
    };

    return (
        <AccessPermissionButton
            accessPermissionRequests={accessPermissionRequests}
            emptyListTitle={emptyListTitle}
            routes={routes}
            settingsButton={settingsButton}
            additionalFormFields={emptyAdditionalFormFields}
        />
    );
});
