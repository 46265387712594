import { TreeView } from '@mui/lab';
import { styled, SxProps, Theme, Grid } from '@mui/material';
import { grey, TreeItem } from '@platform/front-ui';
import { RouteParamsDefault } from '@platform/front-utils';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { useCampaignSettingsPageContext } from '../../../../hooks';

const settingsList = [
    {
        title: <FormattedMessage id="campaignSettings.campaignDescription" />,
        path: clientRoute.campaignSettingsDescription,
    },
    {
        title: <FormattedMessage id="campaignSettings.campaignRequestForms" />,
        path: clientRoute.campaignSettingsRequestForms,
        canDisabled: true,
    },
];

const StyledTreeItem = styled(TreeItem)({
    minWidth: '216px',
});

const settingsListSx: SxProps<Theme> = {
    pt: 1,
    px: 2,
    borderRight: `1px solid ${grey[300]}`,
    fontSize: '0.75rem',
};

export const CampaignSettingsBodyList = (): JSX.Element => {
    const { id } = useParams<RouteParamsDefault>();
    const { isCampaignCreatePage } = useCampaignSettingsPageContext();

    const location = useLocation();
    const { pathname } = location;

    const settings = useMemo(() => {
        return settingsList.map((setting) => {
            return { ...setting, path: generatePath(setting.path, { id }) };
        });
    }, [id]);

    const selected = useMemo((): string[] => {
        return settings
            .filter((setting) => {
                return pathname.startsWith(setting.path);
            })
            .map((setting) => setting.path);
    }, [settings, pathname]);

    const history = useHistory();

    const onNodeSelect = (event: React.SyntheticEvent, nodeId: string[] | string): void => {
        if (nodeId && typeof nodeId === 'string') {
            history.push(generatePath(nodeId, { id }));
        }
    };

    return (
        <Grid item sx={settingsListSx}>
            <TreeView selected={selected} onNodeSelect={onNodeSelect}>
                {settings.map((setting) =>
                    isCampaignCreatePage && setting.canDisabled ? null : (
                        <StyledTreeItem key={setting.path} nodeId={setting.path} label={setting.title} />
                    ),
                )}
            </TreeView>
        </Grid>
    );
};
