import { ContentErrorsModel, ErrorEntityType } from '@platform/front-core';
import { generatePath } from 'react-router-dom';
import { clientRoute } from '../../../../clientRoute';
import { CampaignRequestRouteParams } from '../../../../types';
import { printFormContainerAnchor } from './content';

export const getCampaignRequestSectionPath = (
    pathParams: CampaignRequestRouteParams,
    errors?: ContentErrorsModel,
): string => {
    let sectionPath = generatePath(clientRoute.requestSection, pathParams);

    if (errors) {
        if (errors.isWithError(ErrorEntityType.submission)) {
            sectionPath = generatePath(clientRoute.requestSectionFormEdit, pathParams);
        } else if (errors.isWithError(ErrorEntityType.printForm)) {
            sectionPath = generatePath(`${clientRoute.requestSection}${printFormContainerAnchor}`, pathParams);
        }
    }

    return sectionPath;
};
