import { Grid, SxProps, Theme } from '@mui/material';
import { Form, FormApi, FormDTO } from '@platform/formiojs-react';
import { useCoreStore } from '@platform/front-core';
import { RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { di } from 'react-magnetic-di';
import { useParams, useRouteMatch } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { emptyForm } from '../../../../../constants';
import { useStore } from '../../../../../hooks';
import { CampaignSettingsDescriptionActionsPanel as CampaignSettingsDescriptionActionsPanelInj } from './CampaignSettingsDescriptionActionsPanel';
import { CampaignSettingsDescriptionHeader as CampaignSettingsDescriptionHeaderInj } from './CampaignSettingsDescriptionHeader';

const wrapperSx: SxProps<Theme> = {
    height: '100%',
    overflow: 'hidden',
};

const descriptionFormWrapper: SxProps<Theme> = {
    paddingX: 2,
    overflowY: 'auto',
    overflowX: 'hidden',
};

export const CampaignSettingsDescription = observer((): JSX.Element => {
    const [CampaignSettingsDescriptionActionsPanel] = di(
        [CampaignSettingsDescriptionActionsPanelInj],
        CampaignSettingsDescription,
    );
    const [CampaignSettingsDescriptionHeader] = di([CampaignSettingsDescriptionHeaderInj], CampaignSettingsDescription);

    const { campaignsStore } = useStore();
    const { intlStore } = useCoreStore();
    const { loadCampaignForm } = campaignsStore;
    const { id } = useParams<RouteParamsDefault>();

    const [formDTO, setFormDTO] = useState<FormDTO>(emptyForm);
    const [formApi, setFormApi] = useState<FormApi>();
    const [formIsChanged, setFormIsChanged] = useState<boolean>(false);

    const isFormView = !useRouteMatch([clientRoute.campaignSettingsEdit, clientRoute.createCampaign]);

    const reloadForm = useCallback((): Promise<void> => {
        return loadCampaignForm(id).then(setFormDTO);
    }, [loadCampaignForm, id]);

    useEffect(() => {
        reloadForm();
    }, [reloadForm]);

    return (
        <Grid container direction="column" justifyContent="space-between" wrap="nowrap" sx={wrapperSx}>
            <Grid item>
                <CampaignSettingsDescriptionHeader isFormView={isFormView} />
            </Grid>
            <Grid item flexGrow={1} sx={descriptionFormWrapper}>
                <Form
                    formDTO={formDTO}
                    intlStore={intlStore}
                    ownerEntityId={id}
                    readOnly={isFormView}
                    onFormReady={setFormApi}
                    setFormIsChanged={setFormIsChanged}
                />
            </Grid>
            {!isFormView && <CampaignSettingsDescriptionActionsPanel formApi={formApi} reloadForm={reloadForm} />}
        </Grid>
    );
});
