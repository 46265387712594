import { Divider, Grid } from '@mui/material';
import { AutocompleteField } from '@platform/front-core';
import {
    DialogActions,
    DialogCancelButtonConfig,
    DialogSubmitButtonConfig,
    FullScreenDialogContent,
} from '@platform/front-ui';
import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { useCampaignSettingsAccessPermissionContext } from '../../../../../../hooks';
import { CodeTitleGroupRC } from '../../../../../../types';
import { CampaignSettingsEditPermissionFormikFormStyled as FormStyled } from './CampaignSettingsEditPermissionForm.styled';

export const CampaignSettingsEditPermissionForm = observer((): JSX.Element => {
    const intl = useIntl();
    const { model, checkboxLabel, goBack } = useCampaignSettingsAccessPermissionContext();
    const { roleConditionList, lifecycleStatesList } = model;

    const createCheckboxLabel = {
        label: checkboxLabel,
    };

    const roleConditionGroupBy = (option: CodeTitleGroupRC): string => {
        return intl.formatMessage({
            id: `campaignSettings.editPermissions.group.${option.group}`,
        });
    };

    const cancelButton: DialogCancelButtonConfig = {
        onClick: goBack,
    };

    const submitButton: DialogSubmitButtonConfig = {
        type: 'submit',
        text: intl.formatMessage({ id: 'common.saveChanges' }),
        color: 'secondary',
    };

    return (
        <FormStyled>
            <FullScreenDialogContent>
                <Grid container flexDirection="column" spacing={2}>
                    <Grid item>
                        <AutocompleteField
                            fieldName="roleCondition"
                            options={roleConditionList}
                            disableClearable={true}
                            required
                            label={intl.formatMessage({ id: 'campaignSettings.editPermissions.list.role' })}
                            placeholder={intl.formatMessage({
                                id: 'campaignSettings.sectionForm.form.placeholder',
                            })}
                            groupBy={roleConditionGroupBy}
                        />
                    </Grid>
                    <Grid item>
                        <AutocompleteField
                            fieldName="whenObjectStateIsOneOf"
                            multiple
                            options={lifecycleStatesList}
                            disableClearable={true}
                            label={intl.formatMessage({ id: 'campaignSettings.editPermissions.lifecycleStage' })}
                            placeholder={intl.formatMessage({
                                id: 'campaignSettings.editPermissions.list.allStatuses',
                            })}
                        />
                    </Grid>
                    <Grid item>
                        <Field
                            name="allowEdit"
                            type="checkbox"
                            component={CheckboxWithLabel}
                            Label={createCheckboxLabel}
                        />
                    </Grid>
                </Grid>
            </FullScreenDialogContent>
            <Divider />
            <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
        </FormStyled>
    );
});
