import { FullSubmission } from '@platform/formiojs-react';
import { ApiStore, TransitionDTO, UserPersonDTO } from '@platform/front-core';
import { AsyncCheckActionType, AsyncCheckStore } from '@platform/front-utils';
import { History } from 'history';
import { action, computed, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { CampaignRequestServiceInfoDTO, DeadlinesDTO, RequestDTO, RequestSectionDTO } from '../types';
import { RootStore } from './RootStore';

export const requestStoreObservables = {
    rootStore: observable,
    api: observable,
    showValidation: observable,
    asyncCheckStore: observable,

    history: computed,
    loadRequestDTO: action.bound,
    getDeadlinesDTO: action.bound,
    createRequest: action.bound,
    deleteRequest: action.bound,
    getTransitions: action.bound,
    transitionToNextLifeCycleStep: action.bound,
    getEmployeesList: action.bound,
    getServiceInfo: action.bound,
    setCurrentUserAsExecutor: action.bound,
    getExecutorSettings: action.bound,
    editExecutorSettings: action.bound,
    checkEditablePf: action.bound,
    loadRequestSection: action.bound,
    checkIsSectionEditable: action.bound,
    saveRequestSectionForm: action.bound,
};

export class RequestStore {
    protected rootStore: RootStore;
    protected api: ApiStore;
    showValidation = false;
    asyncCheckStore: AsyncCheckStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.coreRootStore.api;
        this.asyncCheckStore = rootStore.coreRootStore.asyncCheckStore;
        makeObservable(this, requestStoreObservables);
    }

    get history(): History {
        return this.rootStore.history;
    }

    loadRequestDTO(requestId: string): Promise<RequestDTO> {
        return this.api.mainInfoClient(apiConfigs.getRequest(requestId)).then((r) => r.data);
    }

    getDeadlinesDTO(requestId: string): Promise<DeadlinesDTO> {
        return this.api.client(apiConfigs.getDeadlines(requestId)).then((r) => r.data);
    }

    createRequest(regFormId: string): Promise<string> {
        return this.api.userActionClient(apiConfigs.createRequest(regFormId)).then((r) => r.data);
    }

    deleteRequest(id: string, withAsyncCheck = false): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.deleteRequest(id))
            .then(this.asyncCheckStore.asyncCheck(AsyncCheckActionType.registry, withAsyncCheck))
            .then((r) => r.data);
    }

    getTransitions(requestId: string): Promise<TransitionDTO[]> {
        return this.api.client(apiConfigs.transitionRequest(requestId)).then((r) => r.data);
    }

    transitionToNextLifeCycleStep(transitionId: string, requestId: string): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.transitionToNextLifeCycleStep(transitionId, requestId))
            .then((r) => r.data);
    }

    getEmployeesList(): Promise<UserPersonDTO[]> {
        return this.api.client(apiConfigs.getAllActiveEmployees()).then((r) => r.data);
    }

    getServiceInfo(requestId: string): Promise<CampaignRequestServiceInfoDTO> {
        return this.api.client(apiConfigs.campaignRequestServiceInfo(requestId)).then((r) => r.data);
    }

    setCurrentUserAsExecutor(requestId: string): Promise<void> {
        return this.api.userActionClient(apiConfigs.setCurrentUserAsExecutor(requestId)).then((r) => r.data);
    }

    getExecutorSettings(requestId: string): Promise<UserPersonDTO> {
        return this.api.client(apiConfigs.getExecutorSettings(requestId)).then((r) => r.data);
    }

    editExecutorSettings(requestId: string, userId: string): Promise<void> {
        return this.api.userActionClient(apiConfigs.editExecutorSettings(requestId, userId)).then((r) => r.data);
    }

    checkEditablePf(customFormId: string): Promise<void> {
        return this.api.client(apiConfigs.checkEditablePf(customFormId)).then((r) => r.data);
    }

    loadRequestSection(formId: string): Promise<RequestSectionDTO> {
        return this.api
            .mainInfoClient(apiConfigs.loadSection(formId))
            .then((r) => r.data)
            .catch((error) => {
                return this.api.userActionClientCatchHandler(error);
            });
    }

    checkIsSectionEditable(sectionId: string): Promise<void> {
        return this.api.client(apiConfigs.sectionCheckEditable(sectionId)).then((r) => r.data);
    }

    saveRequestSectionForm(sectionId: string, submission: FullSubmission): Promise<void> {
        return this.api.userActionClient(apiConfigs.saveSection(sectionId, submission)).then((r) => r.data);
    }
}

export const getRequestStore = (): any => {
    const [_RequestStore] = di([RequestStore], getRequestStore);
    return _RequestStore;
};
