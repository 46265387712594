import { Grid, SxProps, Theme, Typography } from '@mui/material';
import { FileDTO } from '@platform/front-core';
import { grey, LinkButton } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useStore } from '../../../../../../hooks';
import { FileIcon } from '../../../../../../resources';

export type CampaignCardDescriptionCommonInfoFileCardProps = {
    file: FileDTO;
};

const fileNameSx: SxProps<Theme> = {
    paddingTop: '4px',
    paddingBottom: '8px',
    '& > [class*="MuiTypography"]': {
        maxWidth: '300px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

const fileSizeSx: SxProps<Theme> = {
    paddingLeft: '2px',
    fontFamily: 'Roboto',
    color: grey[400],
};

const iconSx: SxProps<Theme> = {
    color: grey[300],
    paddingRight: '6px',
};

export const CampaignCardDescriptionCommonInfoFileCard = observer(
    (props: CampaignCardDescriptionCommonInfoFileCardProps): JSX.Element => {
        const { file } = props;
        const { filename, size } = file;

        const { campaignsStore } = useStore();

        const handleDownload = (): void => {
            campaignsStore.downloadFile(file);
        };

        return (
            <Grid container alignItems="center">
                <Grid item sx={iconSx}>
                    <FileIcon />
                </Grid>
                <Grid item>
                    <LinkButton color="secondary" onClick={handleDownload} sx={fileNameSx}>
                        {filename}
                    </LinkButton>
                </Grid>
                <Grid item>
                    <Typography variant="body1" sx={fileSizeSx}>
                        {size} <FormattedMessage id="common.megabytes" />
                    </Typography>
                </Grid>
            </Grid>
        );
    },
);
