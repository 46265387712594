import { EmailOutlined, NotInterested, Update } from '@mui/icons-material';
import { Box, SxProps, Theme } from '@mui/material';
import {
    AppContentContainer as AppContentContainerInj,
    Authentication as AuthenticationInj,
    AuthPage as AuthPageInj,
    ConfirmingEmailNotFoundPageButtons as ConfirmingEmailNotFoundPageButtonsInj,
    ConsolePage,
    coreClientRoute,
    ErrorCode,
    Footer as FooterInj,
    Header as HeaderInj,
    NewPasswordPage as NewPasswordPageInj,
    NotificationComponent as NotificationComponentInj,
    NotificationPage as NotificationPageInj,
    RedirectByRole,
    RegistrationConfirmPage as RegistrationConfirmPageInj,
    useCoreStore,
    UserPage as UserPageInj,
    UserRegistryPage as UserRegistryPageInj,
} from '@platform/front-core';
import { useFeature } from 'feature-toggle-jsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { Redirect, Route, Switch } from 'react-router-dom';
import { clientRoute } from './clientRoute';
import { CampaignPages as CampaignPagesInj, CampaignRequestPages as CampaignRequestPagesInj } from './pages';
import icsLogo from './resources/images/ics-start-logo.svg';

export const additionalPagesInj = (): JSX.Element[] => [];

const wrapperSx: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'nowrap',
};

export const App = observer((): JSX.Element => {
    const [additionalPages] = di([additionalPagesInj], App); // todo: нужно явно указать Route path
    const [AuthPage] = di([AuthPageInj], App);
    const [UserPage] = di([UserPageInj], App);
    const [Authentication] = di([AuthenticationInj], App);
    const [ConfirmingEmailNotFoundPageButtons] = di([ConfirmingEmailNotFoundPageButtonsInj], App);
    const [AppContentContainer] = di([AppContentContainerInj], App);
    const [Header] = di([HeaderInj], App); // todo: удалить все Inj из библиотек, это не работает
    const [Footer] = di([FooterInj], App);
    const [NotificationComponent] = di([NotificationComponentInj], App);
    const [CampaignPages] = di([CampaignPagesInj], App);
    const [UserRegistryPage] = di([UserRegistryPageInj], App);
    const [CampaignRequestPages] = di([CampaignRequestPagesInj], App);
    const [NotificationPage] = di([NotificationPageInj], App);
    const [NewPasswordPage] = di([NewPasswordPageInj], App);
    const [RegistrationConfirmPage] = di([RegistrationConfirmPageInj], App);

    const additionalPagesArray = additionalPages();
    const { personStore } = useCoreStore();

    const intl = useIntl();

    // хук для задания положения скролла относительно верхней границы rootElement
    // это значение участвует в расчете поля visibleHeaderHeight, которое нужно для определения видимости шапки в настоящий момент
    // useSetRootScrollTop();

    const notFoundErrorMessage = intl.formatMessage({ id: 'notFoundPage.notExist' });
    const notAllowedErrorMessage = intl.formatMessage({ id: 'common.actionNotAllowed' });
    const loginEditingNotificationMessage = intl.formatMessage({ id: 'authentication.loginEditingRequest' });
    const registrationInfoExpiredMessage = intl.formatMessage({ id: 'authentication.confirmingEmailExpired' });
    const registrationConfirmationMessage = intl.formatMessage({ id: 'authentication.registrationConfirmation' });
    const recoveryLinkSentMessage = intl.formatMessage({ id: 'authentication.recoveryLinkSent' });
    const confirmingEmailNotFoundInfo = intl.formatMessage({ id: 'authentication.confirmingEmailNotFound' });

    const emailOutlinedIcon = <EmailOutlined />;
    const updateIcon = <Update />;
    const notInterestedIcon = <NotInterested />;

    const ConfirmingEmailNotFoundPageBtn = <ConfirmingEmailNotFoundPageButtons />;

    const [isWithRegistrationPage] = useFeature('isWithRegistrationPage');

    return (
        <Box sx={wrapperSx}>
            <Switch>
                <Route path={coreClientRoute.authPrefix}>
                    <AuthPage logo={icsLogo} isWithRegistrationPage={isWithRegistrationPage} />
                </Route>
                <Route path={coreClientRoute.newPassword} exact>
                    <NewPasswordPage logo={icsLogo} />
                </Route>
                <Route>
                    <Header />
                    <AppContentContainer>
                        <Switch>
                            <Route
                                path={[
                                    clientRoute.requestLog,
                                    clientRoute.campaigns,
                                    clientRoute.requests,
                                    clientRoute.requestsNew,
                                    clientRoute.requestSyslog,
                                    clientRoute.users,
                                    coreClientRoute.console,
                                    coreClientRoute.notFound,
                                    coreClientRoute.notAllowed,
                                ]}
                            >
                                <Authentication>
                                    <Switch>
                                        {additionalPagesArray}
                                        <Route path={clientRoute.requestLog}>
                                            <Redirect to={clientRoute.requestSyslog} />
                                        </Route>

                                        <Route path={clientRoute.campaigns}>
                                            <CampaignPages />
                                        </Route>

                                        <Route path={clientRoute.requests}>
                                            <CampaignRequestPages />
                                        </Route>

                                        <Route path={clientRoute.requestsNew}>
                                            <Redirect to={clientRoute.requests} />
                                        </Route>
                                        <Route path={coreClientRoute.console}>
                                            <ConsolePage />
                                        </Route>
                                        <Route path={clientRoute.users} exact>
                                            <UserRegistryPage />
                                        </Route>
                                        <Route path={clientRoute.user}>
                                            <UserPage />
                                        </Route>

                                        <Route path={coreClientRoute.notFound} exact>
                                            <NotificationPage
                                                errorCode={ErrorCode.notFound}
                                                notificationText={notFoundErrorMessage}
                                                withActionButtons={true}
                                            />
                                        </Route>
                                        <Route path={coreClientRoute.notAllowed} exact>
                                            <NotificationPage
                                                errorCode={ErrorCode.notAllowed}
                                                notificationText={notAllowedErrorMessage}
                                                withActionButtons={true}
                                            />
                                        </Route>
                                    </Switch>
                                </Authentication>
                            </Route>

                            <Route path={coreClientRoute.registrationConfirm} exact>
                                <RegistrationConfirmPage />
                            </Route>
                            <Route path={coreClientRoute.registrationInfoSuccess}>
                                <NotificationPage
                                    icon={emailOutlinedIcon}
                                    notificationText={registrationConfirmationMessage}
                                />
                            </Route>
                            <Route path={coreClientRoute.registrationInfoExpired}>
                                <NotificationPage icon={updateIcon} notificationText={registrationInfoExpiredMessage} />
                            </Route>
                            <Route path={coreClientRoute.emailNotification}>
                                <NotificationPage
                                    icon={emailOutlinedIcon}
                                    notificationText={loginEditingNotificationMessage}
                                />
                            </Route>
                            <Route path={coreClientRoute.recoveryPasswordInfoSuccess}>
                                <NotificationPage icon={emailOutlinedIcon} notificationText={recoveryLinkSentMessage} />
                            </Route>
                            <Route path={coreClientRoute.registrationInfoNotFound}>
                                <NotificationPage
                                    icon={notInterestedIcon}
                                    notificationText={confirmingEmailNotFoundInfo}
                                    withActionButtons
                                    customActionButtons={ConfirmingEmailNotFoundPageBtn}
                                />
                            </Route>

                            <Route path={clientRoute.root} exact>
                                <Authentication>
                                    <RedirectByRole />
                                </Authentication>
                            </Route>
                            <Route>
                                <Redirect to={coreClientRoute.notFound} />
                            </Route>
                        </Switch>
                        <NotificationComponent />
                    </AppContentContainer>
                    <Footer />
                </Route>
            </Switch>
        </Box>
    );
});
