import { Box, Grid } from '@mui/material';
import { TotTablePagination } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent } from 'react';
import { di } from 'react-magnetic-di';
import { useCreateRequestDialog } from '../../../hooks';
import { CampaignListModel } from '../../../models';
import { CreateRequestDialog as CreateRequestDialogInj } from '../create-request-dialog';
import { CampaignListCard as CampaignCardInj } from './CampaignListCard';

export type CampaignListGridProps = {
    model: CampaignListModel;
};

const defaultPageSize = 12;
const pageSizes = [defaultPageSize, 24, 50, 100, 500];

export const CampaignListGrid = observer((props: CampaignListGridProps): JSX.Element => {
    const [CampaignCard] = di([CampaignCardInj], CampaignListGrid);
    const [CreateRequestDialog] = di([CreateRequestDialogInj], CampaignListGrid);

    const { model } = props;
    const { count, renderItems, currentPage, pageSize, setCurrentPage, setPageSize } = model;

    const onPageChange = (event: unknown, page: number): void => {
        setCurrentPage(page - 1);
    };

    const onChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const newPageSize = Number(event.target.value);

        if (currentPage !== 0) {
            if (newPageSize < count) {
                const isCurrentPageIsLastPage = currentPage === Math.ceil(count / pageSize) - 1;

                if (isCurrentPageIsLastPage) {
                    const newLastPageIndex = Math.ceil(count / newPageSize) - 1;
                    setCurrentPage(newLastPageIndex);
                } else {
                    const listedItems = pageSize * (currentPage + 1);
                    const currentPageToRedirect = Math.ceil(listedItems / newPageSize) - 1;
                    setCurrentPage(currentPageToRedirect);
                }
            } else {
                setCurrentPage(0);
            }
        }

        setPageSize(newPageSize);
    };

    const { isCreateRequestDialogOpen, closeCreateRequestDialog, openCreateRequestDialog } = useCreateRequestDialog();

    return (
        <React.Fragment>
            <CreateRequestDialog onCancel={closeCreateRequestDialog} open={isCreateRequestDialogOpen} />
            <Grid container item direction="column" spacing={2}>
                <Grid container item spacing={2}>
                    {renderItems.map((campaign) => {
                        const campaignId = campaign.id;
                        const onCreateRequestButtonClick = (): Promise<void> => {
                            return openCreateRequestDialog(campaignId);
                        };
                        return (
                            <CampaignCard
                                onCreateRequestButtonClick={onCreateRequestButtonClick}
                                campaign={campaign}
                                key={campaignId}
                            />
                        );
                    })}
                </Grid>
                <Box pb={6} pt={2} pl={3}>
                    <Grid item alignSelf="center">
                        <TotTablePagination
                            count={count}
                            onChangePage={onPageChange}
                            page={currentPage}
                            rowsPerPageOptions={pageSizes}
                            onChangeRowsPerPage={onChangeRowsPerPage}
                            rowsPerPage={pageSize}
                            isWithPageInfo={false}
                        />
                    </Grid>
                </Box>
            </Grid>
        </React.Fragment>
    );
});
