import { SignatureInfoDTO, SignatureStatus } from '@platform/crypto-ui';
import { ApiStore } from '@platform/front-core';
import { action, makeObservable, observable } from 'mobx';
import { di } from 'react-magnetic-di';
import { apiConfigs } from '../apiConfigs';
import { RootStore } from './RootStore';

export const FormioSignatureStoreProps = {
    rootStore: observable,
    api: observable,
    onSuccessForFormioInstance: observable,
    getFileByte: action.bound,
    uploadSignature: action.bound,
    uploadSignatureFile: action.bound,
    getSignatureInfo: action.bound,
    downloadSignature: action.bound,
    deleteSignature: action.bound,
    setOnSuccessForFormioInstance: action.bound,
};

export class FormioSignatureStore {
    private rootStore: RootStore;
    private api: ApiStore;

    onSuccessForFormioInstance?: () => void;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.api = rootStore.coreRootStore.api;
        makeObservable(this, FormioSignatureStoreProps);
    }

    getFileByte(url: string): () => Promise<ArrayBuffer> {
        return (): Promise<ArrayBuffer> => {
            return this.api.client(apiConfigs.getFormioFileByte(url)).then((r) => r.data);
        };
    }

    uploadSignature(url: string): (signature: string) => Promise<SignatureStatus> {
        return (signature: string): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.uploadFormioSignature(url, signature)).then((r) => r.data);
        };
    }

    uploadSignatureFile(url: string): (signatureFile: FormData) => Promise<SignatureStatus> {
        return (signatureFile: FormData): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.uploadFormioSignatureFile(url, signatureFile)).then((r) => r.data);
        };
    }

    getSignatureInfo(url: string): () => Promise<SignatureInfoDTO[]> {
        return (): Promise<SignatureInfoDTO[]> => {
            return this.api.client(apiConfigs.getFormioSignatureInfo(url)).then((r) => r.data);
        };
    }

    downloadSignature(url: string): (isWithFile: boolean) => Promise<Blob> {
        return (isWithFile: boolean): Promise<Blob> => {
            return this.api.client(apiConfigs.downloadFormioSignature(url, isWithFile)).then((r) => r.data);
        };
    }

    deleteSignature(url: string): () => Promise<SignatureStatus> {
        return (): Promise<SignatureStatus> => {
            return this.api.client(apiConfigs.deleteFormioSignature(url)).then((r) => r.data);
        };
    }

    setOnSuccessForFormioInstance(onSuccess: () => void): void {
        this.onSuccessForFormioInstance = onSuccess;
    }
}

export const getFormioSignatureStore = (): any => {
    const [_FormioSignatureStore] = di([FormioSignatureStore], getFormioSignatureStore);
    return _FormioSignatureStore;
};
