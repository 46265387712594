import { FormDTO } from '@platform/formiojs-react';
import { FileDTO, UserPersonDTO } from '@platform/front-core';
import { IdTitle } from '@platform/front-utils';

export type RequestDTO = {
    id: string;
    number: string;
    regFormTitle: string;
    campaign: IdTitle;
    created: string; // date
    sent?: string; // date
    regEnd?: string; // date
    author: UserPersonDTO;
    sections: RequestSectionListDTO[];
    state: string;
    regFormInstructions: FileDTO[];
};

export type RequestSectionListDTO = RequestSubSectionListDTO & {
    subSections?: RequestSubSectionListDTO[];
};

export type RequestSubSectionListDTO = IdTitle & {
    openOnCreation: boolean;
};

export type RequestSectionDTO = IdTitle & {
    formInfo: FormDTO;
    executor?: UserPersonDTO;
};

export enum DeadlineStatus {
    VALID = 'valid',
    WARNING = 'warning',
    INVALID = 'invalid',
    ERROR = 'error',
}

export type DeadlineDTO = {
    date?: Date;
    status: DeadlineStatus;
    delay: boolean;
};

export type DeadlinesDTO = {
    entityDeadline?: DeadlineDTO;
    stateDeadline?: DeadlineDTO;
};
