import { Autocomplete, AutocompleteRenderInputParams, Grid, TextField, Typography } from '@mui/material';
import { CKEditorToolbarConfigDefault } from '@platform/comments';
import { FieldWithServerError, FieldWithServerErrorProps, inputWithPlaceholderProps } from '@platform/front-core';
import { makeSxStyles } from '@platform/front-ui';
import { useFormikContext } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RequestFormSectionSettingModel } from '../../../../../../../../../../models';
import {
    CommentsSectionSettingsFormField,
    RequestFormSectionSettingFormValues,
} from '../../../../../../../../../../types';

const checkboxProps: FieldWithServerErrorProps = {
    type: 'checkbox',
    component: CheckboxWithLabel,
    isWithoutHelperTextProp: true,
};

const sx = makeSxStyles({
    flexColumn: {
        flexDirection: 'column',
    },
    topBar: {
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: (theme) => theme.spacing(1),
        minHeight: (theme) => theme.spacing(4),
    },
});

export type CampaignSettingsCommentsSettingDialogFormFieldsProps = {
    sectionSettingModel: RequestFormSectionSettingModel;
};

/** Возвращает "путь" к вложенному ДТО настроек комментариев */
const getCommentsPropertyPath = <T extends Record<string, unknown>>(
    field: CommentsSectionSettingsFormField,
): keyof T => {
    return 'commentAttributes.' + field;
};

/**
 * Компонент, объединяющий поля настройки комментариев
 * @prop sectionSettingModel - Модель формы настройки создания раздела
 */
export const CampaignSettingsCommentsSettingDialogFormFields = observer(
    (props: CampaignSettingsCommentsSettingDialogFormFieldsProps): JSX.Element => {
        const { sectionSettingModel } = props;
        const { getFieldLabel, getFieldPlaceholder } = sectionSettingModel;
        const { values, setFieldValue } = useFormikContext<RequestFormSectionSettingFormValues>();
        const { commentAttributes, showComments: isCommentsEnabled } = values;

        const {
            commentingAllowed,
            deletable,
            editable,
            showComments,
            maxCommentLength,
            textEditorSettings,
            threadsAllowed,
        } = CommentsSectionSettingsFormField;

        const showCommentsLabel = { label: getFieldLabel(showComments) };
        const commentingAllowedLabel = { label: getFieldLabel(commentingAllowed) };
        const threadsAllowedLabel = { label: getFieldLabel(threadsAllowed) };
        const editableLabel = { label: getFieldLabel(editable) };
        const deletableLabel = { label: getFieldLabel(deletable) };

        const textEditorSettingsPropertyPath =
            getCommentsPropertyPath<RequestFormSectionSettingFormValues>(textEditorSettings);

        const renderTextFieldInput = (params: AutocompleteRenderInputParams): JSX.Element => {
            return <TextField {...params} variant="outlined" label={getFieldLabel(textEditorSettings)} />;
        };

        const getTextEditorSettingsLabel = (setting: string): string => {
            return setting;
        };

        const setTextEditorSettings = (e: any, value?: string | string[] | null): void => {
            setFieldValue(textEditorSettingsPropertyPath, value);
        };

        return (
            <Grid item container sx={sx.flexColumn}>
                <Grid item container sx={sx.topBar}>
                    <Typography variant="button">
                        <FormattedMessage id="campaignSettings.comments" />
                    </Typography>
                </Grid>
                <Grid item container sx={sx.flexColumn} spacing={0.06}>
                    <Grid item>
                        <FieldWithServerError {...checkboxProps} name={showComments} Label={showCommentsLabel} />
                    </Grid>
                    {isCommentsEnabled && (
                        <React.Fragment>
                            <Grid item>
                                <FieldWithServerError
                                    {...checkboxProps}
                                    name={getCommentsPropertyPath(commentingAllowed)}
                                    Label={commentingAllowedLabel}
                                />
                            </Grid>
                            <Grid item>
                                <FieldWithServerError
                                    {...checkboxProps}
                                    name={getCommentsPropertyPath(threadsAllowed)}
                                    Label={threadsAllowedLabel}
                                />
                            </Grid>
                            <Grid item>
                                <FieldWithServerError
                                    {...checkboxProps}
                                    name={getCommentsPropertyPath(editable)}
                                    Label={editableLabel}
                                />
                            </Grid>
                            <Grid item>
                                <FieldWithServerError
                                    {...checkboxProps}
                                    name={getCommentsPropertyPath(deletable)}
                                    Label={deletableLabel}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
                {isCommentsEnabled && (
                    <React.Fragment>
                        <Grid item>
                            <FieldWithServerError
                                {...inputWithPlaceholderProps}
                                name={getCommentsPropertyPath(maxCommentLength)}
                                label={getFieldLabel(maxCommentLength)}
                                placeholder={getFieldPlaceholder(maxCommentLength)}
                            />
                        </Grid>
                        <Grid item mt={2}>
                            <Autocomplete
                                renderInput={renderTextFieldInput}
                                options={CKEditorToolbarConfigDefault}
                                getOptionLabel={getTextEditorSettingsLabel}
                                onChange={setTextEditorSettings}
                                value={commentAttributes.textEditorSettings}
                                multiple
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    },
);
