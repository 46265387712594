import { crypto_ru } from '@platform/crypto-ui';
import { recordToLang } from '@platform/front-utils';
import { multilevelCatalog_ru } from '@platform/multilevel-catalog';
import breadcrumbs from './breadcrumbs';
import campaign from './campaign';
import campaignRequest from './campaignRequest';
import campaignSettings from './campaignSettings';
import common from './common';
import console from './console';
import homePage from './homePage';
import printForms from './printForms';
import startPage from './startPage';

const lang: Record<string, string> = recordToLang(
    {
        productTitle: 'TOT X',
        crypto: crypto_ru,
        startPage,
        homePage,
        campaign,
        campaignSettings,
        campaignRequest,
        common,
        breadcrumbs,
        printForms,
        console,
        multilevelCatalog: multilevelCatalog_ru,
    },
    false,
);

export default lang;
