import { SidebarItem } from '@platform/formiojs-react';

export const getSelectedSidebarItemsIds = (items: SidebarItem[], selectedItemId: string): string[] => {
    const selectedItemsIds: string[] = [];

    items.some((item) => {
        const { id, subItems } = item;

        if (id === selectedItemId) {
            selectedItemsIds.push(id);
            return true;
        }

        if (subItems) {
            const childSelectedItemsIds = getSelectedSidebarItemsIds(subItems, selectedItemId);
            const isWithChildSelectedItemsIds = !!childSelectedItemsIds.length;
            isWithChildSelectedItemsIds && selectedItemsIds.push(id, ...childSelectedItemsIds);
            return isWithChildSelectedItemsIds;
        }

        return false;
    });

    return selectedItemsIds;
};

export const dropFocus = (): void => {
    (document.activeElement as HTMLElement)?.blur();
};
