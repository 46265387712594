import { componentTypes } from '@platform/formio/constants';
import { defaultFormsLocale } from '@platform/front-utils';
import { makeAutoObservable } from 'mobx';
import { getComponentsKeysByType, getFilesDataFromSubmission } from '../utils/additionalInfo';
var FormApi = /** @class */ (function () {
    function FormApi(formApiProps, formModel) {
        this.formModel = {};
        this.lang = defaultFormsLocale;
        this.readOnly = false;
        this.multiLang = false;
        makeAutoObservable(this, {}, { autoBind: true });
        this.formModel = formModel;
        this.lang = formApiProps.lang;
        this.readOnly = formApiProps.readOnly;
        this.multiLang = formApiProps.multiLang;
    }
    FormApi.prototype.setFormioFormApi = function (formioFormApi) {
        this.formioFormApi = formioFormApi;
    };
    FormApi.prototype.setLang = function (lang) {
        this.lang = lang;
    };
    FormApi.prototype.onChangeLang = function (lang) {
        this.saveLangSubmission(); // save prev lang submission
        this.setLang(lang);
    };
    FormApi.prototype.saveLangSubmission = function () {
        if (!this.readOnly && this.formioFormApi) {
            this.formModel.setLangSubmission(this.lang, this.formioFormApi.getSubmission());
        }
    };
    FormApi.prototype.form = function () {
        var _a;
        return (_a = this.formioFormApi) === null || _a === void 0 ? void 0 : _a.form;
    };
    FormApi.prototype.getSubmission = function () {
        var defaultSubmission = {
            ru: {
                data: {},
                meta: {},
            },
        };
        this.saveLangSubmission();
        var submission = this.formModel.getFullSubmission();
        return Object.keys(submission).length ? submission : defaultSubmission;
    };
    FormApi.prototype.getSubmissionWithAdditionalInfo = function () {
        var submission = this.getSubmission();
        var filesKeys = getComponentsKeysByType(this.formModel.form.components, [componentTypes === null || componentTypes === void 0 ? void 0 : componentTypes.categoryFile]);
        var filesData = getFilesDataFromSubmission(submission, filesKeys);
        var submissionWithAdditionalInfo = {
            submission: submission,
            additionalInfo: {
                files: filesData,
            },
        };
        return submissionWithAdditionalInfo;
    };
    FormApi.prototype.setStartSubmission = function () {
        var lang = this.multiLang ? this.lang : undefined;
        this.formModel.setStartSubmission(lang);
    };
    FormApi.prototype.setSubmissionFromStartSubmission = function () {
        this.formModel.setSubmissionFromStartSubmission();
    };
    FormApi.prototype.getExpertiseInfo = function () {
        var _a;
        return (_a = this.formioFormApi) === null || _a === void 0 ? void 0 : _a.getExpertiseInfo();
    };
    FormApi.prototype.validate = function () {
        return this.formioFormApi ? this.formioFormApi.validate() : true;
    };
    FormApi.prototype.submit = function (validation) {
        return this.formioFormApi ? this.formioFormApi.submit(validation) : Promise.resolve();
    };
    FormApi.prototype.destroy = function () {
        var _a;
        (_a = this.formioFormApi) === null || _a === void 0 ? void 0 : _a.destroy();
    };
    return FormApi;
}());
export { FormApi };
