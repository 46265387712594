import { createContext } from 'react';
import { RequestModel } from '../models';

export type CampaignRequestPageContextType = {
    model: RequestModel;
    isFormView: boolean;
    isRequestEdit: boolean;
    isSyslog: boolean;
};

export const CampaignRequestPageContext = createContext<CampaignRequestPageContextType>({
    model: {} as RequestModel,
    isFormView: true,
    isRequestEdit: false,
    isSyslog: false,
});
