import { Button, Grid } from '@mui/material';
import { FormApi } from '@platform/formiojs-react';
import { useFormioHandlers } from '@platform/front-core';
import { PromiseVoidFunction, RouteParamsDefault } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { useCampaignPageContext, useCampaignSettingsPageContext, useStore } from '../../../../../hooks';
import { campaignSettingActionPanelSxStyles } from '../../campaignSettingActionPanelStyles';

export type CampaignSettingsDescriptionActionsPanelProps = {
    formApi?: FormApi;
    reloadForm: PromiseVoidFunction;
};

export const CampaignSettingsDescriptionActionsPanel = observer(
    (props: CampaignSettingsDescriptionActionsPanelProps): JSX.Element => {
        const { formApi } = props;
        const { campaignsStore } = useStore();
        const { updateCampaignForm } = campaignsStore;
        const { reloadModel } = useCampaignPageContext();
        const { isCampaignCreatePage } = useCampaignSettingsPageContext();

        const history = useHistory();
        const { id } = useParams<RouteParamsDefault>();

        const handleClose = (): void => {
            isCampaignCreatePage
                ? history.push(clientRoute.campaigns)
                : history.push(generatePath(clientRoute.campaignSettingsDescription, { id }));
        };

        const goBack = (): void => {
            history.push(generatePath(clientRoute.campaignSettingsDescription, { id }));
        };

        const afterSubmit = (): Promise<void> => {
            return formApi ? updateCampaignForm(id, formApi.getSubmission()).then(reloadModel) : Promise.reject();
        };

        const { handleSubmit, isSubmitDisabled, submitEndIcon } = useFormioHandlers({
            afterSubmit,
            goBack,
            formApi,
            isWithValidation: true,
        });

        return (
            <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                sx={campaignSettingActionPanelSxStyles.actionPanel}
            >
                <Grid item sx={campaignSettingActionPanelSxStyles.actionPanelCancelButton}>
                    <Button color="secondary" variant="text" onClick={handleClose}>
                        <FormattedMessage id="common.cancel" />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color="secondary"
                        disabled={isSubmitDisabled}
                        endIcon={submitEndIcon}
                        onClick={handleSubmit}
                    >
                        <FormattedMessage id="common.saveChanges" />
                    </Button>
                </Grid>
            </Grid>
        );
    },
);
