import { DeleteOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ConfirmationDialog } from '@platform/front-core';
import { PromiseVoidFunction, useFlag, VoidFunction } from '@platform/front-utils';
import React from 'react';
import { useIntl } from 'react-intl';

export type CampaignSettingsDeletePermissionButtonProps = {
    role: string;
    onOpen: VoidFunction;
    onConfirm: PromiseVoidFunction;
};

export const CampaignSettingsDeletePermissionButton = (
    props: CampaignSettingsDeletePermissionButtonProps,
): JSX.Element => {
    const { role, onConfirm, onOpen } = props;

    const intl = useIntl();

    const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useFlag();

    const handleOpen = (): void => {
        openDeleteModal();
        onOpen();
    };

    const handleDeletion = (): Promise<void> => {
        return onConfirm().then(closeDeleteModal);
    };

    const deletePermissionDialogTitle = intl.formatMessage({ id: 'campaignSettings.editPermissions.deletePermission' });
    const deletePermissionMessage = intl.formatMessage(
        { id: 'campaignSettings.editPermissions.deletePermissionConfirmationMessage' },
        {
            role,
        },
    );

    return (
        <React.Fragment>
            <IconButton onClick={handleOpen}>
                <DeleteOutline />
            </IconButton>
            <ConfirmationDialog
                id="delete-permission"
                keepMounted
                open={isDeleteModalOpen}
                onConfirm={handleDeletion}
                onCancel={closeDeleteModal}
                title={deletePermissionDialogTitle}
                message={deletePermissionMessage}
            />
        </React.Fragment>
    );
};
