import { Grid, SxProps, Theme } from '@mui/material';
import { Comments } from '@platform/comments';
import { AuthorizationCheck, FormSavePanel } from '@platform/front-core';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../../authSchemeConfig';
import { clientRoute } from '../../../../../clientRoute';
import { CampaignRequestPrintFormsContext } from '../../../../../contexts';
import { useCampaignRequestPageContext, useCommentsAppConfig, useStore } from '../../../../../hooks';
import { PrintFormListModel as PrintFormListModelInj } from '../../../../../models';
import { CampaignRequestRouteParams } from '../../../../../types';
import { CampaignRequestForm as CampaignRequestFormInj } from './campaign-request-form';
import { CampaignRequestPrintForms as CampaignRequestPrintFormsInj } from './campaign-request-print-forms';

const mainContentWrapperSx: SxProps<Theme> = {
    overflowY: 'auto',
    overflowX: 'hidden',
    flexWrap: 'nowrap',
    margin: 0,
    width: 'auto',
};

const printFormsWrapperSx: SxProps<Theme> = {
    my: 2,
};

export type CampaignRequestContentProps = {
    formWrapperRef: React.RefObject<HTMLDivElement>;
};

export const CampaignRequestContent = observer((props: CampaignRequestContentProps): JSX.Element => {
    const [CampaignRequestForm] = di([CampaignRequestFormInj], CampaignRequestContent);
    const [CampaignRequestPrintForms] = di([CampaignRequestPrintFormsInj], CampaignRequestContent);
    const [PrintFormListModel] = di([PrintFormListModelInj], CampaignRequestContent);

    const { formWrapperRef } = props;
    const params = useParams<CampaignRequestRouteParams>();
    const rootStore = useStore();
    const { sectionId } = params;
    const { model, isFormView } = useCampaignRequestPageContext();
    const { loadRequestServiceInfo } = model;
    const { currentSection, formName, formApi, setFormApi } = model;
    const { requestStore } = useStore();

    const [formIsChanged, , setFormIsChangedFalse, setFormIsChanged] = useFlag(false);

    const afterSubmit = (): Promise<void> => {
        return formApi
            ? requestStore.saveRequestSectionForm(sectionId, formApi.getSubmission()).then(loadRequestServiceInfo)
            : Promise.reject();
    };

    useEffect(() => {
        if (currentSection) {
            currentSection.setPrintForms(new PrintFormListModel(rootStore, sectionId));
        }
    }, [currentSection, rootStore, sectionId]);

    const commentsAppConfig = useCommentsAppConfig({
        entityId: sectionId,
        entityType: entities.section,
    });

    return (
        <Grid item container direction="column" flexGrow={4} flexWrap="nowrap" overflow="hidden">
            <Grid container direction="row" flexGrow={1} flexWrap="nowrap" overflow="hidden">
                <Grid item flexGrow={1} sx={mainContentWrapperSx} ref={formWrapperRef}>
                    <Grid
                        container
                        direction="column"
                        flexWrap="nowrap"
                        p={1}
                        pr={2}
                        {...(isFormView && { style: { paddingTop: 0 } })}
                    >
                        <Grid item>
                            {currentSection && (
                                <CampaignRequestForm
                                    requestModel={model}
                                    section={currentSection}
                                    setFormApi={setFormApi}
                                    setFormIsChanged={setFormIsChanged}
                                    isFormView={isFormView}
                                    formName={formName}
                                />
                            )}
                        </Grid>
                        {isFormView && !!currentSection?.printForms && (
                            <AuthorizationCheck entityCode={entities.section} permCode={permissions.section.ViewPf}>
                                <Grid item sx={printFormsWrapperSx}>
                                    <CampaignRequestPrintFormsContext.Provider
                                        value={{ model: currentSection.printForms }}
                                    >
                                        <CampaignRequestPrintForms />
                                    </CampaignRequestPrintFormsContext.Provider>
                                </Grid>
                            </AuthorizationCheck>
                        )}
                    </Grid>
                    {commentsAppConfig && (
                        <AuthorizationCheck
                            entityCode={entities.section}
                            permCode={permissions.section.ViewComment}
                            entityId={sectionId}
                        >
                            <Comments config={commentsAppConfig} />
                        </AuthorizationCheck>
                    )}
                </Grid>
            </Grid>
            {!isFormView && (
                <Grid item>
                    <FormSavePanel
                        formApi={formApi}
                        isWithValidation={false}
                        returnPath={sectionId ? generatePath(clientRoute.requestSection, params) : ''}
                        afterSubmit={afterSubmit}
                        formIsChanged={formIsChanged}
                        setFormIsChangedFalse={setFormIsChangedFalse}
                    />
                </Grid>
            )}
        </Grid>
    );
});
