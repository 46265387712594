import { FullScreenFormProps } from '@platform/front-core';
import { FullScreenDialog } from '@platform/front-ui';
import { useAntiDoubleClick } from '@platform/front-utils';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { clientRoute } from '../../../../../../../../clientRoute';
import { useCampaignSettingsListContext, useStore } from '../../../../../../../../hooks';
import { CampaignPfTemplateModel } from '../../../../../../../../models';
import { CampaignRequestFormTemplateRouteParams, TemplateFormValues } from '../../../../../../../../types';
import { CampaignSettingsSectionSettingPfEditForm as CampaignSettingsSectionSettingPfEditFormInj } from './CampaignSettingsSectionSettingPfEditForm';

export const CampaignSettingsSectionSettingPfEdit = observer((): JSX.Element => {
    const [CampaignSettingsSectionSettingPfEditForm] = di(
        [CampaignSettingsSectionSettingPfEditFormInj],
        CampaignSettingsSectionSettingPfEdit,
    );
    const isCreatePage = !!useRouteMatch(clientRoute.campaignSettingsRequestFormSectionSettingPFCreate);
    const params = useParams<CampaignRequestFormTemplateRouteParams>();
    const { sectionSettingId, pfId } = params;
    const { setShouldLoadListItems } = useCampaignSettingsListContext();

    const rootStore = useStore();
    const { coreRootStore, campaignsStore } = rootStore;
    const { visibleHeaderHeight } = coreRootStore.headerHeightStore;

    const pfTemplateModel = useMemo(() => {
        return new CampaignPfTemplateModel(pfId, sectionSettingId, rootStore, isCreatePage);
    }, [pfId, sectionSettingId, rootStore, isCreatePage]);

    const { isTemplateDTOLoaded, setInitialFormValues, formInitialValues, getFormValidationSchema } = pfTemplateModel;

    const formValidationSchema = getFormValidationSchema();

    useEffect(() => {
        if (isTemplateDTOLoaded) {
            setInitialFormValues();
        }
    }, [isTemplateDTOLoaded, setInitialFormValues]);

    const history = useHistory();

    const onCancel = (): void => {
        history.push(generatePath(clientRoute.campaignSettingsRequestFormSectionSettingPF, params));
    };

    const onSubmit = (values: TemplateFormValues): Promise<void> => {
        const data = pfTemplateModel.getFormData(values);
        const thenHandler = (): void => {
            setShouldLoadListItems();
            onCancel();
        };
        return isCreatePage
            ? campaignsStore.createTemplate(sectionSettingId, data).then(thenHandler)
            : campaignsStore.updateTemplate(sectionSettingId, pfId, data).then(thenHandler);
    };

    const [isLoading, endIcon, handleSubmit] = useAntiDoubleClick(onSubmit);

    const submitBtnAdditionalProps: FullScreenFormProps['submitBtnAdditionalProps'] = {
        disabled: isLoading,
        endIcon,
    };

    const intl = useIntl();
    const dialogTitle = intl.formatMessage({
        id: isCreatePage ? 'campaignSettings.creatingTemplate' : 'campaignSettings.editingTemplate',
    });

    return (
        <FullScreenDialog open={true} headerHeight={visibleHeaderHeight} title={dialogTitle} onCancel={onCancel}>
            <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                <CampaignSettingsSectionSettingPfEditForm
                    model={pfTemplateModel}
                    onCancel={onCancel}
                    submitBtnAdditionalProps={submitBtnAdditionalProps}
                />
            </Formik>
        </FullScreenDialog>
    );
});
