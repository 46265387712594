class ClientRoute {
    root = '/';

    campaigns = '/campaigns';
    campaign = '/campaigns/:id';
    campaignDescription = '/campaigns/:id/description';
    campaignDescriptionCommonInfo = '/campaigns/:id/description/info';
    campaignDescriptionRequestForms = '/campaigns/:id/description/rf';
    campaignRequests = '/campaigns/:id/requests';

    campaignSettings = '/campaigns/:id/settings';
    campaignSettingsDescription = '/campaigns/:id/settings/description';
    createCampaign = '/campaigns/:id/settings/description/create';
    campaignSettingsEdit = '/campaigns/:id/settings/description/edit';

    // роуты до редизайна
    createCampaignOld = '/campaigns/:id/create';
    campaignSettingsEditOld = '/campaigns/:id/settings/edit';

    campaignSettingsRequestForms = '/campaigns/:id/settings/rf';
    campaignSettingsRequestForm = '/campaigns/:id/settings/rf/:rfId'; // old?
    campaignSettingsRequestFormInfo = '/campaigns/:id/settings/rf/:rfId/info';
    campaignSettingsRequestFormCreate = '/campaigns/:id/settings/rf/:rfId/create';
    campaignSettingsRequestFormEdit = '/campaigns/:id/settings/rf/:rfId/edit';

    campaignSettingsRequestFormRequestPermissions = '/campaigns/:id/settings/rf/:rfId/info/requestPermissions';
    campaignSettingsRequestFormAddRequestPermission = '/campaigns/:id/settings/rf/:rfId/info/requestPermissions/add';

    campaignSettingsRequestFormExecutors = '/campaigns/:id/settings/rf/:rfId/executors'; // todo: используется?
    campaignSettingsRequestFormSectionsOld = '/campaigns/:id/settings/rf/:rfId/tabsSettings';
    campaignSettingsRequestFormSectionOld = '/campaigns/:id/settings/rf/:rfId/tabsSettings/:tabSettingId';
    campaignSettingsRequestFormSectionEditOld = '/campaigns/:id/settings/rf/:rfId/tabsSettings/:tabSettingId/edit';
    campaignSettingsRequestFormSectionCreateOld = '/campaigns/:id/settings/rf/:rfId/tabsSettings/create';

    campaignSettingsRequestFormSectionSettings = '/campaigns/:id/settings/rf/:rfId/sections';
    campaignSettingsRequestFormSectionSettingsCreate = '/campaigns/:id/settings/rf/:rfId/sections/create';
    campaignSettingsRequestFormSectionSettingsEdit = '/campaigns/:id/settings/rf/:rfId/sections/edit';

    campaignSettingsRequestFormSectionSetting = '/campaigns/:id/settings/rf/:rfId/sections/:sectionSettingId';
    campaignSettingsRequestFormSectionSettingInfo = '/campaigns/:id/settings/rf/:rfId/sections/:sectionSettingId/info';
    campaignSettingsRequestFormSectionSettingEdit =
        '/campaigns/:id/settings/rf/:rfId/sections/:sectionSettingId/info/edit';

    campaignSettingsRequestFormSectionSettingCommentsAccessEdit =
        '/campaigns/:id/settings/rf/:rfId/sections/:sectionSettingId/info/comments-access/edit';
    campaignSettingsRequestFormSectionSettingCommentsAccessAdd =
        '/campaigns/:id/settings/rf/:rfId/sections/:sectionSettingId/info/comments-access';

    campaignSettingsRequestFormSectionSettingAccessPermissions =
        '/campaigns/:id/settings/rf/:rfId/sections/:sectionSettingId/info/access-permissions';
    campaignSettingsRequestFormSectionSettingEditPermission =
        '/campaigns/:id/settings/rf/:rfId/sections/:sectionSettingId/info/access-permissions/edit';

    campaignSettingsRequestFormSectionSettingPF = '/campaigns/:id/settings/rf/:rfId/sections/:sectionSettingId/pf';
    campaignSettingsRequestFormSectionSettingPFCreate =
        '/campaigns/:id/settings/rf/:rfId/sections/:sectionSettingId/pf/create';
    campaignSettingsRequestFormSectionSettingPFEdit =
        '/campaigns/:id/settings/rf/:rfId/sections/:sectionSettingId/pf/entity/:pfId/edit';
    campaignSettingsRequestFormSectionSettingPFAccessPermissions =
        '/campaigns/:id/settings/rf/:rfId/sections/:sectionSettingId/pf/access-permissions';
    campaignSettingsRequestFormSectionSettingPFEditPermission =
        '/campaigns/:id/settings/rf/:rfId/sections/:sectionSettingId/pf/access-permissions/edit';

    // todo: unused
    campaignRequestFormTemplates = '/campaigns/:id/settings/rf/:rfId/templates';
    campaignRequestFormTemplatesEdit =
        '/campaigns/:id/settings/rf/:rfId/templates/:templateId/settings/:settingsId/edit';
    campaignRequestFormTemplatesCreate = '/campaigns/:id/settings/rf/:rfId/templates/settings/:settingsId/create';
    campaignRequestFormTemplatesPermissionSettings =
        '/campaigns/:id/settings/rf/:rfId/templates/settings/:settingsId/permissionSettings';

    campaignDocs = '/campaigns/:id/settings/docs';
    campaignManagement = '/campaigns/:id/settings/management';
    generalSettings = '/campaigns/:id/settings/general';

    requests = '/requests';
    requestsNewOld = '/requests/new';
    requestsNew = '/requests-new';

    request = '/requests/:id';
    requestForm = '/requests/:id/form/:formId'; // old
    requestEdit = '/requests/:id/edit'; // old
    requestFormEdit = '/requests/:id/form/:formId/edit'; // old

    requestSection = '/requests/:id/section/:sectionId';
    requestSectionFormEdit = '/requests/:id/section/:sectionId/edit';

    requestApprovalSheets = '/requests/:id/approval';
    requestSyslog = '/requests/:id/syslog';
    requestLog = '/request-log/:id';

    users = '/users';
    user = '/users/:id';

    filteringWithCampaign = (campaignId: string): string => this.requests + `?campaignId=${campaignId}`;

    formVersionLink = (formServiceUrl: string, formVersionId: string) => {
        return `${formServiceUrl}/form/${formVersionId}`;
    };
}

export const clientRoute = new ClientRoute();
