import { Warning } from '@mui/icons-material';
import { Box, Grid, Tooltip } from '@mui/material';
import { DynamicEllipsisBox } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { di } from 'react-magnetic-di';
import { useCampaignRequestPageContext } from '../../../../../hooks';
import { RequestSectionListDTO } from '../../../../../types';
import { CampaignRequestSectionContentMenu as CampaignRequestSectionContentMenuInj } from './CampaignRequestSectionContentMenu';
import { campaignRequestSectionSxStyles } from './sxStyles';

export type CampaignRequestSectionContentProps = {
    section: RequestSectionListDTO;
};

export const CampaignRequestSectionContent = observer((props: CampaignRequestSectionContentProps) => {
    const [CampaignRequestSectionContentMenu] = di(
        [CampaignRequestSectionContentMenuInj],
        CampaignRequestSectionContent,
    );

    const { section } = props;

    const { model } = useCampaignRequestPageContext();
    const { errorsByTabs } = model.errorsModel;

    const { id: sectionId, title, subSections } = section;
    const errors = errorsByTabs[sectionId];
    // todo: доработать вывод дочерних ошибок - отображать ли их только в меню или же все-таки рядом с родительским разделом?
    const sectionError = errors && errors.getGeneralMessage();
    const errorTitle = <Box whiteSpace="pre-line">{sectionError}</Box>;

    return (
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
            <Grid item>
                <DynamicEllipsisBox text={title} maxRows={1} />
            </Grid>
            {sectionError && (
                <Grid item>
                    <Tooltip title={errorTitle}>
                        <Warning sx={campaignRequestSectionSxStyles.warningIcon} />
                    </Tooltip>
                </Grid>
            )}
            {subSections && (
                <Grid item mr={-1}>
                    <CampaignRequestSectionContentMenu subSections={subSections} />
                </Grid>
            )}
        </Grid>
    );
});
