import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton } from '@mui/material';
import { DraggableItem, RenderDraggableListBody } from '@platform/front-core';
import React from 'react';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../../clientRoute';
import { useStore } from '../../../../../../../hooks';
import { useCampaignSettingsTemplateListContext } from '../../../../../../../hooks';
import {
    CampaignRequestFormSectionSettingsRouteParams,
    CampaignSettingsEntities,
    TemplateListDTO,
} from '../../../../../../../types';
import { CampaignSettingsItemDelete as CampaignSettingsItemDeleteInj } from '../../../components';

export const renderSectionSettingTemplatesBody: RenderDraggableListBody<TemplateListDTO> = (props): JSX.Element => {
    const [CampaignSettingsItemDelete] = di([CampaignSettingsItemDeleteInj], renderSectionSettingTemplatesBody);
    const { listItems, draggableId } = props;
    const { campaignsStore } = useStore();
    const params = useParams<CampaignRequestFormSectionSettingsRouteParams>();
    const settingsTemplateListContext = useCampaignSettingsTemplateListContext();
    const { reloadList } = settingsTemplateListContext;

    const listItemsComponents = listItems.map((pf, index) => {
        const pfId = pf.id;
        const paramsFull = { ...params, pfId };
        const templateEditPath = generatePath(clientRoute.campaignSettingsRequestFormSectionSettingPFEdit, paramsFull);
        const deleteItem = (): Promise<void> => campaignsStore.deletePF(pfId).then(reloadList);

        const renderDeleteBtn = (): JSX.Element => (
            <CampaignSettingsItemDelete
                itemToDelete={pf}
                itemType={CampaignSettingsEntities.PF}
                deleteItem={deleteItem}
            >
                <IconButton color="secondary">
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            </CampaignSettingsItemDelete>
        );

        return (
            <DraggableItem
                key={pfId}
                index={index}
                item={pf}
                draggableId={draggableId}
                editPath={templateEditPath}
                renderDeleteBtn={renderDeleteBtn}
            />
        );
    });

    return <React.Fragment>{listItemsComponents}</React.Fragment>;
};
