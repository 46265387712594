(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@mui/material"), require("@mui/system"), require("@platform/front-ui"), require("@platform/front-utils"), require("mobx"), require("mobx-react-lite"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["@mui/material", "@mui/system", "@platform/front-ui", "@platform/front-utils", "mobx", "mobx-react-lite", "react"], factory);
	else if(typeof exports === 'object')
		exports["@platform/formiojs-react"] = factory(require("@mui/material"), require("@mui/system"), require("@platform/front-ui"), require("@platform/front-utils"), require("mobx"), require("mobx-react-lite"), require("react"));
	else
		root["@platform/formiojs-react"] = factory(root["@mui/material"], root["@mui/system"], root["@platform/front-ui"], root["@platform/front-utils"], root["mobx"], root["mobx-react-lite"], root["react"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__80901__, __WEBPACK_EXTERNAL_MODULE__68719__, __WEBPACK_EXTERNAL_MODULE__6871__, __WEBPACK_EXTERNAL_MODULE__79785__, __WEBPACK_EXTERNAL_MODULE__70259__, __WEBPACK_EXTERNAL_MODULE__42487__, __WEBPACK_EXTERNAL_MODULE__8156__) {
return 