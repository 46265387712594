import { Grid } from '@mui/material';
import { List } from '@platform/front-core';
import { lastIndex, useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { sxHeight100 } from '../../../../../../constants';
import { useLoadCampaignRequestForms } from '../../../../../../hooks';
import { CampaignRequestFormRouteParams } from '../../../../../../types';
import { CampaignCardDescriptionRequestForm as CampaignCardDescriptionRequestFormInj } from './CampaignCardDescriptionRequestForm';

/** Компонент, подгружающий и возвращающий список CampaignCardDescriptionRequestForm. */
export const CampaignCardDescriptionRequestForms = observer((): JSX.Element => {
    const [CampaignCardDescriptionRequestForm] = di(
        [CampaignCardDescriptionRequestFormInj],
        CampaignCardDescriptionRequestForms,
    );

    const { id } = useParams<CampaignRequestFormRouteParams>();

    const intl = useIntl();

    const { loadCampaignRequestFormsModel, campaignRequestForms } = useLoadCampaignRequestForms();
    const [isLoading, startLoading, stopLoading] = useFlag(true);

    const emptyListProps = {
        noItemsTitle: intl.formatMessage({ id: 'campaignSettings.noRequestForms' }),
    };

    const requestFormCards = campaignRequestForms.map((requestForm, index) => {
        const isLastIndex = index === lastIndex(campaignRequestForms);
        const { id } = requestForm;
        return <CampaignCardDescriptionRequestForm key={id} requestForm={requestForm} isLast={isLastIndex} />;
    });

    const campaignRequestFormsLength = campaignRequestForms.length;

    const getForms = (): Promise<void> => {
        // Promise.resolve потому что List требует только Promise<void>
        startLoading();
        return loadCampaignRequestFormsModel(id)
            .then(() => {
                return Promise.resolve();
            })
            .finally(stopLoading);
    };

    useEffect(() => {
        getForms();
    }, []);

    return (
        <Grid container direction="column" flexWrap="nowrap" sx={sxHeight100}>
            <List isLoading={isLoading} {...(!campaignRequestFormsLength && { emptyListProps })}>
                {requestFormCards}
            </List>
        </Grid>
    );
});
