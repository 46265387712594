import { CodeTitle, CodeTitleGroup } from '@platform/front-utils';

export type CodeTitleGroupRC = CodeTitleGroup<PermissionTrigger>;

export enum PermissionsType {
    templatePermissions = 'templatePermissions',
    permissions = 'permissions',
}

export enum PermissionTrigger {
    role = 'role',
    condition = 'condition',
}

export type PermissionSettingsDTO = {
    processCode: string;
    permissions: AccessPermissionDTO[];
};

export type AccessPermissionDTO = {
    roleCondition: CodeTitleGroupRC;
    whenObjectStateIsOneOf: CodeTitle[];
    allowEdit: boolean;
};

export type AccessPermissionInitialValues = Omit<AccessPermissionDTO, 'roleCondition'> & {
    roleCondition: CodeTitleGroupRC | null;
};

export type NewPermissionDTO = {
    role?: string;
    when?: string;
    whenObjectStateIsOneOf: string[];
    allowEdit: boolean;
};

export type LoadPermissions = () => Promise<PermissionSettingsDTO>;
export type UpdatePermissions = (dto: NewPermissionDTO[]) => Promise<void>;
