import { Box, Divider, Grid, RadioGroup, SxProps, Typography } from '@mui/material';
import { Loader } from '@platform/front-core';
import { DialogActions, FullScreenDialog, FullScreenDialogContent, FullScreenDialogSize } from '@platform/front-ui';
import { useAntiDoubleClick, VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { sxHeight100 } from '../../../constants';
import { useHiddenScroll, useStore } from '../../../hooks';
import { CampaignRequestFormListMember } from '../../../types';
import { CreateRequestDialogRequestFormCard as CreateRequestDialogRequestFormCardInj } from './CreateRequestDialogRequestFormCard';

export const createRequestRadioGroupSx: SxProps = {
    flexWrap: 'nowrap',
};

export type CreateRequestDialogProps = {
    onCancel: VoidFunction;
    open: boolean;
};

/**
 * Выезжающий попап со списком форм заявок.
 * @param onCancel {() => void} Функция, срабатывающая при нажатии на кнопку "закрыть".
 * @param open {boolean} Флаг, открывающий FullScreenDialog.
 */
export const CreateRequestDialog = observer((props: CreateRequestDialogProps): JSX.Element => {
    const [CreateRequestDialogRequestFormCard] = di([CreateRequestDialogRequestFormCardInj], CreateRequestDialog);

    const { onCancel, open } = props;

    const { coreRootStore, campaignsStore } = useStore();
    const { campaignRequestFormListModel } = campaignsStore;
    const { visibleHeaderHeight } = coreRootStore.headerHeightStore;

    const intl = useIntl();
    useHiddenScroll(open);

    const [selectedRequestFormId, setSelectedRequestFormId] = useState<string>('');

    const createRequest = (): Promise<void> => {
        return campaignsStore.createRequest(selectedRequestFormId);
    };

    const [isSubmitDisabled, submitEndIcon, createHandler] = useAntiDoubleClick(createRequest);

    const isNotSelectedRequestFormId = !selectedRequestFormId;

    const loader = campaignRequestFormListModel?.isLoading ? <Loader fullSize /> : null;

    const requestForms = campaignRequestFormListModel?.campaignRequestForms || [];

    const requestFormCards = requestForms.map((requestForm: CampaignRequestFormListMember) => {
        const { id } = requestForm;
        return <CreateRequestDialogRequestFormCard key={id} requestForm={requestForm} />;
    });

    const onSelect = (event: React.ChangeEvent<{}>, value: string): void => {
        setSelectedRequestFormId(value);
    };

    const onCloseDialog = (): void => {
        onCancel();
        setSelectedRequestFormId('');
    };

    const cancelButton = {
        onClick: onCloseDialog,
    };

    const submitButton = {
        onClick: createHandler,
        text: intl.formatMessage({ id: 'campaign.createRequest' }),
        disabled: isNotSelectedRequestFormId || isSubmitDisabled,
        endIcon: submitEndIcon,
        tooltip: isNotSelectedRequestFormId
            ? {
                  title: intl.formatMessage({ id: 'campaignRequest.selectRequestForm' }),
              }
            : undefined,
    };

    return (
        <FullScreenDialog
            open={open}
            title={intl.formatMessage({ id: 'campaignRequest.requestCreation' })}
            onCancel={onCloseDialog}
            headerHeight={visibleHeaderHeight}
            size={FullScreenDialogSize.large}
        >
            <FullScreenDialogContent>
                <Grid container direction="column" flexWrap="nowrap" sx={sxHeight100}>
                    <Grid item>
                        <Typography>
                            <FormattedMessage id="campaignRequest.selectRequestFormFromList" />
                        </Typography>
                    </Grid>
                    {loader || (
                        <Grid item>
                            <Box pt={2}>
                                <RadioGroup
                                    value={selectedRequestFormId}
                                    onChange={onSelect}
                                    sx={createRequestRadioGroupSx}
                                >
                                    {requestFormCards}
                                </RadioGroup>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </FullScreenDialogContent>
            <Divider />
            <DialogActions cancelButton={cancelButton} submitButton={submitButton} />
        </FullScreenDialog>
    );
});
