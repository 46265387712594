import { Typography } from '@mui/material';
import React from 'react';

export type ViewFieldValueProps = {
    value?: string | number;
};

export const ViewFieldValue = (props: ViewFieldValueProps): JSX.Element => {
    const { value = '-' } = props;
    return <Typography variant="body1">{value}</Typography>;
};
