import { Theme, ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';
import {
    ActionMenuItem as ActionMenuItemInj,
    AuthorizationCheckQuery,
    ConfirmationDialog as ConfirmationDialogInj,
    MenuButton as MenuButtonInj,
} from '@platform/front-core';
import { KebabButton } from '@platform/front-ui';
import { identity, IdTitle, useFlag, VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useHistory } from 'react-router-dom';
import { permissionsConfig } from '../../../../authSchemeConfig';
import { clientRoute } from '../../../../clientRoute';
import { useFetchAllPermissions, useStore } from '../../../../hooks';
import { CampaignRequestListConfirmationButtons, CampaignRequestRow } from '../../../../types';
import {
    CampaignRequestEditExecutorDialog as CampaignRequestEditExecutorDialogInj,
    CampaignRequestEditExecutorDialogInfo as CampaignRequestEditExecutorDialogInfoInj,
    CampaignRequestEditExecutorFormType,
} from '../../components';
import { useSettingsConfirmDialog } from './useSettingsConfirmDialog';

export type CampaignRequestListActionsBtnsProps = {
    campaignRequestRow: CampaignRequestRow;
    reloadData: VoidFunction;
    toggleIsRowBlocked: VoidFunction;
    rowErrorHandler: VoidFunction;
};

export const CampaignRequestListActionsBtns = observer((props: CampaignRequestListActionsBtnsProps): JSX.Element => {
    const [ConfirmationDialog] = di([ConfirmationDialogInj], CampaignRequestListActionsBtns);
    const [MenuButton] = di([MenuButtonInj], CampaignRequestListActionsBtns);
    const [ActionMenuItem] = di([ActionMenuItemInj], CampaignRequestListActionsBtns);
    const [CampaignRequestEditExecutorDialogInfo] = di(
        [CampaignRequestEditExecutorDialogInfoInj],
        CampaignRequestListActionsBtns,
    );
    const [CampaignRequestEditExecutorDialog] = di(
        [CampaignRequestEditExecutorDialogInj],
        CampaignRequestListActionsBtns,
    );

    const { campaignRequestRow, reloadData, toggleIsRowBlocked, rowErrorHandler } = props;
    const { id, customData } = campaignRequestRow;
    const { number } = customData;
    const { requestStore, theme } = useStore();
    const history = useHistory();
    const intl = useIntl();

    const [isOpenEditExecutorDialog, openEditExecutorDialog, closeEditExecutorDialog] = useFlag();
    const [isModalOpen, openModal, closeModal] = useFlag();

    const allPermissionsQueries = useMemo<AuthorizationCheckQuery[]>(() => {
        return [
            permissionsConfig.addActualAppointment(id),
            permissionsConfig.updateActualAppointment(id),
            permissionsConfig.editCampaignRequest(id),
            permissionsConfig.deleteCampaignRequest(id),
        ];
    }, [id]);
    const [permissionsData, reloadPermission] = useFetchAllPermissions({ allPermissionsQueries });
    const [addActualAppointment, updateActualAppointment, editCampaignRequest, deleteCampaignRequest] = permissionsData;

    const reloadDataAndPermission = (): void => {
        reloadData();
        reloadPermission();
    };

    const onDeleteConfirm = async (): Promise<void> => {
        try {
            toggleIsRowBlocked();
            await requestStore.deleteRequest(id);
            reloadData();
        } catch {
            rowErrorHandler();
        } finally {
            closeModal();
        }
    };

    const handleConfirmAddExecutor = useCallback((): Promise<void> => {
        return requestStore.setCurrentUserAsExecutor(id).finally(() => {
            reloadDataAndPermission();
            closeModal();
        });
    }, [reloadData, id, reloadPermission]);

    const handleEditExecutorDialogOpen = useCallback((): void => {
        openEditExecutorDialog();
    }, []);

    const handleSubmitExecutor = async (values: CampaignRequestEditExecutorFormType): Promise<void> => {
        try {
            toggleIsRowBlocked();
            await requestStore.editExecutorSettings(campaignRequestRow.id, (values.executor as IdTitle).id);
            reloadDataAndPermission();
        } catch {
            rowErrorHandler();
        } finally {
            closeEditExecutorDialog();
        }
    };

    const [settings, setSettings] = useSettingsConfirmDialog({
        delete: onDeleteConfirm,
        addExecutor: handleConfirmAddExecutor,
    });

    const confirmationMessage =
        CampaignRequestListConfirmationButtons.addExecutor === settings.id ? (
            <React.Fragment>
                <CampaignRequestEditExecutorDialogInfo campaignRequestRow={campaignRequestRow} />
                <Box pt={1}>
                    {intl.formatMessage(
                        {
                            id: settings.messageId,
                        },
                        { number: number.title },
                    )}
                </Box>
            </React.Fragment>
        ) : (
            intl.formatMessage(
                {
                    id: settings.messageId,
                },
                { number: number.title },
            )
        );

    const renderActionItems = useCallback(
        (hideMenu): ReactNode[] => {
            const onDeleteClick = (): void => {
                setSettings(CampaignRequestListConfirmationButtons.delete);
                hideMenu && hideMenu();
                openModal();
            };

            const onEditClick = (): void => {
                history.push(generatePath(clientRoute.requestEdit, { id }));
            };

            const onAddExecutorClick = (): void => {
                setSettings(CampaignRequestListConfirmationButtons.addExecutor);
                hideMenu && hideMenu();
                openModal();
            };

            const onOpenEditExecutorDialog = (): void => {
                hideMenu && hideMenu();
                handleEditExecutorDialogOpen();
            };

            return [
                <React.Fragment key="actionBtns">
                    {addActualAppointment && updateActualAppointment && (
                        <ActionMenuItem
                            messageId="campaignRequest.actions.appointExecutor"
                            onClick={onOpenEditExecutorDialog}
                        />
                    )}
                    {!addActualAppointment && updateActualAppointment && (
                        <ActionMenuItem
                            messageId="campaignRequest.actions.changeExecutor"
                            onClick={onOpenEditExecutorDialog}
                        />
                    )}
                    {addActualAppointment && (
                        <ActionMenuItem
                            messageId="campaignRequest.actions.requestToWork"
                            onClick={onAddExecutorClick}
                        />
                    )}
                    {editCampaignRequest && <ActionMenuItem messageId="common.edit" onClick={onEditClick} />}
                    {deleteCampaignRequest && <ActionMenuItem messageId="common.delete" onClick={onDeleteClick} />}
                </React.Fragment>,
            ];
        },
        [permissionsData, campaignRequestRow, handleEditExecutorDialogOpen],
    );

    const renderActionsButton = (onClick: (event: React.MouseEvent<HTMLButtonElement>) => void): JSX.Element => {
        const atLeastOneAllowed = permissionsData.some(identity);
        return atLeastOneAllowed ? <KebabButton onClick={onClick} /> : <React.Fragment />;
    };

    return (
        <React.Fragment>
            <MenuButton renderButton={renderActionsButton} renderMenuItems={renderActionItems} />
            <ConfirmationDialog
                id={settings.id}
                open={isModalOpen}
                title={intl.formatMessage({
                    id: settings.titleId,
                })}
                message={confirmationMessage}
                onConfirm={settings.onConfirm}
                onCancel={closeModal}
                keepMounted
                maxWidth="xs"
            />
            {updateActualAppointment && (
                <ThemeProvider theme={theme as Theme}>
                    <CampaignRequestEditExecutorDialog
                        onCancel={closeEditExecutorDialog}
                        onSubmit={handleSubmitExecutor}
                        open={isOpenEditExecutorDialog}
                        campaignRequestRow={campaignRequestRow}
                        requestId={campaignRequestRow.id}
                    />
                </ThemeProvider>
            )}
        </React.Fragment>
    );
});
