import { styled } from '@mui/material';
import { StyledComponent } from '@mui/styles';
import { Form, FormikFormProps } from 'formik';
import React from 'react';

export const CampaignSettingsEditPermissionFormikFormStyled: StyledComponent<FormikFormProps> = styled(
    (props: FormikFormProps) => <Form {...props} />,
)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
