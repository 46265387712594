import { promiseVoidFunction, PromiseVoidFunction, VoidFunction, voidFunction } from '@platform/front-utils';
import { createContext } from 'react';
import { AccessPermissionModel } from '../models';
import { LoadPermissions, PermissionSettingsDTO, SelectedIndex, UpdatePermissions } from '../types';

export type CampaignSettingsAccessPermissionContextType = {
    editOrAddPermissionDialogTitle: string;
    checkboxLabel: string;
    loadPermissions: LoadPermissions;
    updatePermissions: UpdatePermissions;
    model: AccessPermissionModel;
    editPermission: (selectedIndex: SelectedIndex) => VoidFunction;
    onDeleteConfirm: PromiseVoidFunction;
    onDelete: (deleteIndex: number) => VoidFunction;
    selectedPermissionIndex: SelectedIndex;
    editPermissionRoutePath: string;
    goBack: VoidFunction;
};

export const CampaignSettingsAccessPermissionContext = createContext<CampaignSettingsAccessPermissionContextType>({
    editOrAddPermissionDialogTitle: '',
    checkboxLabel: '',
    loadPermissions: () => Promise.resolve({} as PermissionSettingsDTO),
    updatePermissions: promiseVoidFunction,
    model: {} as AccessPermissionModel,
    editPermission: () => voidFunction,
    onDeleteConfirm: promiseVoidFunction,
    onDelete: () => voidFunction,
    selectedPermissionIndex: null,
    editPermissionRoutePath: '',
    goBack: voidFunction,
});
