import { AuthorizationCheck, NewPermissionDTO } from '@platform/front-core';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, useParams } from 'react-router-dom';
import { entities, permissions } from '../../../../../../../../../../authSchemeConfig';
import { clientRoute } from '../../../../../../../../../../clientRoute';
import { useStore } from '../../../../../../../../../../hooks';
import { CampaignRequestFormRouteParams, PermissionSettingsDTO } from '../../../../../../../../../../types';
import { CampaignSettingsAccessPermissionButton as CampaignSettingsAccessPermissionButtonInj } from '../../../../../../components';
import { CampaignRequestFormSectionEditParams } from '../../../../CampaignSettingsSectionSettingRoutes';

/** Компонент, группирующий все настройки для кнопки настроек доступа к комментариям. */
export const CampaignSettingsAccessCommentsPermissionButton = observer(() => {
    const [CampaignSettingsAccessPermissionButton] = di(
        [CampaignSettingsAccessPermissionButtonInj],
        CampaignSettingsAccessCommentsPermissionButton,
    );
    const params = useParams<CampaignRequestFormSectionEditParams & CampaignRequestFormRouteParams>();
    const { sectionSettingId } = params;
    const intl = useIntl();
    const { campaignsStore } = useStore();
    const { getCommentPermissions, updateCommentPermissions } = campaignsStore;

    const accessPermissionRoutePath = generatePath(
        clientRoute.campaignSettingsRequestFormSectionSettingCommentsAccessAdd,
        params,
    );

    const accessPermissionRoutePathTemplate = generatePath(
        clientRoute.campaignSettingsRequestFormSectionSettingCommentsAccessAdd,
        params,
    );

    const editPermissionRoutePath = generatePath(
        clientRoute.campaignSettingsRequestFormSectionSettingCommentsAccessEdit,
        params,
    );

    const editPermissionRoutePathTemplate = generatePath(
        clientRoute.campaignSettingsRequestFormSectionSettingCommentsAccessEdit,
        params,
    );

    const accessPermissionDialogTitle = intl.formatMessage({
        id: 'campaignSettings.commentsSetting.accessSectionSetting',
    });

    const addPermissionDialogTitle = intl.formatMessage({
        id: 'campaignSettings.commentsSetting.addingSectionPermission',
    });

    const editPermissionDialogTitle = intl.formatMessage({
        id: 'campaignSettings.commentsSetting.editingSectionPermission',
    });

    const emptyAccessPermissionList = intl.formatMessage({
        id: 'campaignSettings.commentsSetting.emptyAccessPermissionListSection',
    });

    const checkboxLabel = intl.formatMessage({ id: 'campaignSettings.editPermissions.canComment' });
    const buttonTitle = intl.formatMessage({ id: 'campaignSettings.commentsSetting.setSectionAccess' });

    const loadPermissions = useCallback((): Promise<PermissionSettingsDTO> => {
        return getCommentPermissions(sectionSettingId);
    }, []);

    const savePermissions = useCallback((dto: NewPermissionDTO[]): Promise<void> => {
        return updateCommentPermissions(sectionSettingId, dto);
    }, []);

    // Потому что наличие sectionSettingId означает существование раздела в базе данных.
    // Без существующего раздела, настройка комментариев этого раздела невозможна, ибо нечего настраивать
    // на том, чего ещё не существует...
    if (!sectionSettingId) {
        return <React.Fragment />;
    }

    return (
        <AuthorizationCheck
            entityCode={entities.sectionSettings}
            permCode={permissions.sectionSettings.CommentManagement}
            entityId={sectionSettingId}
        >
            <CampaignSettingsAccessPermissionButton
                accessPermissionRoutePath={accessPermissionRoutePath}
                accessPermissionRoutePathTemplate={accessPermissionRoutePathTemplate}
                editPermissionRoutePath={editPermissionRoutePath}
                editPermissionRoutePathTemplate={editPermissionRoutePathTemplate}
                accessPermissionDialogTitle={accessPermissionDialogTitle}
                addPermissionDialogTitle={addPermissionDialogTitle}
                editPermissionDialogTitle={editPermissionDialogTitle}
                emptyAccessPermissionList={emptyAccessPermissionList}
                checkboxLabel={checkboxLabel}
                buttonTitle={buttonTitle}
                loadPermissions={loadPermissions}
                updatePermissions={savePermissions}
                buttonVariant="outlined"
            />
        </AuthorizationCheck>
    );
});
