import { LocalesTree } from '@platform/front-utils';

const campaign: LocalesTree = {
    titleCampaignListPage: 'Категории',
    newCampaign: 'Новая категория',
    titleCreateCampaignPage: 'Создание категории',
    emptyList: 'В данный момент отсутствуют созданные категории',
    emptyActiveList: 'В данный момент отсутствуют активные категории',
    createCampaign: 'Создать категорию',
    editCampaign: 'Редактирование категории',
    files: 'Приложенные файлы',
    campaignPeriod: 'Активна',
    campaignPeriodIfNotStarted: 'Ожидает запуск',
    createRequest: 'Создать заявку',
    commonInfo: 'Общая информация',
    description: 'Описание',
    requests: 'Заявки',

    filters: {
        title: 'Название',
        allStates: 'Все состояния',
        startDate: 'Начало сбора',
        endDate: 'Окончание сбора',
    },

    lifecycle: 'Выберите ЖЦ',
    settingsRequestFormTabs: {
        information: 'Информация',
        sections: 'Разделы',
        pf: 'Шаблоны ПФ',
    },

    campaignManagementDelete: 'Вы действительно хотите удалить категорию «{campaignName}»?',
    deleteCampaign: 'Удалить категорию',

    campaignRequestFormExecutorResponsibleExecutor: 'Ответственный исполнитель',

    collectionCompleted: 'Сбор завершен',
    createRequestError: 'Создание заявок по категории недоступно',

    userRequests: {
        title: 'Ваши заявки',
        endMessage: 'Более ранних заявок нет',
        isEmpty: 'У вас нет созданных заявок',
        createRequest: 'Создать новую заявку',
        fields: {
            created: 'Дата создания',
            sent: 'Дата подачи',
            sentAlt: 'Не подана',
            regForm: 'Форма заявки',
            number: 'Номер заявки',
        },
    },

    requestForm: {
        createRequest: 'Перейти к созданию заявки',
    },
};

export default campaign;
