import { Box, Button, Grid, SxProps, Theme, Typography } from '@mui/material';
import { AuthorizationCheck as AuthorizationCheckInj, List } from '@platform/front-core';
import { grey, ListView, makeSxStyles, TotToggleGridButtons } from '@platform/front-ui';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { entities, permissions } from '../../../authSchemeConfig';
import { sxHeight100 } from '../../../constants';
import { useStore } from '../../../hooks';
import { CampaignListModel } from '../../../models';
import { CampaignListGrid as CampaignListGridInj } from './CampaignListGrid';
import { CreateCampaignModal as CreateCampaignModalInj } from './CreateCampaignModal';
import { CampaignListRegistry } from './registry';

const titleSx: SxProps<Theme> = { color: grey[900] };

export const campaignListPageSxStyles = makeSxStyles({
    emptyList: {
        height: (theme) => `calc(100% - ${theme.spacing(9)})`,
    },
});

export const CampaignListPage = observer((): JSX.Element => {
    const [AuthorizationCheck] = di([AuthorizationCheckInj], CampaignListPage);
    const [CampaignListGrid] = di([CampaignListGridInj], CampaignListPage);
    const [CreateCampaignModal] = di([CreateCampaignModalInj], CampaignListPage);

    const intl = useIntl();
    const { coreRootStore } = useStore();
    const { api, env } = coreRootStore;
    const { apiUrl } = env;

    const [isCreateModalOpen, openCreateModal, closeCreateModal] = useFlag();

    const model = useMemo(() => new CampaignListModel(api, apiUrl), [api, apiUrl]);
    const { isEmpty, isLoading, getPaginationData } = model;

    const [selectedGrid, setSelectedGrid] = useState<ListView>(ListView.grid);
    const paddingListCategories = selectedGrid === ListView.grid ? 2 : 1.75;

    useEffect(() => {
        getPaginationData();
    }, []);

    const createButtonProps = {
        title: intl.formatMessage({ id: 'campaign.createCampaign' }),
        createItem: openCreateModal,
    };

    return (
        <React.Fragment>
            <CreateCampaignModal onCancel={closeCreateModal} open={isCreateModalOpen} />
            <Box padding={2} sx={isEmpty ? campaignListPageSxStyles.emptyList : {}}>
                <Grid container justifyContent="space-between" alignItems="center" pb={2}>
                    <Grid item>
                        <Typography variant="subtitle2" sx={titleSx}>
                            <FormattedMessage id="campaign.titleCampaignListPage" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.AddCampaign}>
                            <Button color="secondary" size="large" onClick={openCreateModal}>
                                <FormattedMessage id="campaign.createCampaign" />
                            </Button>
                        </AuthorizationCheck>
                    </Grid>
                </Grid>

                <AuthorizationCheck entityCode={entities.system} permCode={permissions.system.AddCampaign}>
                    {(allowed): JSX.Element => {
                        const emptyListProps = {
                            noItemsTitle: intl.formatMessage({
                                id: allowed ? 'campaign.emptyList' : 'campaign.emptyActiveList',
                            }),
                            ...(allowed && { createButtonProps }),
                        };

                        return (
                            <Grid container sx={sxHeight100}>
                                <List isLoading={isLoading} {...(isEmpty && { emptyListProps })}>
                                    <Grid
                                        container
                                        item
                                        justifyContent="space-between"
                                        wrap="nowrap"
                                        spacing={2}
                                        pb={paddingListCategories}
                                    >
                                        <Grid item>{/*<CampaignListFilters />*/}</Grid>
                                        <Grid item>
                                            <TotToggleGridButtons
                                                selectedGrid={selectedGrid}
                                                setSelectedGrid={setSelectedGrid}
                                            />
                                        </Grid>
                                    </Grid>

                                    {selectedGrid === ListView.grid ? (
                                        <CampaignListGrid model={model} />
                                    ) : (
                                        <CampaignListRegistry />
                                    )}
                                </List>
                            </Grid>
                        );
                    }}
                </AuthorizationCheck>
            </Box>
        </React.Fragment>
    );
});
