import { DraggableList, EmptyList } from '@platform/front-core';
import { VoidFunction } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { useParams } from 'react-router-dom';
import { useCampaignSettingsListContext, useStore } from '../../../../../hooks';
import { CampaignRequestFormRouteParams } from '../../../../../types';
import { renderSectionSettingsBody as renderSectionsBodyInj } from './renderSectionSettingsBody';
import { renderSectionSettingsHead as renderSectionsHeadInj } from './renderSectionSettingsHead';

export type CampaignSettingsSectionSettingsListProps = {
    createItem: VoidFunction;
};

export const CampaignSettingsSectionSettingsList = observer(
    (props: CampaignSettingsSectionSettingsListProps): JSX.Element => {
        const [renderSectionsBody] = di([renderSectionsBodyInj], CampaignSettingsSectionSettingsList);
        const [renderSectionsHead] = di([renderSectionsHeadInj], CampaignSettingsSectionSettingsList);

        const { createItem } = props;
        const { shouldLoadListItems, setShouldntLoadListItems } = useCampaignSettingsListContext();

        const { campaignsStore } = useStore();
        const { loadSectionSettings, changeSectionSettingPosition } = campaignsStore;
        const intl = useIntl();

        const { rfId } = useParams<CampaignRequestFormRouteParams>();

        const loadListItems = useCallback(() => loadSectionSettings(rfId), [rfId]);

        const createButtonProps = {
            title: intl.formatMessage({ id: 'campaignSettings.addSectionButton' }),
            createItem,
        };

        const renderDraggableListEmpty = (): JSX.Element => (
            <EmptyList
                noItemsTitle={intl.formatMessage({ id: 'campaignSettings.noSections' })}
                createButtonProps={createButtonProps}
            />
        );

        return (
            <DraggableList
                loadListItems={loadListItems}
                changeItemPosition={changeSectionSettingPosition}
                renderDraggableListBody={renderSectionsBody}
                renderDraggableListHead={renderSectionsHead}
                renderDraggableListEmpty={renderDraggableListEmpty}
                setShouldntLoadListItems={setShouldntLoadListItems}
                shouldLoadListItems={shouldLoadListItems}
            />
        );
    },
);
