import { Grid, SxProps, Theme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Redirect, Route, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../../clientRoute';
import { useCampaignPageContext, useCampaignSettingsPageContext, useStore } from '../../../../../../hooks';
import { CampaignRequestFormSectionSettingsRouteParams } from '../../../../../../types';
import { CampaignSettingsSectionSettings as CampaignSettingsSectionSettingsInj } from '../../section-settings';
import { CampaignSettingsRequestForm as CampaignSettingsRequestFormInj } from './CampaignSettingsRequestForm';
import { CampaignSettingsRequestFormHeader as CampaignSettingsRequestFormHeaderInj } from './CampaignSettingsRequestFormHeader';
import { CampaignSettingsRequestFormTabs as CampaignSettingsRequestFormTabsInj } from './CampaignSettingsRequestFormTabs';

const campaignSettingsRequestFormWrapperSx: SxProps<Theme> = {
    height: '100%',
};

export const CampaignSettingsRequestFormRoutes = observer((): JSX.Element => {
    const [CampaignSettingsSectionSettings] = di(
        [CampaignSettingsSectionSettingsInj],
        CampaignSettingsRequestFormRoutes,
    );
    const [CampaignSettingsRequestForm] = di([CampaignSettingsRequestFormInj], CampaignSettingsRequestFormRoutes);
    const [CampaignSettingsRequestFormHeader] = di(
        [CampaignSettingsRequestFormHeaderInj],
        CampaignSettingsRequestFormRoutes,
    );
    const [CampaignSettingsRequestFormTabs] = di(
        [CampaignSettingsRequestFormTabsInj],
        CampaignSettingsRequestFormRoutes,
    );

    const params = useParams<CampaignRequestFormSectionSettingsRouteParams>();
    const { rfId } = params;

    const { campaignsStore } = useStore();
    const { loadRequestFormInfo } = campaignsStore;
    const intl = useIntl();

    const { isCampaignSettingsRequestFormEditingForm } = useCampaignSettingsPageContext();
    const { requestFormModel } = useCampaignPageContext();

    const isFormView = !isCampaignSettingsRequestFormEditingForm;

    useEffect(() => {
        loadRequestFormInfo(rfId).then((dto) => requestFormModel.load(dto));
    }, [intl.locale, rfId]);

    return (
        <Grid container direction="column" wrap="nowrap" sx={campaignSettingsRequestFormWrapperSx}>
            <Route path={clientRoute.campaignSettingsRequestForm} exact>
                <Redirect to={generatePath(clientRoute.campaignSettingsRequestFormInfo, params)} />
            </Route>
            <Route path={clientRoute.campaignSettingsRequestFormSectionsOld} exact>
                <Redirect to={generatePath(clientRoute.campaignSettingsRequestFormSectionSettings, params)} />
            </Route>
            <Route path={clientRoute.campaignSettingsRequestFormSectionCreateOld} exact>
                <Redirect to={generatePath(clientRoute.campaignSettingsRequestFormSectionSettingsCreate, params)} />
            </Route>
            <Route
                path={[
                    clientRoute.campaignSettingsRequestFormInfo,
                    clientRoute.campaignSettingsRequestFormCreate,
                    clientRoute.campaignSettingsRequestFormEdit,
                    clientRoute.campaignSettingsRequestFormSectionSettings,
                    clientRoute.campaignSettingsRequestFormSectionSettingsCreate,
                    clientRoute.campaignSettingsRequestFormSectionSettingsEdit,
                    clientRoute.campaignSettingsRequestFormRequestPermissions,
                    clientRoute.campaignSettingsRequestFormAddRequestPermission,
                ]}
                exact
            >
                <CampaignSettingsRequestFormHeader requestForm={requestFormModel} isFormView={isFormView} />
                {isFormView && <CampaignSettingsRequestFormTabs />}
            </Route>
            <Route
                path={[
                    clientRoute.campaignSettingsRequestFormInfo,
                    clientRoute.campaignSettingsRequestFormCreate,
                    clientRoute.campaignSettingsRequestFormEdit,
                ]}
            >
                <CampaignSettingsRequestForm />
            </Route>
            <Route
                path={[
                    clientRoute.campaignSettingsRequestFormSectionSettings,
                    clientRoute.campaignSettingsRequestFormSectionEditOld,
                ]}
            >
                <Grid container item flexDirection="column" justifyContent="start" flexGrow={1}>
                    <CampaignSettingsSectionSettings />
                </Grid>
            </Route>
        </Grid>
    );
});
