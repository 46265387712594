import { TableCell } from '@mui/material';
import {
    DynamicEllipsisBox,
    DynamicEllipsisBoxProps,
    FileDownload,
    FileDownloadProps,
    RequiredLabel,
    SxStyle,
} from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { useCampaignRequestPrintFormContext } from '../../../../../../../../hooks';

const fileNameEllipsisProps: FileDownloadProps['nameEllipsisProps'] = {
    startTimeout: 500,
};

const pfWithoutGeneratedFileSx: Partial<DynamicEllipsisBoxProps> = {
    maxRows: 1,
    isWithTooltip: true,
};

const fileCellSx: SxStyle = {
    maxWidth: '180px',
};

export const CampaignRequestPrintFormsRowFileCell = observer((): JSX.Element => {
    const { model } = useCampaignRequestPrintFormContext();
    const { filename, pf, downloadPrintForm, required } = model;

    const pfWithoutGeneratedFileEllipsisBoxProps: DynamicEllipsisBoxProps = {
        ...pfWithoutGeneratedFileSx,
        ...fileNameEllipsisProps,
        text: filename,
    };

    const file: ReactNode = pf ? (
        <FileDownload
            fileName={pf.filename}
            downloadFile={downloadPrintForm}
            nameEllipsisProps={fileNameEllipsisProps}
        />
    ) : (
        <DynamicEllipsisBox {...pfWithoutGeneratedFileEllipsisBoxProps} />
    );

    const fileName = required ? <RequiredLabel text={file} /> : file;

    return <TableCell sx={fileCellSx}>{fileName}</TableCell>;
});
