import { Button, Grid, SxProps, Theme } from '@mui/material';
import { useFlag } from '@platform/front-utils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { di } from 'react-magnetic-di';
import { generatePath, Route, useHistory, useParams } from 'react-router-dom';
import { clientRoute } from '../../../../../clientRoute';
import { CampaignSettingsListContext } from '../../../../../contexts';
import { CampaignRequestFormSectionSettingsRouteParams } from '../../../../../types';
import { CampaignSettingsBodyHeader as CampaignSettingsBodyHeaderInj } from '../CampaignSettingsBodyHeader';
import { CampaignSettingsSectionSettingsList as CampaignSettingsSectionsListInj } from './CampaignSettingsSectionSettingsList';
import { CampaignSettingsSectionSettingRoutes as CampaignSettingsSectionRoutesInj } from './section-setting';

const headerTitleSx: SxProps<Theme> = {
    fontSize: '0.875rem',
};

export const CampaignSettingsSectionSettings = observer((): JSX.Element => {
    const [CampaignSettingsBodyHeader] = di([CampaignSettingsBodyHeaderInj], CampaignSettingsSectionSettings);
    const [CampaignSettingsSectionsList] = di([CampaignSettingsSectionsListInj], CampaignSettingsSectionSettings);
    const [CampaignSettingsSectionRoutes] = di([CampaignSettingsSectionRoutesInj], CampaignSettingsSectionSettings);

    const params = useParams<CampaignRequestFormSectionSettingsRouteParams>();
    const history = useHistory();

    const createItem = (): void => {
        history.push(generatePath(clientRoute.campaignSettingsRequestFormSectionSettingsCreate, params));
    };

    const renderButtons = (): JSX.Element => {
        const actionButtons = [
            <Grid key="create-section" item>
                <Button color="secondary" onClick={createItem}>
                    <FormattedMessage id="campaignSettings.createSection" />
                </Button>
            </Grid>,
        ];
        return <React.Fragment>{actionButtons}</React.Fragment>;
    };

    const [shouldLoadListItems, setShouldLoadListItems, setShouldntLoadListItems] = useFlag(false);

    return (
        <CampaignSettingsListContext.Provider
            value={{ shouldLoadListItems, setShouldLoadListItems, setShouldntLoadListItems }}
        >
            <Route
                path={[
                    clientRoute.campaignSettingsRequestFormSectionSettings,
                    clientRoute.campaignSettingsRequestFormSectionSettingsCreate,
                    clientRoute.campaignSettingsRequestFormSectionSettingsEdit,
                ]}
                exact
            >
                <Grid container direction="column" flexGrow={1} wrap="nowrap">
                    <Grid item>
                        <CampaignSettingsBodyHeader
                            titleId="campaignSettings.sectionsTitle"
                            titleSx={headerTitleSx}
                            renderButtons={renderButtons}
                        />
                    </Grid>
                    <Grid item container flexGrow={1}>
                        <CampaignSettingsSectionsList createItem={createItem} />
                    </Grid>
                </Grid>
            </Route>
            <Route
                path={[
                    clientRoute.campaignSettingsRequestFormSectionSettingsCreate,
                    clientRoute.campaignSettingsRequestFormSectionSettingsEdit,
                    clientRoute.campaignSettingsRequestFormSectionSettingEdit,
                    clientRoute.campaignSettingsRequestFormSectionEditOld,
                    clientRoute.campaignSettingsRequestFormSectionSetting,
                    clientRoute.campaignSettingsRequestFormSectionSettingInfo,
                    clientRoute.campaignSettingsRequestFormSectionSettingPF,
                ]}
            >
                <CampaignSettingsSectionRoutes />
            </Route>
        </CampaignSettingsListContext.Provider>
    );
});
