import { FormDTO, FullSubmission } from '@platform/formiojs-react';
import {
    ApiStore,
    FileDTO,
    NotificationStore,
    TransitionDTO,
    AccessPermissionSelects,
    DefaultNewPermissionDTO,
} from '@platform/front-core';
import { CodeTitle, IdTitle } from '@platform/front-utils';
import { AxiosPromise } from 'axios';
import { History } from 'history';
import downloadFile from 'js-file-download';
import { action, computed, makeObservable, observable, when } from 'mobx';
import { di } from 'react-magnetic-di';
import { generatePath } from 'react-router-dom';
import { apiConfigs } from '../apiConfigs';
import { clientRoute } from '../clientRoute';
import { CampaignRequestFormListModel } from '../models';
import {
    CampaignInfoDTO,
    CampaignRequestFormDTO,
    CampaignRequestFormListMemberDTO,
    CampaignRequestFormRouteParams,
    CampaignRequestFormSectionSettingsRouteParams,
    NewPermissionDTO,
    PermissionSettingsDTO,
    PermissionsType,
    RequestSectionSettingsListDTO,
    SectionSettingDTO,
    TemplateDTO,
    TemplateListDTO,
    UserRequestsResponse,
} from '../types';
import { RootStore } from './RootStore';

export const CampaignsStoreProps = {
    rootStore: observable,
    api: observable,
    campaignRequestFormListModel: observable,
    history: computed,
    notificationStore: computed,

    createCampaign: action.bound,
    loadCampaign: action.bound,
    loadCampaignForm: action.bound,
    updateCampaignForm: action.bound,
    downloadRequestFormFile: action.bound,
    deleteCampaign: action.bound,

    downloadFile: action.bound,

    loadRequestForms: action.bound,
    createRequestForm: action.bound,
    loadRequestFormInfo: action.bound,
    loadRequestForm: action.bound,
    updateRequestForm: action.bound,
    changeRequestFormPosition: action.bound,
    deleteCampaignRequestForm: action.bound,

    loadSectionSettings: action.bound,
    createSectionSetting: action.bound,
    loadSectionSetting: action.bound,
    updateSectionSetting: action.bound,
    changeSectionSettingPosition: action.bound,
    deleteSectionSetting: action.bound,

    loadExecutors: action.bound,
    loadParentSectionSetting: action.bound,
    loadFormCode: action.bound,

    loadSectionTemplates: action.bound,
    loadTemplate: action.bound,
    createTemplate: action.bound,
    updateTemplate: action.bound,
    deletePF: action.bound,
    changeTemplatePosition: action.bound,
    downloadTemplateFile: action.bound,
    createRequest: action.bound,
    loadUserRequests: action.bound,

    getCampaignRequestForms: action.bound,
    getCampaignRequestFormListModel: action.bound,
    setCampaignRequestFormListModel: action.bound,
    dropCampaignRequestFormListModel: action.bound,

    getTransitions: action.bound,
    transitionToNextLifeCycleStep: action.bound,

    getCommentPermissions: action.bound,
    updateCommentPermissions: action.bound,
};

export class CampaignsStore {
    rootStore: RootStore;
    api: ApiStore;
    campaignRequestFormListModel?: CampaignRequestFormListModel;

    constructor(rootStore: RootStore) {
        makeObservable(this, CampaignsStoreProps);
        this.rootStore = rootStore;
        this.api = rootStore.coreRootStore.api;
    }

    get history(): History {
        return this.rootStore.history;
    }

    get notificationStore(): NotificationStore {
        return this.rootStore.coreRootStore.notificationStore;
    }

    loadCampaign(id: string): Promise<CampaignInfoDTO> {
        return this.api.mainInfoClient(apiConfigs.campaign(id)).then((r) => r.data);
    }

    createCampaign(lifecycleCode: string): Promise<string> {
        return this.api.userActionClient(apiConfigs.createCampaign(lifecycleCode)).then((r) => r.data.id);
    }

    downloadRequestFormFile(file: FileDTO): Promise<void> {
        return this.api
            .client(apiConfigs.requestFormFile(file.id))
            .then((r) => r.data)
            .then((f) => downloadFile(f, file.filename, file.mimeType));
    }

    //
    // loadFile(id: string): Promise<string> {
    //     return this.api.client(apiConfigs.campaignFile(id)).then((r) => r.data);
    // }
    //
    //
    loadCampaignForm(id: string): Promise<FormDTO> {
        return this.api.mainInfoClient(apiConfigs.campaignForm(id)).then((r) => r.data);
    }

    updateCampaignForm(id: string, submission: FullSubmission): Promise<void> {
        return this.api.userActionClient(apiConfigs.editCampaign(id, submission)).then((r) => r.data);
    }

    downloadFile(file: FileDTO): Promise<void> {
        return this.api
            .client(apiConfigs.campaignFile(file.id))
            .then((r) => r.data)
            .then((f) => downloadFile(f, file.filename, file.mimeType));
    }

    //
    // downloadRequestFormFile(file: FileModel): Promise<void> {
    //     return this.api
    //         .client(apiConfigs.requestFormFile(file.fileId))
    //         .then((r) => r.data)
    //         .then((f) => downloadFile(f, file.filename, file.mimeType));
    // }
    //
    createRequestForm(campaignId: string): Promise<string> {
        return this.api.userActionClient(apiConfigs.createRequestForm(campaignId)).then((r) => r.data.id);
    }

    loadRequestForms(campaignId: string, minimal = false, edit?: boolean): Promise<CampaignRequestFormDTO[]> {
        return this.api.mainInfoClient(apiConfigs.requestForms(campaignId, minimal, edit)).then((r) => r.data);
    }

    changeRequestFormPosition(id: string, newPosition: number): AxiosPromise<void> {
        return this.api.userActionClient(apiConfigs.changeRequestFormsPosition(id, newPosition));
    }

    // requestFormsByCampaignsForSelect(campaigns: string[] = []): Promise<GroupedIdTitle> {
    //     return this.api.client(apiConfigs.requestFormsByCampaignsForSelect(campaigns)).then((r) => r.data);
    // }
    //
    loadRequestFormInfo(id: string): Promise<CampaignRequestFormDTO> {
        return this.api.mainInfoClient(apiConfigs.requestFormCard(id)).then((r) => r.data);
    }

    loadRequestForm(id: string): Promise<FormDTO> {
        return this.api.mainInfoClient(apiConfigs.requestForm(id)).then((r) => r.data);
    }

    updateRequestForm(id: string, submission: FullSubmission): AxiosPromise<void> {
        return this.api.userActionClient(apiConfigs.editRequestForm(id, submission)).then((r) => r.data);
    }

    deleteCampaignRequestForm(params: CampaignRequestFormRouteParams): Promise<void> {
        const { id, rfId } = params;
        return this.api.userActionClient(apiConfigs.deleteRequestForm(rfId)).then(() => {
            this.history.push(generatePath(clientRoute.campaignSettingsRequestForms, { id }));
        });
    }

    deleteCampaign(id: string): AxiosPromise<void> {
        return this.api.userActionClient(apiConfigs.deleteCampaign(id));
    }

    //
    // getRequestFormModel(id: string): FormModel {
    //     const model = new FormModel(id);
    //     this.loadRequestForm(id).then(model.load);
    //     return model;
    // }
    //
    // getRequestFormInfoModel(id: string): CampaignRequestFormInfoModel {
    //     const model = new CampaignRequestFormInfoModel(id);
    //     this.loadRequestFormInfo(id).then(model.load);
    //     return model;
    // }
    //
    loadSectionTemplates(sectionSettingsId: string): Promise<TemplateListDTO[]> {
        return this.api.mainInfoClient(apiConfigs.sectionTemplates(sectionSettingsId)).then((r) => r.data);
    }

    loadTemplate(id: string): Promise<TemplateDTO> {
        return this.api.mainInfoClient(apiConfigs.template(id)).then((r) => r.data);
    }

    createTemplate(sectionSettingId: string, formData: FormData): Promise<string> {
        return this.api.userActionClient(apiConfigs.createTemplate(sectionSettingId, formData)).then((r) => r.data);
    }

    updateTemplate(sectionSettingId: string, pfId: string, formData: FormData): Promise<void> {
        return this.api.userActionClient(apiConfigs.saveTemplate(sectionSettingId, pfId, formData)).then((r) => r.data);
    }

    deletePF(id: string): Promise<void> {
        return this.api.userActionClient(apiConfigs.deleteTemplate(id)).then((r) => r.data);
    }

    changeTemplatePosition(pfId: string, newPosition: number): AxiosPromise<void> {
        return this.api.userActionClient(apiConfigs.changeTemplatePosition(pfId, newPosition));
    }

    downloadTemplateFile(fileId: string): Promise<Blob> {
        return this.api.client(apiConfigs.downloadTemplateFile(fileId)).then((r) => r.data);
    }

    // getActualCampaignList(): Promise<IdTitle[]> {
    //     return this.api.client(apiConfigs.getActualCampaignList).then((r) => r.data);
    // }

    getTransitions(requestId: string): Promise<TransitionDTO[]> {
        return this.api.client(apiConfigs.transitionCampaign(requestId)).then((r) => r.data);
    }

    transitionToNextLifeCycleStep(transitionId: string, requestId: string): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.transitionToNextLifeCycleStepCampaign(transitionId, requestId))
            .then((r) => r.data);
    }

    // getCampaignDTO(campaignId: string): Promise<CampaignInfoDTO> {
    //     return this.api
    //         .mainInfoClient(apiConfigs.campaign(campaignId))
    //         .then((r) => r.data);
    // }
    //
    // getFormForGeneralSettings(campaignId: string): FormModel {
    //     const model = new FormModel(campaignId);
    //     this.loadFormForGeneralSettings(campaignId).then(model.load);
    //     return model;
    // }
    //
    // loadFormForGeneralSettings(campaignId: string): Promise<FormDTO> {
    //     return this.api
    //         .client(apiConfigs.getFormForGeneralSettings(campaignId))
    //         .then((r) => r.data)
    //         .catch(
    //             handleAxiosErrorByResponseStatus({
    //                 403: () => this.history.replace(clientRoute.notAllowed),
    //                 404: () => this.history.replace(clientRoute.notFound),
    //             }),
    //         );
    // }
    //
    // saveFormForGeneralSettings(id: string, submission: FullSubmission): AxiosPromise<void> {
    //     return this.api.client(apiConfigs.saveFormForGeneralSettings(id, submission));
    // }
    //

    loadSectionSettings(id: string): Promise<RequestSectionSettingsListDTO[]> {
        return this.api.mainInfoClient(apiConfigs.sectionSettings(id)).then((r) => r.data);
    }

    createSectionSetting(id: string, dto: SectionSettingDTO): Promise<string> {
        return this.api.userActionClient(apiConfigs.createSectionSetting(id, dto)).then((r) => r.data.id);
    }

    loadSectionSetting(id: string): Promise<SectionSettingDTO> {
        return this.api.mainInfoClient(apiConfigs.loadSectionSetting(id)).then((r) => r.data);
    }

    updateSectionSetting(id: string, dto: SectionSettingDTO): Promise<void | number> {
        return this.api.userActionClient(apiConfigs.saveSectionSetting(id, dto)).then((r) => r.data);
    }

    deleteSectionSetting(params: CampaignRequestFormSectionSettingsRouteParams): Promise<void> {
        const { sectionSettingId } = params;
        return this.api.userActionClient(apiConfigs.deleteSectionSetting(sectionSettingId)).then(() => {
            this.history.push(generatePath(clientRoute.campaignSettingsRequestFormSectionSettings, params));
        });
    }

    changeSectionSettingPosition(id: string, position: number): AxiosPromise<void> {
        return this.api.userActionClient(apiConfigs.changePositionSectionSetting(id, position)).then((r) => r.data);
    }

    loadExecutors(): Promise<IdTitle[]> {
        return this.api.client(apiConfigs.loadExecutors()).then((r) => r.data);
    }

    loadParentSectionSetting(requestFormId: string, sectionId: string): Promise<IdTitle[]> {
        return this.api.client(apiConfigs.loadParentSectionSetting(requestFormId, sectionId)).then((r) => r.data);
    }

    loadFormCode(): Promise<CodeTitle[]> {
        return this.api.client(apiConfigs.loadFormCode()).then((r) => r.data);
    }

    createRequest(selectedRequestFormId: string): Promise<void> {
        const { requestStore } = this.rootStore;
        return requestStore.createRequest(selectedRequestFormId).then((requestId: string) => {
            this.history.push(generatePath(clientRoute.requestEdit, { id: requestId }));
        });
    }

    loadAccessPermissions(settingsId: string, permissionsType: PermissionsType): Promise<PermissionSettingsDTO> {
        return this.api
            .mainInfoClient(apiConfigs.loadAccessPermissions(settingsId, permissionsType))
            .then((r) => r.data);
    }

    saveAccessPermissions(
        settingsId: string,
        permissions: NewPermissionDTO[],
        permissionsType: PermissionsType,
    ): Promise<void> {
        return this.api
            .userActionClient(apiConfigs.saveAccessPermissions(settingsId, permissions, permissionsType))
            .then((r) => r.data);
    }

    loadUserRequests(id: string, page: number): Promise<UserRequestsResponse> {
        return this.api.mainInfoClient(apiConfigs.campaignUserRequests(id, page)).then((r) => r.data);
    }

    getCampaignRequestForms(campaignId: string): Promise<CampaignRequestFormListMemberDTO[]> {
        return this.api.client(apiConfigs.requestForms(campaignId, false)).then((r) => r.data);
    }

    getCampaignRequestFormListModel(): Promise<CampaignRequestFormListModel> {
        return new Promise(async (res) => {
            await when(() => !!this.campaignRequestFormListModel && !this.campaignRequestFormListModel.isLoading);
            if (this.campaignRequestFormListModel) {
                res(this.campaignRequestFormListModel);
            }
        });
    }

    setCampaignRequestFormListModel(campaignId: string): void {
        if (this.campaignRequestFormListModel?.campaignId !== campaignId) {
            this.campaignRequestFormListModel = new CampaignRequestFormListModel(this, campaignId);
        }
    }

    dropCampaignRequestFormListModel(): void {
        this.campaignRequestFormListModel = undefined;
    }

    getCommentPermissions(commentPermissionId: string): Promise<PermissionSettingsDTO> {
        return this.api.client(apiConfigs.commentPermissions(commentPermissionId)).then((r) => r.data);
    }

    async updateCommentPermissions(commentPermissionId: string, permissions: NewPermissionDTO[]): Promise<void> {
        await this.api.userActionClient(apiConfigs.updateCommentsPermissions(commentPermissionId, permissions));
    }

    requestPermissions(requestFormId: string): Promise<PermissionSettingsDTO> {
        return this.api.client(apiConfigs.loadRequestPermissions(requestFormId)).then((r) => r.data);
    }

    requestPermissionsSelectData(requestFormId: string): Promise<AccessPermissionSelects> {
        return this.api.client(apiConfigs.loadRequestPermissionsSelectData(requestFormId)).then((r) => r.data);
    }

    async updateRequestPermissions(requestFormId: string, permissions: DefaultNewPermissionDTO[]): Promise<void> {
        await this.api.userActionClient(apiConfigs.updateRequestPermissions(requestFormId, permissions));
    }
}

export const getCampaignsStore = (): any => {
    const [_CampaignsStore] = di([CampaignsStore], getCampaignsStore);
    return _CampaignsStore;
};
