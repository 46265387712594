import { Features } from '../../types';

const features: Features = {
    loginWithoutRegister: false,
    headerLogo: false,
    footerLogo: false,
    allUsersRedirectPath: false,
    search: false,
    customLogo: {
        isEnabled: true,
        src: require('./tszd-logo.svg'),
        // locales: {
        //     ru: {
        //         src: require('./tszd-logo.svg'),
        //         alt: 'Растим детей',
        //     },
        // },
        style: { minHeight: 70 },
        withText: false,
        mdLogo: false,
    },
    customBackground: {
        isEnabled: true,
        src: require('./tszd-background.jpg'),
    },
    helpIcon: false,
    notificationIcon: true,
    // changeLanguage: true,
    phoneNumber: false,
    esiaLogin: false,
    showCreateRequestButton: true,
    showRequestsCount: true,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: true,
    samlAuth: false,
    showCampaignPeriod: true,
    isWithRegistrationPage: true,
};

export default features;
