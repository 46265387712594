// import { SignatureSettings } from '@platform/crypto-ui';
import { CatalogStore, IntlStore, useYup } from '@platform/front-core';
import { IdTitle } from '@platform/front-utils';
import { action, makeObservable, observable } from 'mobx';
import { apiConfigs } from '../apiConfigs';
import { CatalogCodes } from '../constants';
import { CampaignsStore, RootStore } from '../stores';
import { TemplateDTO, TemplateFormFields, TemplateFormValues } from '../types';
import { createDropzoneFileInstance } from '../utils';

export const CampaignPfTemplateModelProps = {
    intlStore: observable,
    catalogStore: observable,
    campaignsStore: observable,

    id: observable,
    sectionSettingId: observable,
    templateDTO: observable,
    isTemplateDTOLoaded: observable,
    isTemplateDataLoaded: observable,
    formInitialValues: observable,
    docCategories: observable,
    fileFormats: observable,

    loadTemplateDTO: action.bound,
    loadTemplateData: action.bound,
    loadDocCategories: action.bound,
    loadFileFormats: action.bound,
    setInitialFormValues: action.bound,
    getFormValidationSchema: action.bound,
    getFieldLabel: action.bound,
    getFieldPlaceholder: action.bound,
    getFormData: action.bound,
    setTemplate: action.bound,
    setTemplateDTOLoadedTrue: action.bound,
};

export const getTemplateFormInitialValues = (): TemplateFormValues => ({
    title: '',
    description: '',
    docCategory: null,
    fileFormat: '',
    required: false,
    file: [],
    signatureSettings: {
        signatureEnabled: false,
        signatureRequired: false,
        signatureUpload: false,
        signatureBrowserPlugin: false,
    },
});

export class CampaignPfTemplateModel {
    intlStore: IntlStore;
    catalogStore: CatalogStore;
    campaignsStore: CampaignsStore;

    id = '';
    sectionSettingId = '';
    templateDTO: TemplateDTO = {} as TemplateDTO;
    isTemplateDTOLoaded = false;
    isTemplateDataLoaded = false;
    formInitialValues: TemplateFormValues = observable.object(getTemplateFormInitialValues());

    docCategories: IdTitle[] = [];
    fileFormats: IdTitle[] = [];

    titleMaxLength = 100;
    acceptedFiles = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    constructor(id: string, sectionSettingId: string, rootStore: RootStore, isCreatePage: boolean) {
        makeObservable(this, CampaignPfTemplateModelProps);
        this.intlStore = rootStore.coreRootStore.intlStore;
        this.catalogStore = rootStore.coreRootStore.catalogStore;
        this.campaignsStore = rootStore.campaignsStore;
        this.id = id;
        this.sectionSettingId = sectionSettingId;
        if (!isCreatePage) {
            this.loadTemplateDTO();
        } else {
            this.setTemplateDTOLoadedTrue();
        }
    }

    loadTemplateDTO(): void {
        this.campaignsStore.loadTemplate(this.id).then(this.setTemplate).finally(this.setTemplateDTOLoadedTrue);
    }

    async loadTemplateData(): Promise<void> {
        this.docCategories = await this.loadDocCategories();
        this.fileFormats = await this.loadFileFormats();
        this.isTemplateDataLoaded = true;
    }

    loadDocCategories(): Promise<IdTitle[]> {
        return this.catalogStore.getSelectData(
            apiConfigs.catalogItems(CatalogCodes.documentCategory, this.intlStore.locale),
        );
    }

    loadFileFormats(): Promise<IdTitle[]> {
        return this.catalogStore.getSelectData(apiConfigs.catalogItems(CatalogCodes.fileFormat, this.intlStore.locale));
    }

    async setInitialFormValues(): Promise<void> {
        if (!this.isTemplateDataLoaded) {
            await this.loadTemplateData();
        }

        const { author, fileDTO, resultFileFormat, signatureSettings, docCategory, ...restDTO } = this.templateDTO;
        const initialValues = getTemplateFormInitialValues();
        const fileFormat = resultFileFormat || this.fileFormats[0].id;
        const docCategoryObject = this.docCategories.find((category) => category.id === docCategory);
        const currentInitialValues: TemplateFormValues = {
            ...initialValues,
            ...restDTO,
            signatureSettings: {
                ...initialValues.signatureSettings,
                ...signatureSettings,
            },
            fileFormat,
            file: fileDTO ? [createDropzoneFileInstance(fileDTO)] : [],
        };
        docCategoryObject && (currentInitialValues.docCategory = docCategoryObject);

        this.formInitialValues = observable.object(currentInitialValues);
    }

    getFormValidationSchema() {
        const { Yup } = useYup();
        return Yup.object({
            title: Yup.string()
                .required()
                .max(
                    this.titleMaxLength,
                    this.intlStore.intl.formatMessage({ id: 'validation.maxLength' }, { length: this.titleMaxLength }),
                ),
            description: Yup.string(),
            docCategory: Yup.object().nullable(),
            fileFormat: Yup.string().required(),
            required: Yup.boolean(),
            file: Yup.array().min(1, this.intlStore.intl.formatMessage({ id: 'validation.required' })),
            signatureSettings: Yup.object().shape({
                signatureEnabled: Yup.boolean(),
                signatureRequired: Yup.boolean(),
                signatureBrowserPlugin: Yup.boolean(),
                signatureUpload: Yup.boolean().when('signatureEnabled', {
                    is: true,
                    then: Yup.boolean().when('signatureBrowserPlugin', {
                        is: false,
                        then: Yup.boolean().oneOf(
                            [true],
                            this.intlStore.intl.formatMessage({ id: 'validation.withoutSignatureMethod' }),
                        ),
                    }),
                }),
            }),
        });
    }

    getFieldLabel(fieldName: TemplateFormFields): string {
        return this.intlStore.intl.formatMessage({ id: `campaignSettings.templateForm.${fieldName}.label` });
    }

    getFieldPlaceholder(fieldName: TemplateFormFields): string {
        return this.intlStore.intl.formatMessage({ id: `campaignSettings.templateForm.${fieldName}.placeholder` });
    }

    getFormData(values: TemplateFormValues): FormData {
        const { title, description, docCategory, fileFormat, required, file, signatureSettings } = values;
        const { signatureEnabled, signatureUpload, signatureRequired, signatureBrowserPlugin } = signatureSettings;
        const data = new FormData();

        data.append('title', title);
        data.append('description', description);
        docCategory && data.append('docCategory', docCategory.id);
        data.append('fileFormat', fileFormat);
        data.append('required', required.toString());
        data.append('signatureEnabled', signatureEnabled.toString());
        data.append('signatureUpload', signatureUpload.toString());
        data.append('signatureRequired', signatureRequired.toString());
        data.append('signatureBrowserPlugin', signatureBrowserPlugin.toString());
        data.append('file', file[0]);
        return data;
    }

    setTemplate(dto: TemplateDTO): void {
        this.templateDTO = dto;
    }

    setTemplateDTOLoadedTrue(): void {
        this.isTemplateDTOLoaded = true;
    }
}
