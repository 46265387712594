import { Features } from '../../types';
import rkkLogo from './rkk-logo.svg';

const logoStyle = { width: '131px', height: '32px' };

const features: Features = {
    search: false,
    loginWithoutRegister: false,
    customLogo: {
        isEnabled: true,
        src: '',
    },
    headerLogo: {
        isEnabled: true,
        locales: {
            ru: {
                image: rkkLogo,
                alt: 'РКК',
                style: logoStyle,
            },
            en: {
                image: rkkLogo,
                alt: 'RRC',
                style: logoStyle,
            },
        },
    },
    customBackground: false,
    helpIcon: true,
    notificationIcon: true,
    phoneNumber: true,
    esiaLogin: true,
    showCreateRequestButton: true,
    showRequestsCount: true,
    loginDialogAlert: false,
    yaMetrika: false,
    modularNavigation: true,
    footerLogo: false,
    samlAuth: false,
    showCampaignPeriod: true,
    allUsersRedirectPath: false,
    isWithRegistrationPage: false,
    isWithoutHelpIconAuthPage: true,
};

export default features;
