import React from 'react';
import { useTheme } from '@mui/material';
import { StyledForm } from '../styled/Form.styled';
import { flatpickrGlobalStyles } from '../styled/global-components/flatpickr';
import { formioModalGlobalStyles } from '../styled/global-components/formioModal';
export var FormWithTheme = function (props) {
    var children = props.children;
    var theme = useTheme();
    var appTheme = theme;
    return (React.createElement(StyledForm, { theme: appTheme },
        flatpickrGlobalStyles(appTheme),
        formioModalGlobalStyles(appTheme),
        children));
};
