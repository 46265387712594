import { AuthorizationCheckQuery } from '@platform/front-core';
import { VoidFunction } from '@platform/front-utils';
import { useEffect, useState } from 'react';
import { useStore } from './index';

export type UseFetchAllPermissionsProps = {
    allPermissionsQueries: AuthorizationCheckQuery[];
};

export type UseFetchAllPermissionsReturns = [boolean[], VoidFunction];

export const useFetchAllPermissions = (props: UseFetchAllPermissionsProps): UseFetchAllPermissionsReturns => {
    const { allPermissionsQueries } = props;

    const [permissionsData, setPermissionsData] = useState<boolean[]>([]);

    const { coreRootStore } = useStore();
    const { authorizationStore } = coreRootStore;

    useEffect(() => {
        authorizationStore.checkAll(allPermissionsQueries).then(setPermissionsData);
    }, [allPermissionsQueries]);

    const reloadPermission = (): void => {
        authorizationStore.checkAll(allPermissionsQueries).then(setPermissionsData);
    };

    return [permissionsData, reloadPermission];
};
