import { CKEditorToolbarConfigDefault } from '@platform/comments';
import { IntlStore, useYup } from '@platform/front-core';
import { CodeTitle, CodeTitleNull, IdTitle, IdTitleNull } from '@platform/front-utils';
import { action, computed, makeObservable, observable } from 'mobx';
import { CampaignsStore, RootStore } from '../stores';
import { RequestFormSectionSettingFormFields, RequestFormSectionSettingFormValues, SectionSettingDTO } from '../types';

const bannedSymbols = /^[^\/\\:*?<>|]+$/g;
const commentMaxLength = 1000;
const commentMinLength = 1;

export const getSectionSettingFormInitialValues = (): RequestFormSectionSettingFormValues => ({
    title: '',
    identifier: '',
    form: null,
    executor: null,
    parentSectionSetting: null,
    openOnCreation: false,
    showComments: false,
    commentAttributes: {
        editable: false,
        deletable: false,
        commentingAllowed: false,
        threadsAllowed: false,
        textEditorSettings: CKEditorToolbarConfigDefault,
        maxCommentLength: commentMaxLength,
    },
});

export const RequestFormSectionSettingModelProps = {
    campaignsStore: observable,
    intlStore: observable,

    rfId: observable,
    id: observable,
    sectionSettingFormInitialValues: observable,
    sectionSettingDTO: observable,
    isSectionSettingDTOLoaded: observable,

    isSectionSettingDataLoaded: observable,
    formCodeList: observable,

    parentSectionSettingList: observable,
    executorsList: observable,

    loadSectionSettingDTO: action.bound,
    loadSectionSettingData: action.bound,
    setDTO: action.bound,
    setSectionSettingDTOLoadedTrue: action.bound,
    setInitialSectionSettingFormValues: action.bound,

    getSectionSettingFormValidationSchema: action.bound,
    getFieldLabel: action.bound,
    getFieldPlaceholder: action.bound,

    identifierMessage: computed,
    isNanMessage: computed,
    mustBeIntegerMessage: computed,
    positiveNumberMessage: computed,
    maxCommentLengthMessage: computed,
};

/**
 * Модель формы настройки создания раздела
 * @param rfId - Id формы заявки
 * @param id - Id категории
 * @param rootStore - Корневой стор, где собраны все сторы.
 * @param isCreatePage - Флаг, который определяет на странице ли создания раздела находится пользователь
 * */
export class RequestFormSectionSettingModel {
    /** Стор категорий */
    campaignsStore: CampaignsStore;
    /** Стор апи для работы с локалями (intl) */
    intlStore: IntlStore;

    /** Id формы заявки */
    rfId: string;
    /** Id категории */
    id: string;
    /** Начальные значения формы */
    sectionSettingFormInitialValues: RequestFormSectionSettingFormValues = observable.object(
        getSectionSettingFormInitialValues(),
    );
    /** ДТО с настройками раздела */
    sectionSettingDTO: SectionSettingDTO = {} as SectionSettingDTO;
    /** Флаг загрузки ДТО настроек раздела */
    isSectionSettingDTOLoaded = false;

    /**
     * Флаг загрузки необходимых данных для формы (e.g.
     * список кодов формы, список экзекьюторов, список настроек
     * родительской формы etc.)
     */
    isSectionSettingDataLoaded = false;
    /** Список кодов формы */
    formCodeList: CodeTitle[] = [];
    /** Список экзекьюторов */
    executorsList: IdTitle[] = [];
    /** Список настроек родительской формы */
    parentSectionSettingList: IdTitle[] = [];

    constructor(rfId: string, id: string, rootStore: RootStore, isCreatePage: boolean) {
        makeObservable(this, RequestFormSectionSettingModelProps);
        this.rfId = rfId;
        this.id = id;
        this.campaignsStore = rootStore.campaignsStore;
        this.intlStore = rootStore.coreRootStore.intlStore;
        if (!isCreatePage) {
            this.loadSectionSettingDTO();
        } else {
            this.setSectionSettingDTOLoadedTrue();
        }
    }

    /** Загружает ДТО настроек раздела */
    loadSectionSettingDTO(): void {
        // setSectionDTOLoadedTrue в finally, потому что при создании dto не приходит,
        // а выполнять следующее действие по флагу isSectionDTOLoaded - нужно
        this.campaignsStore.loadSectionSetting(this.id).then(this.setDTO).finally(this.setSectionSettingDTOLoadedTrue);
    }

    /** Загружает настройки необходимых данных для формы. См isSectionSettingDataLoaded */
    async loadSectionSettingData(): Promise<void> {
        this.formCodeList = await this.campaignsStore.loadFormCode();
        this.executorsList = await this.campaignsStore.loadExecutors();
        this.parentSectionSettingList = await this.campaignsStore.loadParentSectionSetting(this.rfId, this.id);
        this.isSectionSettingDataLoaded = true;
    }

    /** Назначает начальные значения формы */
    async setInitialSectionSettingFormValues(): Promise<void> {
        if (!this.isSectionSettingDataLoaded) {
            await this.loadSectionSettingData();
        }

        const { formCode, executorId, parentSectionSettingId, ...restDTO } = this
            .sectionSettingDTO as SectionSettingDTO;
        const initialValues = getSectionSettingFormInitialValues();

        const form: CodeTitleNull =
            (formCode &&
                this.formCodeList.find((formItem) => {
                    return formItem.code === formCode;
                })) ||
            null;
        const executor: IdTitleNull =
            (executorId &&
                this.executorsList.find((executorItem) => {
                    return executorItem.id === executorId;
                })) ||
            null;
        const parentSectionSetting: IdTitleNull =
            (parentSectionSettingId &&
                this.parentSectionSettingList.find((parentSectionSettingItem) => {
                    return parentSectionSettingItem.id === parentSectionSettingId;
                })) ||
            null;

        Object.assign(initialValues, {
            ...restDTO,
            form,
            executor,
            parentSectionSetting,
        });

        this.sectionSettingFormInitialValues = observable.object(initialValues);
    }

    /** Валидационная схема для формика. */
    getSectionSettingFormValidationSchema() {
        const { Yup } = useYup();
        return Yup.object({
            title: Yup.string().required(),
            identifier: Yup.string().required().matches(bannedSymbols, { message: this.identifierMessage }),
            form: Yup.object().nullable().required(),
            fileSize: Yup.number().min(0, this.positiveNumberMessage).typeError(this.isNanMessage),
            executor: Yup.object().nullable(),
            commentAttributes: Yup.object({
                maxCommentLength: Yup.number()
                    .min(commentMinLength, this.positiveNumberMessage)
                    .max(commentMaxLength, this.maxCommentLengthMessage)
                    .integer(this.mustBeIntegerMessage)
                    .typeError(this.isNanMessage),
            }),
        });
    }

    /** Возвращает локализированное сообщение для лейбла поля формы */
    getFieldLabel(fieldName: RequestFormSectionSettingFormFields): string {
        return this.intlStore.intl.formatMessage({ id: `campaignSettings.sectionForm.${fieldName}.label` });
    }

    /** Возвращает локализированное сообщение для плейсхолдера поля формы */
    getFieldPlaceholder(fieldName: RequestFormSectionSettingFormFields): string {
        return this.intlStore.intl.formatMessage({ id: `campaignSettings.sectionForm.${fieldName}.placeholder` });
    }

    /** Возвращает сообщение с ошибкой валидации для запрещенных символов */
    get identifierMessage(): string {
        return this.intlStore.intl.formatMessage({ id: 'validation.bannedSymbols' }, { symbols: '/ \\ : * ? < > |' });
    }

    /** Возвращает сообщение с ошибкой валидации для проверки на число */
    get isNanMessage(): string {
        return this.intlStore.intl.formatMessage({ id: 'validation.number' });
    }

    /** Возвращает сообщение с ошибкой валидации для проверки на положительное число */
    get positiveNumberMessage(): string {
        return this.intlStore.intl.formatMessage({ id: 'validation.numberPositive' });
    }

    /** Возвращает сообщение с ошибкой валидации для проверки на целое число */
    get mustBeIntegerMessage(): string {
        return this.intlStore.intl.formatMessage({ id: 'validation.numberInteger' });
    }

    /** Сообщение о максимально допустимой длине комментария */
    get maxCommentLengthMessage(): string {
        return this.intlStore.intl.formatMessage({ id: 'validation.maxLength' }, { length: commentMaxLength });
    }

    /** Устанавливает ДТО настройки создания раздела */
    setDTO(dto: SectionSettingDTO): void {
        this.sectionSettingDTO = dto;
    }

    /** Устанавливает статус ДТО настройки создания раздела как "загруженный" */
    setSectionSettingDTOLoadedTrue(): void {
        this.isSectionSettingDTOLoaded = true;
    }
}
