import { useTreeItem } from '@mui/lab';
import { Divider } from '@mui/material';
import { SidebarItem } from '@platform/formiojs-react';
import { TreeItem } from '@platform/front-ui';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import { TreeItemClickHandle } from '../../../../../types';

export type CampaignRequestFormSidebarItemsProps = {
    sidebarItems: SidebarItem[];
    itemAnchorPrefix: string;
    expandItem: TreeItemClickHandle;
    decreaseItem: TreeItemClickHandle;
    isFirstStep?: boolean;
};

const linkSx: CSSProperties = {
    color: 'unset',
};

export const CampaignRequestFormSidebarItems = observer((props: CampaignRequestFormSidebarItemsProps): JSX.Element => {
    const { sidebarItems, itemAnchorPrefix, isFirstStep = false, expandItem, decreaseItem } = props;
    const lastSidebarItemIndex = sidebarItems.length - 1;

    return (
        <React.Fragment>
            {sidebarItems.map((item, index) => {
                const { title, id, hasError, errorsCount, subItems, isShown } = item;
                const isWithDivider = isFirstStep && lastSidebarItemIndex !== index;
                const isWithSubItems = !!subItems?.length;
                const { expanded } = useTreeItem(id);
                const handleExpandDecreaseItem = (): void => {
                    expanded ? decreaseItem(id) : expandItem(id);
                };

                const label = <a style={linkSx}>{title}</a>;

                if (!isShown) {
                    return <React.Fragment key={id} />;
                }

                return (
                    <React.Fragment key={id}>
                        <TreeItem
                            label={label}
                            nodeId={id}
                            hasError={hasError}
                            errorsCount={errorsCount}
                            needCounter={true}
                            id={itemAnchorPrefix + id}
                            isWithExpandDecreaseButton={isWithSubItems}
                            handleExpandDecreaseItem={handleExpandDecreaseItem}
                        >
                            {subItems && (
                                <CampaignRequestFormSidebarItems
                                    itemAnchorPrefix={itemAnchorPrefix}
                                    sidebarItems={subItems}
                                    expandItem={expandItem}
                                    decreaseItem={decreaseItem}
                                />
                            )}
                        </TreeItem>
                        {isWithDivider && <Divider />}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
});
