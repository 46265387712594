import { LocalesTree } from '@platform/front-utils';

const breadcrumbs: LocalesTree = {
    mainPage: 'Main page',
    campaigns: 'Campaigns',
    campaign: 'Campaign',
    requests: 'Register of requests',
    campaignSettings: 'Campaign settings',
    campaignCreate: 'Create campaign',
    campaignEdit: 'Edit campaign',
    users: 'Users',
    campaignDescription: 'Campaign description',
    campaignRequestForms: 'Request forms',
    campaignRequestFormEdit: 'Editing of request form',
    campaignRequestFormCreate: 'Adding of request form',
    campaignRequestFormInformation: '{requestFormTitle}. Information',
    campaignRequestFormSections: '{requestFormTitle}. Sections',
    campaignRequestFormSectionInformation: '{sectionTitle}. Information',
    campaignRequestFormSectionPF: '{sectionTitle}. Templates',
    syslog: 'Syslog',
};

export default breadcrumbs;
